import unreduxed from 'unreduxed';
import md5 from 'md5';
import { useState } from 'react';
import { GOAL_QUESTION } from '../common/const';
import { QuestionResponse } from '../types/QuestionResponse';
import { MarryResponse, QuizResponseParameter } from '../types/MarryResponse';
import { sendEvent } from '../common/GTM';
import { postMarryRecommender, storeMarryData } from '../common/next-util';

const marryApiKey = process.env.NEXT_PUBLIC_MARRY_API_KEY || '012345678';

const container = () => {
  const [marryResponse, setMarryResponse] = useState<MarryResponse>();

  async function invokeRecommender(answers: QuestionResponse[], email: string, productCount: number, basketGMV: number, version: string) {
    const hashedEmail = md5(email);
    const goalAnswer = answers.find((a: QuestionResponse) => a.short === GOAL_QUESTION);
    const parameterSetRecommender: QuizResponseParameter = {};
    parameterSetRecommender.productCount = productCount;
    parameterSetRecommender.basketGMV = basketGMV;
    if (goalAnswer) {
      const rank = goalAnswer.response.split(',');
      parameterSetRecommender.number_goals = rank.length;
      for (let i = 0; i < rank.length; i++) {
        parameterSetRecommender[`${rank[i]}_goal`] = i + 1;
      }
    }
    answers.map((i) => {
      parameterSetRecommender[`q_${i.short}`] = i.response;
      return i;
    });

    try {
      const response = await postMarryRecommender(marryApiKey, hashedEmail, parameterSetRecommender, version);
      setMarryResponse(response);
      await storeMarryData(response, email);
      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred with Marry', e);
      return null;
    }
  }

  async function getMarryPromoCode(answers: QuestionResponse[], email: string, productCount: number, basketGMV: number, version: string) {
    const response = await invokeRecommender(answers, email, productCount, basketGMV, version);
    if (!response) return null;
    const { recommendedPromoCode } = response;
    sendEvent('four2', 'promo', recommendedPromoCode, true);
    return recommendedPromoCode;
  }

  return {
    marryResponse, getMarryPromoCode,
  };
};

export const [MarryProvider, useMarry] = unreduxed(container);
