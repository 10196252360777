import { useState } from 'react';
import unreduxed from 'unreduxed';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
});

const container = () => {
  const [notification, setNotification] = useState({ type: '', message: '' });

  const showNotification = (message: string, type: string) => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification({ type, message: '' });
    }, 4000);
  };

  const showProgressBar = () => {
    NProgress.remove();
    NProgress.start();
  };

  const hideProgressBar = () => {
    NProgress.done();
  };

  return {
    notification, setNotification, showNotification, showProgressBar, hideProgressBar,
  };
};

export const [NotificationProvider, useNotification] = unreduxed(container);
