import { isEmpty, map, orderBy } from 'lodash';
// import { captureMessage } from '@sentry/nextjs';
import { Category } from '../types/Category';
import { Collection } from '../types/Collection';
import { Country } from '../types/Country';
import { GroupProduct } from '../types/GroupProduct';
import { OrderLatest } from '../types/OrderLatest';
import { Policy } from '../types/Policy';
import { Product } from '../types/Product';
import { PromoCode } from '../types/PromoCode';
import { Question } from '../types/Question';
import type {
  CategoriesListResponse,
  CountryResponse,
  GroupProductListResponse,
  ProductListResponse,
  ProductResponse,
  ProductResponseItem,
  QuestionListResponse,
  TermsResponse,
  PolicyResponse,
  ProductCollectionResponse,
  ProductCollectionListResponse,
  SubscriptionResponseItem,
} from '../types/ServerResponses';
import { Setting } from '../types/Setting';
import { Subscription } from '../types/Subscription';
import { Terms } from '../types/TermCondition';
import { ORDER_STATUS_OPEN, SUBSCRIPTION_STATUS_INCOMPLETE } from './const';
import {
  toCategory,
  toGroupProduct,
  toProduct,
  toQuestion,
  toSetting,
  toTerms,
  toPolicy,
  toProductCollects,
  toProductCollect,
  toPromoCode,
  toCountries,
  toSubscriptionItem,
  toOrderLatest,
  toPromoCodeInvalid,
} from './transformers';
import { SubscriptionItem } from '../types/SubscriptionItem';
import { sendEvent } from './GTM';
import { ParameterSetRecommender } from '../types/Four2Response';
import { MarryResponse, QuizResponseParameter } from '../types/MarryResponse';

type QueryParam = {
  [key: string]: string
};

type Items = {
  name: string,
  sku: string,
  quantity: number,
  price: {
    amount: string,
    currency: string,
  },
};

const convertQueryParam = (queryParam: QueryParam): string => {
  let str = '';
  Object.keys(queryParam).forEach((key: string) => {
    if (str !== '') {
      str += '&';
    }
    str += `${key}=${queryParam[key]}`;
  });
  return str;
};

export const getProducts = async (queryParam = {}): Promise<Product[]> => {
  let url = `${process.env.NEXT_PUBLIC_API}/api/v1/products`;
  if (!isEmpty(queryParam)) {
    const param = convertQueryParam(queryParam);
    url = `${process.env.NEXT_PUBLIC_API}/api/v1/products?${param}`;
  }
  // const url = `${process.env.NEXT_PUBLIC_API}/api/v1/products`;
  const res = await fetch(url);
  const response: ProductListResponse = await res.json();
  // const cart = localStorage.getItem('cart');
  // console.log('cart', cart);
  const products: Product[] = [];
  if (response.data) {
    response.data.map((item) => {
      const product: Product = toProduct(item);
      products.push(product);
      return product;
    });
  }
  return products;
};

export const getProductsBySku = async (sku: string): Promise<Product[]> => {
  const result: Product[] = [];
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/products?sku=${sku}`;
  const res = await fetch(url);
  const { data } = await res.json();
  data.forEach((item: ProductResponseItem) => {
    const product = toProduct(item);
    result.push(product);
  });
  return result;
};

export const getCategories = async (): Promise<Category[]> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/categories`;
  const res = await fetch(url);
  const response: CategoriesListResponse = await res.json();
  const categories: Category[] = [];
  response.data.map((item) => {
    const category: Category = toCategory(item);
    categories.push(category);
    return categories;
  });

  return orderBy(categories, 'id', 'asc');
};

export const getProductByGroups = async (): Promise<GroupProduct[]> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/product_groups`;
  const res = await fetch(url);
  const response: GroupProductListResponse = await res.json();
  const groupProducts: GroupProduct[] = [];
  response.data.map((item) => {
    const groupProduct: GroupProduct = toGroupProduct(item);
    groupProducts.push(groupProduct);
    return groupProduct;
  });
  return groupProducts;
};

export const getProduct = async (sku: string): Promise<Product> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/products/sku/${sku}`;
  const res = await fetch(url);
  const data: ProductResponse = await res.json();
  return toProduct(data.data);
};

export const getProductBySlug = async (sku: string): Promise<Product> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/products/slug/${sku}`;
  const res = await fetch(url);
  const data: ProductResponse = await res.json();
  return toProduct(data.data);
};

export const getQuestions = async (): Promise<Question[]> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/questions`;
  const res = await fetch(url);
  const response: QuestionListResponse = await res.json();
  const questions: Question[] = [];
  response.data.map((item) => {
    const question: Question = toQuestion(item);
    questions.push(question);
    return question;
  });
  return questions;
};

export const getSetting = async (): Promise<Setting | null> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/setting`;
  const res = await fetch(url)
    .then((response) => {
      if (response) {
        return response.json();
      }
      return null;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
      return null;
    });
  // console.log('res', res);
  if (res) return toSetting(res);
  return null;
};

export const getCountries = async (): Promise<Country[]> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/countries`;
  const res = await fetch(url);
  const data: CountryResponse[] = await res.json();

  return toCountries(data);
};

export const getPromoCode = async (code: string | undefined, token?: string | undefined): Promise<PromoCode | undefined> => {
  if (!code) return undefined;
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/promocodes/name?code=${code}`;
  let res;
  if (token && token !== '') {
    res = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    res = await fetch(url);
  }

  // console.log('res.status', res.status);
  const data = await res.json();
  // console.log('data', data);
  let promoCode;
  if (res.status !== 200) {
    sendEvent('promo', 'invalid', code, true);
    promoCode = toPromoCodeInvalid(data);
    return promoCode;
    // captureMessage(`promo not found ${code}`);
  }
  promoCode = toPromoCode(data.data);
  promoCode.valid = true;
  return promoCode;
};

export const getExtraDiscountCode = async (code: string ): Promise<PromoCode | undefined> => {
  if (!code) return undefined;
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/promocodes/extra-discount/${code}`;
  const res = await fetch(url);
  // console.log('res.status', res.status);
  const data = await res.json();
  // console.log('data', data);
  let promoCode;
  if (res.status !== 200) {
    sendEvent('promo', 'invalid', code, true);
    promoCode = toPromoCodeInvalid(data);
    return promoCode;
    // captureMessage(`promo not found ${code}`);
  }
  promoCode = toPromoCode(data.data);
  promoCode.valid = true;
  return promoCode;
};

export const postReviewClick = async (email: string): Promise<void> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/events/clicked/review`;
  const body = {
    email,
  }
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return res.json();
};

export const postCheckoutClick = async (email: string): Promise<void> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/events/clicked/checkout`;
  const body = {
    email,
  }
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return res.json();
};

export const getToken = async (): Promise<{ token: string }> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/auth/checkout-token`;
  const res = await fetch(url);
  const response = await res.json();
  return response;
};

export const postEvent = async (type: string, email: string, token?: string): Promise<{ id: number }> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/events`;
  const body = {
    type,
    email
  }
  const headers = {
    'Content-Type': 'application/json',
    Authorization: ''
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });
  return res.json();
}; 

export const getPolicy = async (): Promise<Policy> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/tearm_policies/get/policy`;
  const res = await fetch(url);
  const response: PolicyResponse = await res.json();
  return toPolicy(response.data);
};

export const getTerms = async (): Promise<Terms> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/tearm_policies/get/tearm`;
  const res = await fetch(url);
  const response: TermsResponse = await res.json();
  return toTerms(response.data);
};
export const getProductCollection = async (slug: string): Promise<Collection> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/product_collections/${slug}`;
  const res = await fetch(url);
  const data: ProductCollectionResponse = await res.json();
  // const productCollection: Collection = toProductCollect(data.data);
  // return productCollection;
  return toProductCollect(data.data);
};
export const getProductCollections = async (): Promise<Collection[]> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/product_collections`;
  const res = await fetch(url);
  const response: ProductCollectionListResponse = await res.json();
  // const productCollections: Collection = toProductCollect(response.data);
  // return productCollections;
  const collections: Collection[] = [];
  response.data.map((item) => {
    const collection: Collection = toProductCollects(item);
    collections.push(collection);
    return collection;
  });
  return collections;
};

export const checkRetryPayment = (order?: OrderLatest, subscriptionData?: Subscription): boolean => !!(order && order.status === ORDER_STATUS_OPEN && subscriptionData && subscriptionData.status === SUBSCRIPTION_STATUS_INCOMPLETE);

export const changeStatusSubscription = async (userId: number, subscriptionId: number, token:string): Promise<Subscription> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/users/${userId}/subscriptions/${subscriptionId}`;
  const res = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  const sub = await res.json();
  const subData: Subscription = {
    id: sub.data.id,
    nextInvoice: sub.data.next_invoice,
    status: sub.data.status,
    coupons: sub.data.coupons,
    type: sub.data.type,
  };
  return subData;
};

export const applyPromoCodeToSubscription = async (code: string, subscriptionId: number, token:string): Promise<Response> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/subscriptions/${subscriptionId}/apply-promo-code`;
  const res = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({ promocode: code.trim() }),
  });
  return res;
};

export const getSubscription = async (token: string): Promise<Subscription | null> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/subscriptions/check`;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  const sub = await res.json();
  // console.log('sub', sub);
  if (sub.data && sub.data.has !== false) {
    const subscriptionData: Subscription = {
      id: sub.data.subscription.data.id,
      nextInvoice: sub.data.subscription.data.next_invoice,
      status: sub.data.subscription.data.status,
      coupons: sub.data.subscription.data.coupons,
      type: sub.data.subscription.data.type,
    };
    return subscriptionData;
    // setSubscription(subscriptionData);
  }
  return null;
};

export const getSubItems = async (token: string): Promise<SubscriptionItem[]> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/users/me/subscription-items`;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  const { data } = await res.json();
  const subItems = map(data, (item : SubscriptionResponseItem) => toSubscriptionItem(item));
  return subItems;
};

export const getLatestOrder = async (token: string): Promise<OrderLatest | null> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/users/me/orders/latest`;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const orderData = await res.json();
  if (orderData) {
    const item: OrderLatest = toOrderLatest(orderData.data);
    return item;
  }
  return null;
};

export const retryManualPayment = async (token: string): Promise<boolean> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/users/retry-payment`;
  const req = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });

  const res = await req.json();
  let retryPayment = false;
  if (res.data) {
    if (res.data.success) {
      retryPayment = true;
    }
  }
  return retryPayment;
};

export const createTokenAfterPay = async (totalOrder: number, currency: string, popupOriginUrl: string, items: Items[]): Promise<string> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/afterpay/checkouts`;
  const body = {
    amount: totalOrder,
    currency,
    mode: 'express',
    popupOriginUrl,
    items,
  };
  const req = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const data = await req.json();
  return data.token || '';
};

export const storeCherryData = async (parameterSetRecommender: ParameterSetRecommender, four2Response: { correlatorId: string, code: string }): Promise<void> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/cherry`;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      parameterSetRecommender,
      four2Response,
    }),
  });
};

export const storeMarryData = async (marryResponse: MarryResponse, email: string): Promise<void> => {
  const url = `${process.env.NEXT_PUBLIC_API}/api/v1/marry`;
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      marryResponse,
    }),
  });
};

export const postMarryRecommender = async (token: string, hashedEmail: string, parameterSetRecommender: QuizResponseParameter, version: string): Promise<MarryResponse> => {
  let url = `${process.env.NEXT_PUBLIC_MARRY_ENDPOINT}/recommend`;
  if (version === 'marry-2') url += '/2';
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      type: 'promocode',
      commonUserId: hashedEmail,
      arguments: parameterSetRecommender,
    }),
  });
  return res.json();
};
