/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  assign,
} from 'lodash';
import md5 from 'md5';
import sha256 from 'sha256';
import { OrderLatest } from '../types/OrderLatest';
import { Question } from '../types/Question';
import { Product } from '../types/Product';
import { insiderTrackEvent } from './insider';

type Variable = {
  [key: string]: string
};

type AdditionalData = {
  email: string,
  phone_number?: string,
  FirstName?: string,
  LastName?: string,
  ShippingStreet?: string,
  ShippingCity?: string,
  ShippingRegion?: string,
  ShippingCountry?: string,
  ShippingPostCode?: string,
};

type AddressGTM = {
  FirstName: string,
  LastName: string,
  ShippingStreet: string,
  ShippingCity: string,
  ShippingRegion: string,
  ShippingCountry: string,
  ShippingPostCode: string,
};

export const sendAdditionalData = (email: string, phone?: string, address?: AddressGTM, event?: string, category?: string, action?: string) => {
  let data: AdditionalData = {
    email,
  };
  if (phone) data.phone_number = phone;
  if (address) data = { ...data, ...address };
  if (event) assign(data, { event });
  if (category) assign(data, { category });
  if (action) assign(data, { action });
  (<any>window).dataLayer.push(data);
};

export const sendEvent = (category: string, action: string, label?: string, nonInteraction?: boolean) => {
  // console.log('sendEvent :', category, action, label);
  (<any>window).dataLayer.push({
    category,
    action,
    label,
    nonInteraction,
    event: 'eventTracking',
  });
};

export const sendGa4Event = (event: string, action: string, label?: string) => {
  // console.log('sendEvent :', category, action, label);
  (<any>window).dataLayer.push({
    event,
    action,
    label
  });
};

export const sendEcommerceEvent = (event: string, products: Product[]) => {
  const items = products.map((product) => {
    const item = {
      item_name: product.name,
      item_id: product.sku,
      price: product.price.toString(),
      item_brand: product.brand || '',
      quantity: product.quantity.toString(),
    }
    return item;
  });
  (<any>window).dataLayer.push({
    event,
    ecommerce: {
      items,
    }
  });
};

export const setVariable = (name: string, value: string) => {
  const variable: Variable = {};
  variable[name] = value;
  (<any>window).dataLayer.push(variable);
};

export const setTrackingEmail = (email: string) => {
  (<any>window).dataLayer.push({ userId: md5(email) });
};

export const sendAnswerGA = (question: Question, response: string) => {
  const category = 'mainQuiz';
  const action = question.short;
  let label = response;
  if (question.short === 'email') {
    setVariable('hashed_email', sha256(response));
    label = '';
  }
  if (question && question.type === 'boolean') {
    if (question.short !== 'gender') {
      label = response === 'Yes' ? 'true' : 'false';
    }
  }
  sendEvent(category, action, label);
  sendEvent(category, 'answer_question', action);
  sendGa4Event('mainQuiz', `${question.short}`, `${response}`);
  const trackingData = {
    question: question.short,
    response: label,
  };
  // segment track
  insiderTrackEvent('answerQuestion', trackingData);
};

export const sendOrderToGa = (email: string, order: OrderLatest) => {
  const data = {
    event: 'purchase',
    order_value: order.amount,
    order_id: order.id,
    enhanced_conversion_data: {
      email,
    },
  };
  (<any>window).dataLayer.push(data);
};
