/* eslint-disable import/prefer-default-export */
export const eventTypes = {
  ADD_TO_CART: 'ADD_TO_CART',
  SITE_VIEW: 'SITE_VIEW',
  PAYMENT_PAGE_VIEW: 'PAYMENT_PAGE_VIEW',
  CART_PAGE_VIEW: 'CART_PAGE_VIEW',
  RECOMMENDATION_PAGE_VIEW: 'RECOMMENDATION_PAGE_VIEW',
  CLICK_REVIEW_AND_RESUME: 'CLICK_REVIEW_AND_RESUME',
  CLICK_RESUME_AND_REVIEW: 'CLICK_RESUME_AND_REVIEW',
  CLICK_RESUME: 'CLICK_RESUME',
};
export const promoCodeTypes = {
  ONCE: 'once',
  REPEATING: 'repeating',
  DEGRESSIVE: 'degressive',
  FOREVER: 'forever',
  DUB: 'dub',
};
export const TYPE_INTERNAL = 'INTERNAL';
export const TYPE_STRIPE = 'STRIPE';
export const ORDER_STATUS_IN_DELIVERY = 7;
export const ORDER_STATUS_DELIVERED = 8;
export const ORDER_STATUS_PROCESSING = 6;
export const ORDER_STATUS_CANCELED = 4;
export const ORDER_STATUS_OPEN = 2;
export const ORDER_STATUS_PAID = 3;
export const ORDER_STATUS_FAILED = 9;
export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const SUBSCRIPTION_STATUS_INCOMPLETE = 'incomplete';
export const SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED = 'incomplete_expired';
export const SUBSCRIPTION_STATUS_PAUSED = 'paused';
export const SUBSCRIPTION_STATUS_CANCELED = 'canceled';
export const SUBSCRIPTION_STATUS_TRIALING = 'trialing';
export const SUBSCRIPTION_STATUS_UNPAID = 'unpaid';
export const SUBSCRIPTION_STATUS_PAST_DUE = 'past_due';

export const pageTitles = {
  TERM_CONDITIONS: 'Vitable | Terms & Conditions',
  PRIVACY: 'Vitable | Privacy Policy',
  PRODUCTS_LIST:
    'Browse Products - Build Your Daily Vitamin Pack - Vitable',
  QUIZ: 'What Vitamins Should I Take? - Personalised Quiz - Vitable',
  HOME_PAGE: 'Vitable | Personalised Daily Vitamin Packs',
  LOGIN: 'Login as existing Vitable Member | Vitable - Personalised Vitamins',
  SUSTAINABILITY: 'Our Sustainable Health Solutions - Vitable',
  ABOUT_US: 'About Us - Our promise to you. | Vitable - Personalised Vitamins',
  PAYMENT: 'Place your order | Vitable - Personalised Vitamins',
  EMPTY_CART: 'Build your vitamin plan | Vitable - Personalised Vitamins',
  FORGOT_PASSWORD: 'I forgot my password | Vitable - Personalised Vitamins',
  RESET_PASSWORD: 'Reset my password | Vitable - Personalised Vitamins',
  CART: 'Review your vitamin selection | Vitable - Personalised Vitamins',
  ONE_OFF_PURCHASE: 'One-time purchase | Vitable - Personalised Vitamins',
  CONFIRMATION: 'Your order has been placed! | Vitable - Personalised Vitamins',
  DELIVERY_SETTINGS: 'Edit your shipping information | My Vitable Account',
  ACCOUNT_SETTINGS: 'Manage your account | My Vitable Account',
  REFERRAL: 'Refer a friend, earn rewards! | My Vitable Account',
  NEXT_DELIVERY: 'Check your next delivery | My Vitable Account',
  ORDER_HISTORY: 'Check your order history | My Vitable Account',
  PROFILE_DISPLAY:
    'Visit your profile & see your vitamin recommendation | My Vitable Account',
  RECOMMENDATION: 'Vitable | See your Vitamin Recommendation',
  BLOG: 'Vitable | Vitable Blog',
  REVIEWS: 'Vitable Reviews - What Our Customers Say',
  GIFT_CARD: 'Vitable e-Gift Cards | Personalised Daily Vitamin Packs',
};

export const pageMetaDescription = {
  TERM_CONDITIONS:
    'Visit our Terms and Conditions to read the detail of the relationship between you, the products and Vitable.',
  PRIVACY:
    'We take your privacy seriously. Read more about what information we collect and why.',
  PRODUCTS_LIST:
    'Browse our premium dietary supplements by health goal or concern & build your own vitamin plan delivered monthly in daily plastic-free packs!',
  QUIZ: "Take our quiz and tell us about your unique health goals, diet and lifestyle. We'll tell you what your body needs & recommend a personalised vitamin plan!",
  HOME_PAGE:
    'Get supplements for your health goals, tailored to you with honest guidance and premium ingredients, shipped to you each month.',
  LOGIN:
    'Login into your customer account here. Sign in and get access to your orders and account information.',
  SUSTAINABILITY:
    'At Vitable we take sustainability seriously. From our eco-friendly packaging to our carbon neutral partnership, find out more about our sustainable solutions.',
  ABOUT_US:
    'Better ingredients, honest guidance. We work hard to source the best ingredients and create a waste-free experience. We only make products we believe are effective. That’s our promise to you.',
  PAYMENT:
    "You're a click away from making feeling good an everyday thing! Your personalised vitamin pack will be safely delivered to you in no time.",
  EMPTY_CART:
    'Your Cart is empty. Browse products and build your own vitamin plan or take our Quiz for more guidance!',
  FORGOT_PASSWORD:
    "Enter your email address and you'll be emailed a link to reset your password !",
  RESET_PASSWORD:
    'Click on your name >> "My pack" >> "Account settings" then key in your current password under "CURRENT PASSWORD" and the new password under "CHANGE PASSWORD" and put the new password under "RE-ENTER PASSWORDd"',
  CART: "Confirm your choice and start your Vitable personalised vitamin plan! Let's make FEELING GOOD an everyday thing !",
  CONFIRMATION:
    "Congrats! You'll receive your order in no time. You can download our Mobile App to track your order and enhace your feel good journey!",
  DELIVERY_SETTINGS:
    'Manage your shipping & payment information. You can download our Mobile App to manage your account easier !',
  ACCOUNT_SETTINGS:
    'Manage your account settings & payment information. You can download our Mobile App to manage your account easier !',
  REFERRAL:
    'Give your friends the gift of feeling good and get credits for your next Vitable pack !',
  NEXT_DELIVERY:
    'You can manage your next order: track the excpected delivery time or edit your upcoming vitamin pack! You can download our Mobile App to manage your pack easily!',
  ORDER_HISTORY:
    'You can review your orders history and check your next delivery! You can download our Mobile App to manage your account easier !',
  PROFILE_DISPLAY:
    'See your nutritional profile and vitamin plan, find out personalised vitamin recommendation and lifestyle tips thanks to our Quiz !',
  RECOMMENDATION:
    "Discover the vitamins & minerals your body needs. Only what you actually need, nothing that you don't.",
  BLOG:
    'Read the latest nutrition news, learn everything about vitamins and get top lifestyle advice from our health experts team.',
  REVIEWS: "Don't take our word for it, hear what our customers have to say about Vitable's daily vitamin packs! Read verified feel-good reviews from our customers.",
  GIFT_CARD: "Give the gift of feeling good everyday with a Vitable gift card! Your giftee can take our quiz and build their very own personalised vitamin pack."
};
export const ourDifference = {
  keepPersonal: {
    title: 'We keep it personal.',
    description: `  We recommend the products that best suit your needs, taking into account your unique health goals, lifestyle and diet. Plus, everything from your daily sachets to
    your information booklet are personalised and made to order just for you.`,
    img: 'assets/images/our_difference_1.jpg',
  },
  tellTrue: {
    title: 'We tell it true.',
    description: `Transparency is behind everything Vitable does, from our formulas and ingredients to the scientific research. We’re also direct-to-consumer, which means no middlemen
    mark-ups. We take away the unnecessary costs and pass those savings onto you.`,
    img: 'assets/images/our_difference_2.jpg',
  },
  makeSimple: {
    title: 'We make it simple.',
    description: `We believe that good health shouldn’t be complicated or inaccessible. We do the heavy lifting for you - from our simple online quiz to your daily vitamin packs
    delivered straight to your door - so you can go about your day feeling good.`,
    img: 'assets/images/our_difference_3.jpg',
  },
};
export const trustpilotData = [
  {
    id: 1,
    name: 'Jo',
    title: 'I no longer waste money',
    description: 'I no longer waste money, on vitamins I don\'t need. I feel like my old self again. I wake up in the morning and feel refreshed, after a good night sleep. I have not felt like that for a long time.',
    star: 5,
    onTop: false,
  },
  {
    id: 2,
    name: 'R. Stevens',
    title: 'Quality business',
    description: 'Really awesome business and products. I love the fact that my vitamins are in one place and easy to take! After one week, I had already noticed a difference to my energy levels and skin. Customer service has been excellent!',
    star: 5,
    onTop: false,
  },
  {
    id: 3,
    name: 'Ashlee',
    title: 'Absolutely best thing I have invested in',
    description: 'Absolutely best thing I have invested in. Super easy when on the go while still getting what you need in through the day. Love it',
    star: 5,
    onTop: false,
  },
  {
    id: 4,
    name: 'Martin',
    title: 'Great service, convenient, easy and super helpful!',
    description: 'Great service! I knew I needed some supplements but was not really which vitamins to take. Thanks to Vitable, I now have my daily boost of magnesium and vitamin C. It is so convenient with the pack delivered at my doorstep every month. And customer service was perfect. Definitely recommend Vitable!',
    star: 5,
    onTop: false,
  },
];

export const TOKEN = 'token';

export const quizType = {
  MAIN_QUIZ: 'main',
  TRACKER_QUIZ: 'tracker',
};
export const GOAL_QUESTION = 'improve_area';
export const ORDER_SUMMARY_DEFAULT = 'default';
export const ORDER_SUMMARY_A = 'a';
export const ORDER_SUMMARY_B = 'b';
export const ORDER_SUMMARY_C = 'c';
export const ORDER_SUMMARY_D = 'd';
export const RECOMMENDATION_SUMMARY_A = 'a';
export const RECOMMENDATION_SUMMARY_B = 'b';
export const RECOMMENDATION_SUMMARY_DEFAULT = 'default';
export const RECO_SUMMARY_PRODUCT_DETAIL_DEFAULT = 'default';
export const RECO_SUMMARY_PRODUCT_DETAIL_A = 'a';
export const DISCOUNT_APPLIED = 'Discount applied';
export const APPLIED_TO_TWO_MONTHLY_PACK = 'Applied to two Monthly packs';
export const MOBILE_APP_VIEW_DEFAULT = 'a';
export const SHIPPING_VARIANT_DEFAULT = 'shiping-variant-default';
export const SHIPPING_VARIANT_1 = 'shiping-variant-1';
export const SHIPPING_VARIANT_2 = 'shiping-variant-2';
export const LIMITED_TIME = 20160000;
export const HOMEPAGE_BANNER_A = 'a';
export const HOMEPAGE_BANNER_B = 'b';

export const productStatus = {
  ACTIVE: 2,
  PENDING: 1,
};

export const DEFAULT_UPCOMING_INVOICE_FREQUENCY = 7;
export const UPCOMING_INVOICE_FREQUENCY = 2;
export const QUIZ_BTN_DEFAULT_TEXT = 'Take The <span>Quiz</span>';
export const QUIZ_BTN_PRIMARY_TEXT = 'Take The Quiz';
export const QUIZ_BTN_RE_QUIZ_TEXT = 'Retake The Quiz';
export const discountType = {
  PERCENT: 'percent',
  DOLLAR: 'dollar',
};
export const kindOfDiscount = {
  GIFT_CARD: 'giftCard',
  PROMO_CODE: 'promoCode',
};

export const HEADER_DEFAULT_VERSION = 'default';
export const HEADER_NEW_VERSION = 'new';
export const PRODUCT_MENU_NAME_DEFAULT = 'Browse Library';
export const PRODUCT_MENU_NAME_NEW = 'Browse Products';
export const FAQ_LINK = 'https://intercom.help/vitable/en';
export const INSTAGRAM_LINK = 'https://www.instagram.com/vitablevitamins/';
export const FACEBOOK_LINK = 'https://www.facebook.com/VitableVitamins/';
export const RESEARCH_LINK = 'https://research.vitable.com.au/';
export const SHIPPING_DELIVERY_LINK = 'https://intercom.help/vitable/en/collections/3730129-shipping-delivery';
export const DOWNLOAD_APP_LINK = 'https://onelink.to/pbuekr';
export const DOWNLOAD_APP_STORE = 'https://apps.apple.com/au/app/vitable/id1552666276';
export const DOWNLOAD_GG_PLAY = 'https://play.google.com/store/apps/details?id=com.vitable&hl=en_AU&gl=US';
export const BECOME_AFFILIATES_LINK = 'https://affiliates.vitable.com.au/';
export const MAIL_LINK = 'mailto:hello@vitable.com.au';
export const WHATSAPP_LINK = 'https://wa.me/message/MR6O2QNIMFZZI1';
export const REFERRAL_TRIGGER_A = 'referral-trigger-a';
export const DEFAULT = 'default';
export const POPUP_CHANGE_SUBSCRIPTION_TYPE = 'change-subscription-type';
export const POPUP_CHANGE_TO_PAYPAL = 'change-to-paypal';
export const POPUP_CHANGE_TO_STRIPE = 'change-to-stripe';
export const SUBSCRIPTION_FREQUENCY_SEPARATOR = ' | '
export const FLAG_NEW_SUBCRIPTION_PLANS = {
  CONTROL: 'control',
  VARIATION1: 'variation-1',
  VARIATION2: 'variation-2',
  FORCE_NEW: 'force-new',
};
export const FLAG_WEB_NEW_SUBCRIPTION_PLANS_CURRENT_CUSTOMER = {
  NAME: 'web-new-subscription-plan-current-customer',
  CONTROL: 'control',
  VARIATION1: 'variation-1',
}
export const VARIATION_1 = 'variation-1';
export const CONTROL = 'control';
export const FLAG_VALUE = {
  CONTROL: false,
  VARIATION1: true,
}

export const ADD_ONS_BRAND = 'Add-ons';
export const DNA_TEST_SKU = 'dna-test';
export const PERSONALISED_NUTRITION_SKU = 'personalised-nutrition-plan';
export const PAYMENT_GATEWAY = {
  STRIPE: 'STRIPE',
  AFTERPAY: 'AFTERPAY',
  BRAINTREE: 'BRAINTREE',
}
