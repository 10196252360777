import useFetch from 'use-http';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { IntercomProvider } from 'react-use-intercom';
import { useAsyncEffect } from 'use-async-effect';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import assign from 'lodash/assign';
import forIn from 'lodash/forIn';
import isNil from 'lodash/isNil';
import dynamic from 'next/dynamic';
import App from '../containers/App';
import { NotificationProvider } from '../containers/NotificationContainer';
import { AuthProvider } from '../containers/AuthContainer';
import { PromoCodeProvider } from '../containers/PromoCodeContainer';
import FetchProvider from '../containers/FetchProvider';
import { RecommendationProvider } from '../containers/RecommendationContainer';
import { SurveyProvider } from '../containers/SurveyContainer';
import { LaunchDarklyProvider } from '../containers/LaunchDarklyContainer';
import { ConfirmationProvider } from '../containers/ConfirmationContainer';
import { ProductProvider } from '../containers/ProductContainer';
import { CountryProvider } from '../containers/CountryContainer';
import {
  convertQueryParams, redirectLogin, checkIfIntegrationTesting, getTestLaunchDarkly, getTestSetting,
} from '../common/util';
import { eventTypes, TOKEN } from '../common/const';
import { HeaderProvider } from '../containers/HeaderContainer';
import { EventsProvider } from '../containers/EventsContainer';
import { Setting } from '../types/Setting';
import { CartProvider } from '../containers/CartContainer';
import { StripeProvider } from '../containers/StripeContainer';
import { Four2Provider } from '../containers/Four2Container';
import { MarryProvider } from '../containers/MarryContainer';
import '../common/polyfills';
import { sendEvent, sendGa4Event } from '../common/GTM';
import { Country } from '../types/Country';
import { Product } from '../types/Product';
import { InsiderProvider } from '../containers/InsiderContainer';
import { postEvent } from '../common/next-util';

const Header = dynamic(import('../components/layout/Header'));
const Footer = dynamic(import('../components/layout/Footer'));
const Spinner = dynamic(import('../components/Spinner'));
const Maintenance = dynamic(import('./maintenance'));
const ModalAfterPay = dynamic(import('../components/ModalAfterPay'));
const ModalAddedProduct = dynamic(import('../components/ModalAddedProduct'));
const GlobalStyle = dynamic(import('../pagestyles/components/intercom/GlobalStyle'));

const INTERCOM_INIT_DELAY = 6000;
const AppComponent = ({ Component, pageProps }: AppProps<{
  setting: Setting,
  countries: Country[],
  products: Product[],
}>): JSX.Element => {
  const router = useRouter();

  const [authToken, setAuthToken] = useState('');
  const { asPath } = router;
  const { get: checkAuthToken } = useFetch(`${process.env.NEXT_PUBLIC_API}/api/v1/auth/auth-token`);
  const { post: verifyToken } = useFetch(`${process.env.NEXT_PUBLIC_API}/api/v1/auth/verify-token`);
  const [routeChange, setRouteChange] = useState<boolean | null>(null);
  const [scrollOnTopComplete, setScrollOnTopComplete] = useState<boolean | null>(null);
  const siteKeyCaptchaV3 = process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY || '';
  const intercomId = process.env.NEXT_PUBLIC_INTERCOM_ID || '';
  const maintenanceModeOn = !pageProps.setting || (pageProps.setting && pageProps.setting.maintenanceMode !== false);
  const giftCardEnabled = pageProps.setting && pageProps.setting.giftCardEnabled;
  useEffect(() => {
    if ((maintenanceModeOn && !router.isFallback) || (!giftCardEnabled && router.asPath.includes('gift-card'))) {
      router.replace('/');
    }
  }, []);
  const depthList = [25, 50, 75, 100];
  const trackedDepthList: number[] = [];

  const sendTrackingDepth = (offsetHeight: number, innerHeight: number, scrollY: number) => {
    let depth = 0;
    const position = Math.round(((innerHeight + scrollY) / offsetHeight) * 100);
    if (depthList.includes(position) && !trackedDepthList.includes(position)) {
      depth = position;
      trackedDepthList.push(position);
    }

    if (depth !== 0) {
      const list = depthList.filter((i: number) => i < depth);
      if (list.length) {
        list.forEach((value: number) => {
          if (!trackedDepthList.includes(value)) {
            sendEvent('scroll', value.toString(), asPath);
            sendGa4Event('scrollDepth', 'scroll', `${value}%`)
            trackedDepthList.push(value);
          }
        });
      }
      sendEvent('scroll', depth.toString(), asPath);
    }
    return depth;
  };

  // check the scroll event when the user goes back and forth between pages
  const handleScrollOnRedirect = () => {
    if (isNil(routeChange)) return;
    if (isNil(scrollOnTopComplete) && (window.scrollY === 0 || window.scrollY === 1)) {
      setScrollOnTopComplete(true);
    }
    if (scrollOnTopComplete) {
      sendTrackingDepth(document.body.offsetHeight, window.innerHeight, window.scrollY);
    }
  };

  // check the scroll event when the user enters the page for the first time
  const handleScroll = () => {
    if (routeChange) return;
    sendTrackingDepth(document.body.offsetHeight, window.innerHeight, window.scrollY);
  };

  const onRouteChangeDone = React.useCallback(() => {
    setRouteChange(true);
    setScrollOnTopComplete(null);
    // segment page
  }, []);

  useAsyncEffect(async () => {
    if (window !== undefined) {
      let integrationTestingSetting:Setting;
      if (asPath.indexOf('clearstore') > -1) {
        forIn(window.localStorage, (value, key) => {
          window.localStorage.removeItem(key);
        });
      }
      // Load test setting if pass ?integrationTesting=true
      if (checkIfIntegrationTesting(router)) {
        // Setup local setting
        integrationTestingSetting = getTestSetting();
        if (asPath.includes('maintenance_mode')) integrationTestingSetting.maintenanceMode = true;
        assign(pageProps.setting, integrationTestingSetting);
        window.localStorage.setItem('integrationTesting-setting', JSON.stringify(integrationTestingSetting));

        // Setup local LaunchDarkly
        window.localStorage.setItem('integrationTesting-launchDarkly', JSON.stringify(getTestLaunchDarkly(router)));
      } else {
        const localSettingJson = window.localStorage.getItem('integrationTesting-setting');
        if (localSettingJson) {
          const localTestSetting: JSON = JSON.parse(localSettingJson);
          if (asPath.includes('maintenance_mode')) {
            assign(pageProps.setting, localTestSetting, { maintenanceMode: true });
          } else {
            assign(pageProps.setting, localTestSetting);
          }
        }
      }

      const token = window.localStorage.getItem(TOKEN);
      const params = router.asPath.split('?');
      let query;
      if (params.length >= 2) query = convertQueryParams();
      if ((asPath.includes('auth=') && query && query.auth !== '') || (asPath.includes('/myaccount') && !token)) {
        if (asPath.includes('auth=') && query && query.auth !== '') {
          const res = await checkAuthToken(`?auth=${query.auth}`);
          if (res && res.access_token) {
            setAuthToken(res.access_token);
          } else if (!token && asPath.includes('/myaccount')) {
            redirectLogin(null, router);
          }
        } else if (asPath.includes('/myaccount') && !token) {
          redirectLogin(null, router);
        }
      }
      const siteView = window.localStorage.getItem('siteView');
      if (!siteView) {
        const res = await postEvent(eventTypes.SITE_VIEW, '');
        if (res && res.id) {
          localStorage.setItem('siteView', 'true');
        }
      }
    }
    window.history.scrollRestoration = 'manual';
    router.events.on('routeChangeComplete', onRouteChangeDone);
  }, []);

  useAsyncEffect(async () => {
    if (asPath.includes('/place-order') && process.env.NEXT_PUBLIC_ENV !== 'local') {
      const queryParams = new URLSearchParams(window.location.search);
      const checkoutToken = queryParams.get('checkout-token');
      const data = await verifyToken({ token: checkoutToken });
      if (!data.success) {
        await router.replace('/checkout/review');
      }
    }
  }, [routeChange]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('scroll', handleScrollOnRedirect, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScrollOnRedirect);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [routeChange, scrollOnTopComplete]);

  const urlListNotShowingIntercom = ['/survey', 'widgets'];
  const checkHideIntercom = urlListNotShowingIntercom.find((path) => asPath.includes(path));
  const hideIntercom = !isNil(checkHideIntercom) || false;
  // console.log('hideIntercom', hideIntercom);
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
  // const segmentId = process.env.NEXT_PUBLIC_SEGMENT_ID;
  if (router.isFallback || (!giftCardEnabled && router.asPath.includes('gift-card'))) return <Spinner />;
  if (maintenanceModeOn && !router.isFallback) return <Maintenance />;
  return (
    <NotificationProvider>
      <FetchProvider>
        <CountryProvider countries={pageProps.countries}>
          <LaunchDarklyProvider>
            <IntercomProvider appId={intercomId} autoBoot shouldInitialize initializeDelay={INTERCOM_INIT_DELAY}>
              <SurveyProvider>
                <EventsProvider>
                  <PromoCodeProvider>
                    <CartProvider {...pageProps}>
                      <AuthProvider authToken={authToken}>
                        <ConfirmationProvider>
                          <StripeProvider>
                            <Four2Provider>
                              <MarryProvider>
                                <RecommendationProvider>
                                  <ProductProvider>
                                    <HeaderProvider>
                                      <InsiderProvider>
                                        <App>
                                          <GlobalStyle hideIntercom={hideIntercom} />
                                          <Head>
                                            <title>
                                              Vitable: Personalised Daily Vitamin Packs
                                            </title>
                                            <meta
                                              name="description"
                                              content="Get supplements for your health goals, from premium ingredients, shipped to you each month."
                                              key="description"
                                            />
                                            <link rel="icon" href="/favicon.ico" />
                                            <link rel="preload" as="image" href="/assets/images/landing_page_hero_large.jpg"/>
                                            <script
                                            // eslint-disable-next-line react/no-danger
                                              dangerouslySetInnerHTML={{
                                                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                              })(window,document,'script','dataLayer','${gtmId}');`,
                                              }}
                                            />
                                          </Head>
                                          <Header {...pageProps} />
                                          <GoogleReCaptchaProvider
                                            reCaptchaKey={siteKeyCaptchaV3}
                                            language="en-GB"
                                          >
                                            <Component {...pageProps} />
                                          </GoogleReCaptchaProvider>
                                          <Footer {...pageProps} />
                                          <ModalAfterPay />
                                          <ModalAddedProduct />
                                        </App>
                                      </InsiderProvider>
                                    </HeaderProvider>
                                  </ProductProvider>
                                </RecommendationProvider>
                              </MarryProvider>
                            </Four2Provider>
                          </StripeProvider>
                        </ConfirmationProvider>
                      </AuthProvider>
                    </CartProvider>
                  </PromoCodeProvider>
                </EventsProvider>
              </SurveyProvider>
            </IntercomProvider>
          </LaunchDarklyProvider>
        </CountryProvider>
      </FetchProvider>
    </NotificationProvider>
  );
};

export default AppComponent;
