import { useAsyncEffect } from 'use-async-effect';
import useFetch from 'use-http';
import { useRouter } from 'next/router';
import { useState } from 'react';
import unreduxed from 'unreduxed';
import { convertQueryParams } from '../common/util';
import { Country } from '../types/Country';

type Props = {
  countries: Country[]
};
const container = (props:Props) => {
  const { countries } = props;
  const router = useRouter();
  const [country, setCountry] = useState<Country>();
  const [detectedCountry, setDetectedCountry] = useState<Country>();
  const { get: getCountry } = useFetch('/api/v1/country');
  const { get: getIp } = useFetch('/api/v1/ip');
  const { get: detectCountry } = useFetch('/api/v1/country/detect');


  async function getAndSetCountry() {
    let currentCountry;
    let detect;
    if (router.asPath.includes('force-country')) {
      const query = convertQueryParams();
      currentCountry = countries.find((i: Country) => i.country === query['force-country']);
      detect = countries.find((i: Country) => i.country === query['force-country']);
    } else {
      const resCountry = await getCountry();
      currentCountry = countries.find((i: Country) => i.country === resCountry.country);
      detect = await detectCountry();
    }
    if (!currentCountry) {
      currentCountry = countries.find((i: Country) => i.country === 'AU');
    }
    setCountry(currentCountry);
    setDetectedCountry(detect);
  }

  useAsyncEffect(async () => {
    await getAndSetCountry();
    const ip = sessionStorage.getItem('ip');
    if (!ip) {
      const res = await getIp();
      if (res && res.data) {
        sessionStorage.setItem('ip', res.data);
      }
    }
  }, []);

  return {
    country,
    detectedCountry,
    setCountry,
  };
};
export const [CountryProvider, useCountry] = unreduxed(container);
