const appRoutes = {
  HOME: '/',
  QUIZ_RESPONSES: '/api/v1/quiz_responses',
  QUIZ_RESPONSES_PROFILE: '/api/v1/quiz_responses/profile',
  ORDERS: '/api/v1/orders',
  ADDRESSES: '/api/v1/addresses',
  ADDRESSES_LATEST: '/api/v1/users/me/addresses/latest',
  API_RECOMMENDATIONS_ONE_OFF: '/api/v1/recommendations/one-off',
  API_RECOMMENDATIONS_NEW: '/api/v1/recommendations/new',
  CHECKOUT_REVIEW: '/checkout/review',
  CHECKOUT_ONE_OFF: '/checkout/one-off',
  CHECKOUT_ONE_OFF_CONFIRMATION: '/checkout/one-off/confirmation',
  SURVEY: '/survey',
  PRODUCTS: '/products',
  MY_ACCOUNT_NEXT_DELIVERY: '/myaccount/next-delivery',
  MY_ACCOUNT_MY_ACCOUNT: '/myaccount/my-account',
  RECOMMENDATION_ADD_ONS: '/recommendation/add-ons',
};

export default appRoutes;
