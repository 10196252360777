const port = process.env.TEST_PORT || 3099;
const localServer = process.env.LOCAL_SERVER === 'true';
const remoteServer = process.env.REMOTE_SERVER === 'true';
const remoteTest = process.env.REMOTE_TEST === 'true';
let h = `http://localhost:${port}`;
let gtm = 'GTM-TXBMJ2J';
if (remoteServer || remoteTest) {
  h = 'https://v2-staging.vitable.com.au';
  gtm = 'GTM-MNCFQRC';
}
export const host = h;
// export const host = `http://localhost:${port}`;
// console.log('port', port);

// export const host = 'http://localhost:3002';
export default { host };
export const GTM_TAG = gtm;
export const DEFAULT_PRODUCT = 'test-default-product';
export const CHECKOUT_GROUP_EXTRA_PRODUCT = 'test-checkout-group-extra';
export const CHECKOUT_GROUP_POWDER_PRODUCT = 'test-checkout-group-powder';
export const SECONDARY_PRODUCT = 'test-secondary-product';
export const ALSO_RECOMMENDED_PRODUCT = 'vitamin-b12';
export const PREGNANT_REMOVE_PRODUCT = 'test-remove-pregnant';
export const HIDDEN_PRODUCT = 'test-hidden';
export const HIDDEN_FROM_LIBRARY_PRODUCT = 'test-hidden-from-library';
export const OUT_OF_STOCK_PRODUCT = 'test-out-of-stock';
export const DEACTIVATED_PRODUCT = 'test-product-deactivated';
export const COUNTRY_BLOCKED_PRODUCT = 'test-au-blocked';
export const ALL_GOALS_PRODUCT = 'test-goals-product';
export const AUTH_EMAIL = 'authtest@vitable.com.au';
export const AUTH_PASSWORD = 'password';
export const DEFAULT_COLLECTION = 'test';
export const DEFAULT_SHIPPING = 9.95;
export const SHIPPING_FEE_SG = '14.50';
export const CODE_50_PERCENT = 'GIFT50';
export const CODE_50_PERCENT_GIFTCARD = 'TEST50%_GIFTCARD';
export const CODE_10_DOLLAR = 'TEST_DISCOUNT_10$';
export const CODE_10_DOLLAR_3MONTH = 'TEST_DISCOUNT_10$_3MONTH';
export const CODE_20_PERCENT_3MONTH = 'TEST20%_3M';
export const CODE_20_PERCENT = 'TEST20%';
export const CODE_20_PERCENT_DEFAULT_PRODUCT = 'TEST20%_DEFAULT';
export const CODE_20_PERCENT_DEFAULT_3M = 'TEST20%_DEFAULT_3M';
export const CODE_50_PERCENT_3MONTH = 'TEST50PERCENT_3MONTH';
export const CODE_10$_DEFAULT_PRODUCT = 'TEST10$_DEFAULT';
export const CODE_10$_DEFAULT_PRODUCT_3MONTH = 'TEST10DEFAULT_3MONTH';
export const CODE_50_PERCENT_DEFAULT_PRODUCT = 'TEST50PERCENT_DEFAULT';
export const CODE_50_PERCENT_DEFAULT_PRODUCT_3MONTH = 'TEST50PERCENT_DEFAULT_3MONTH';
export const CODE_MESSAGE = 'TEST_MESSAGE';
export const CODE_GIFT_CARD_11 = 'TESTGIFTCARD11';
export const CODE_GIFT_CARD_50 = 'TEST_GIFT50$';
export const CODE_NEW_CUSTOMER = 'TESTNEWCUSTOMER';
export const BLACK_FRIDAY_CODE = 'BLACKFRIDAY';
export const DEFAULT_POST = 'vitamins-for-nail-health-tips-on-creating-a-vitamin-plan-to-support-healthy-nails';
export const DEFAULT_TAG = 'biotin';
// Test Setting
export const TEST_SETTING_REF_CREDIT = 30;
export const TEST_SETTING_MIN_ORDER_PRICE = 5;
export const TEST_SETTING_REF_DISCOUNT = 50;
export const TEST_SETTING_MAX_PILLS_PER_SACHET = 12;
export const TEST_SETTING_CHECKOUT_POPUP_PROMOCODE = 'GIFT50';
export const TEST_SETTING_CHECKOUT_POPUP_DOLLAR = 0;
export const TEST_SETTING_CHECKOUT_POPUP_DELAY = 1;
export const TEST_SETTING_CHECKOUT_POPUP_DISCOUNT = 0;
export const TEST_SETTING_CART_POPUP_PROMOCODE = 'GIFT50';
export const TEST_SETTING_CART_POPUP_DELAY = 1;
export const TEST_SETTING_USE_CART_POPUP_PROMOCODE = true;
export const TEST_SETTING_USE_CHECKOUT_POPUP_PROMOCODE = true;
export const TEST_SETTING_MAINTENANCE_MODE = false;
export const TEST_SETTING_CART_POPUP_DISCOUNT = 20;
export const TEST_SETTING_CART_POPUP_DOLLAR = 0;
export const TEST_FOUR2_CHECKOUT_PROMO = true;
export const BLACK_FRIDAY_VARIANT_ORIGIN = 'origin-bg';
export const BLACK_FRIDAY_VARIANT_BLACK = 'black-bg';
export const BLACK_FRIDAY_VARIANT_ORANGE = 'orange-bg';
// Test Launch Darkly Settings
export const TEST_LAUNCHDARKLY_VARIATIONS = {
  'checkout-popup-delay': TEST_SETTING_CHECKOUT_POPUP_DELAY,
  'checkout-popup-promocode': TEST_SETTING_CHECKOUT_POPUP_PROMOCODE,
  'use-cart-popup-promocode': TEST_SETTING_USE_CART_POPUP_PROMOCODE,
  'cart-popup-delay': TEST_SETTING_CART_POPUP_DELAY,
  'cart-popup-promocode': TEST_SETTING_CART_POPUP_PROMOCODE,
  'use-checkout-popup-promocode': TEST_SETTING_USE_CHECKOUT_POPUP_PROMOCODE,
  'four2-checkout-promo': TEST_FOUR2_CHECKOUT_PROMO,
};
