import unreduxed from 'unreduxed';
import { eventTypes } from '../common/const';
import { useSurvey } from './SurveyContainer';
import { postCheckoutClick, postEvent, postReviewClick } from '../common/next-util';

const container = () => {
  const quizEmail = useSurvey((c) => c.quizEmail);
  const sendEvent = (type: string) => {
    let email = '';
    if (quizEmail) {
      email = quizEmail;
    }
    // Make sure event has been sent before changing the page
    if (type === eventTypes.CART_PAGE_VIEW) {
      postReviewClick(email);
    }
    if (type === eventTypes.PAYMENT_PAGE_VIEW) {
      postCheckoutClick(email);
    }
  };

  const createEvent = (event: string, email: string, token: string) => {
    postEvent(event, email, token);
  }

  return { sendEvent, createEvent };
};

export const [EventsProvider, useEvents] = unreduxed(container);
