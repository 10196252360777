import { useRouter } from 'next/router';
import React, { useState } from 'react';
import unreduxed from 'unreduxed';
import useFetch from 'use-http';
import { eventTypes } from '../common/const';
import { sendEvent, sendGa4Event } from '../common/GTM';
import { insiderTrackEvent } from '../common/insider';
import { Product } from '../types/Product';
import { useAuth } from './AuthContainer';
import { useCart } from './CartContainer';
import { useLaunchDarkly } from './LaunchDarklyContainer';
import { useRecommendation } from './RecommendationContainer';
import { useSurvey } from './SurveyContainer';
import { postEvent } from '../common/next-util';

const container = () => {
  const router = useRouter();
  const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);
  const [showHighRiskPopup, setShowHideRiskPopup] = useState(false);
  const [showAdded, setShowAdded] = useState<{ display: boolean, message: string, delay?: number }>({ display: false, message: '', delay: 5.5 });
  const [highRiskProduct, setHighRiskProduct] = useState<Product>();
  const [showModalAdded, setShowModalAdded] = useState(false);
  const [productAdded, setProductAdded] = useState('');
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const addToCart = useCart((cartContainer) => cartContainer.addToCart);
  const setCart = useCart((cartContainer) => cartContainer.setCart);
  const token = useAuth((authContainer) => authContainer.token);
  const customer = useAuth((authContainer) => authContainer.customer);
  const subscription = useAuth((authContainer) => authContainer.subscription);
  const removeProducts = useRecommendation((recommendationContainer) => recommendationContainer.removeProducts);
  const quizEmail = useSurvey((surveyContainer) => surveyContainer.quizEmail);
  const trackEvent = useLaunchDarkly((launchDarklyContainer) => launchDarklyContainer.trackEvent);
  const setItemHaveNewTag = useCart((cartContainer) => cartContainer.setItemHaveNewTag);
  const itemHaveNewTag = useCart((cartContainer) => cartContainer.itemHaveNewTag);

  let subscriptionsAddUrl = '/api/v1/subscriptions';
  if (subscription) subscriptionsAddUrl = `/api/v1/subscriptions/${subscription.id}`;

  const { put } = useFetch(
    subscriptionsAddUrl,
  );

  async function sleep(ms: number): Promise<void> {
    const timeout = new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
    await timeout;
  }

  const showMessageAdded = async (name: string) => {
    if (!showAdded.display) {
      setShowAdded({ display: true, message: `${name} Added`, delay: 5.5 });
      setTimer(setTimeout(() => {
        setShowAdded({ display: false, message: '' });
      }, 6000));
    } else {
      setShowAdded({ display: false, message: '', delay: 0.5 });
      clearTimeout((timer as ReturnType<typeof setTimeout>));
      await sleep(0.5);
      setShowAdded({ display: true, message: `${name} Added`, delay: 5.5 });
      setTimer(setTimeout(() => {
        setShowAdded({ display: false, message: '' });
      }, 6000));
    }
  };

  const doAdd = async (e: React.MouseEvent<Element, MouseEvent>, product: Product, event?: string,  isReactivation?: boolean) => {
    const found: Product | undefined = removeProducts.find((item) => item.id === product.id);
    // console.log('found', found);
    e.preventDefault();
    if (found !== undefined) {
      setShowHideRiskPopup(true);
      sendEvent('product', 'show warning popup', found.warningReason.join(' '), true);
      setHighRiskProduct(found);
      return;
    }
    if (token && subscription) {
      const data = { action: 'add', product_id: product.id, quantity: product.quantity };
      put(data);
      setCart((c) => [...c, { productId: product.id, quantity: product.quantity }]);
      showMessageAdded(product.name);
      if (isReactivation) {
        await trackEvent('successful-add-items');
        const findItem = itemHaveNewTag.find((i) => i.productId === product.id)
        if (!findItem) setItemHaveNewTag((i) => [...i, { productId: product.id, quantity: product.quantity }]);
      }
      if (customer?.paymentGateway === 'AFTERPAY' && !router.asPath.includes('recommendation')) {
        setProductAdded(product.name);
        setShowModalAdded(true);
      }
    } else {
      addToCart(product);
      showMessageAdded(product.name);
      let email = '';
      if (quizEmail) {
        email = quizEmail;
      }
      postEvent(eventTypes.ADD_TO_CART, email);
      sendEvent('product', 'add-to-cart', `${product.sku}`);
      const persistentMenuClass = 'persistent-menu';
      const recoProductClass = 'reco-product';
      const button = e.target as HTMLButtonElement;
      let action = 'click';
      if (button.className.includes(persistentMenuClass)) {
        action = 'persistentMenu';
      } 
      else if (button.className.includes(recoProductClass)) {
        action = 'recoProduct';
      } 
      sendGa4Event('addPack', action, product.name);
      // segment track
      insiderTrackEvent('addToCart', {
        product_id: product.id,
        product_sku: product.sku,
        product_name: product.name,
        product_price: product.price,
        product_quantity: product.quantity,
      });
    }
    if (event && event !== '') trackEvent(event);
  };

  const doAddHighRiskProduct = async (e: React.MouseEvent<Element, MouseEvent>, product: Product, isReactivation?: boolean) => {
    e.preventDefault();
    const data = { action: 'add', product_id: product.id, quantity: product.quantity };
    put(data);
    setShowHideRiskPopup(false);
    // setShowModal(false);
    setCart((c) => [...c, { productId: product.id, quantity: product.quantity }]);
    if (isReactivation) {
      await trackEvent('successful-add-items');
      const findItem = itemHaveNewTag.find((i) => i.productId === product.id)
      if (!findItem) setItemHaveNewTag((i) => [...i, { productId: product.id, quantity: product.quantity }]);
    }
    showMessageAdded(product.name);
  };

  return {
    showHighRiskPopup,
    setShowHideRiskPopup,
    showAdded,
    setShowAdded,
    highRiskProduct,
    doAdd,
    doAddHighRiskProduct,
    setIsMobileScreen,
    isMobileScreen,
    showModalAdded,
    setShowModalAdded,
    productAdded,
  };
};

export const [ProductProvider, useProduct] = unreduxed(container);
