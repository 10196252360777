import unreduxed from 'unreduxed';
import { useState } from 'react';

const container = () => {
  const env = process.env.NEXT_PUBLIC_ENV;
  const [showHeader, setShowHeader] = useState(true);
  const [isShowSideBar, setIsShowSideBar] = useState<boolean>(false);
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const [headerVersion, setHeaderVersion] = useState<null | string>(null);
  const [bannerTextChange, setBannerTextChange] = useState<boolean>(false);
  const [debug, setDebug] = useState<boolean>(env !== 'production');
  return {
    showHeader,
    setShowHeader,
    isShowSideBar,
    setIsShowSideBar,
    setIsShowButton,
    isShowButton,
    headerVersion,
    setHeaderVersion,
    bannerTextChange,
    setBannerTextChange,
    debug,
    setDebug,
  };
};

export const [HeaderProvider, useHeader] = unreduxed(container);
