export interface Question {
  criteria: Criteria[];
  enable: boolean;
  display?: boolean;
  error: string;
  firstQuiz: boolean;
  id: number;
  order: number;
  profile: boolean;
  options: Option[];
  max: number;
  min: number;
  optionCriteria?: boolean;
  response?: string | unknown;
  optionId?: string;
  class?: string;
  answered?: boolean;
  questionGroupId: number;
  quiz: string;
  short: string;
  skipUser: boolean;
  subtitle: string;
  text: string;
  textFilled?: string;
  title: string;
  type: string;
  whyWeAsk: boolean;
  whyWeAskContent: string;
  updatedAt: string;
  previousResponse?: string;
  criteriaIsAnd: boolean;
}
export interface Option {
  criterias: Criteria[];
  disclaimerText: string;
  disclaimerTitle: string;
  exclusive: boolean;
  id: number;
  image: string;
  mailchimpTag: string;
  order: number;
  removeProduct: string;
  short: string;
  text: string;
  subtitle?: string;
  rank: number;
  selected: boolean;
  display?: boolean;
  response?: string;
}

export interface Criteria {
  id: number;
  options: [];
  question: string;
}

export type Payload = {
  currentQuestion: Question | undefined;
  firstQuestion: Question | undefined;
  questions: Question[];
  questionsTypeSection: Question[];
};

export const QUESTION_TYPE = {
  TEXT: 'text',
  INPUT: 'input',
  EMAIL: 'email',
  NUMBER: 'number',
  MULTICHOICE: 'multichoice',
  SECTION: 'section',
  BOOLEAN: 'boolean',
  RANKING: 'ranking',
  RATING: 'rating',
  MULTISELECT: 'multiselect',
};
