import { Category } from './Category';
import {
  HealthClaim, HealthClaimContent, ProductImages, ProductKeyFeatures, ProductTagResponse, RecommendationQuestion, RecommendationResponse, ShortHealthClaimByGoal,
} from './ServerResponses';
import { OrderItem } from './Order';

export const checkoutGroupLabel = {
  PILL: 'vitamins',
  POWDER: 'powders',
  EXTRA: 'extras',
  ADD_ONS: 'add-ons',
};

export const checkoutGroupValue = {
  PILL: 'PILL',
  POWDER: 'POWDER',
  EXTRA: 'EXTRA',
  ADD_ONS: 'ADD_ONS',
};

export interface Product {
  id: number;
  status: number;
  name: string;
  nickName: string;
  outOfStock: boolean;
  description: string;
  metaTitle?: string;
  metaDescription: string;
  metaShortDescription?: string;
  mediumDescription: string;
  bannerLarge: string;
  useLargePdpImage: boolean;
  sku: string;
  slug: string;
  largePdpImage: string;
  listSmall: string;
  hideCategory: boolean;
  pdpPriceText: string;
  listLarge: string;
  shortDescription: string;
  price: number;
  quantity: number;
  label: string;
  hidden: boolean,
  hideFromLibrary: boolean;
  recommendation: RecommendationResponse;
  categories: Category[];
  productReccomendationBanner: string;
  productTinyMobile: string;
  productReccomendationBannerMobile: string;
  productFeatureBannerMobile: string;
  scientific: boolean;
  traditionally: boolean;
  benefit: string;
  productFeatureBanner: string;
  ourHeading1?: string;
  ourBody1?: string;
  ourHeading2?: string;
  ourBody2?: string;
  qualityIconNgmo: boolean;
  qualityIconVegetarian: boolean;
  qualityIconVegan: boolean;
  qualityIconNgluten: boolean;
  qualityIconNlactose: boolean;
  qualityIconHalal: boolean;
  longName: string;
  unit: string;
  dosage: number;
  dosageDescription: string;
  ingredients: string;
  disclaimer: string;
  brand: string;
  bulk: boolean;
  allergens: string;
  maxQuantity: number;
  suggestedDose: number;
  suggestedDoseText: string;
  checkoutGroup: string;
  noDollarDiscount: boolean;
  noPercentDiscount: boolean;
  goalBones: string;
  goalBrain: string;
  goalDigestion: string;
  goalEnergy: string;
  goalEyes: string;
  goalFitness: string;
  goalHair: string;
  goalHeart: string;
  goalImmunity: string;
  goalJoints: string;
  goalNails: string;
  goalSkin: string;
  goalSleep: string;
  goalStress: string;
  goalPrenatal: string;
  goalMensHealth: string;
  goalWomensHealth: string;
  hideGoal: boolean;
  lifeStyle: string;
  diet: string;
  customDetails: string;
  useCustomDetails: boolean;
  detailLarge: string;
  detailSmall: string;
  hideBreakdown: boolean;
  howItWorks: string;
  researchLink: string;
  warningReason: string[];
  blockedCountries: string
  bookletReason: string,
  recommendedNote?: RecommendationQuestion,
  replacementSku: string,
  healthClaims: HealthClaim[],
  canonicalTag: string,
  extraDetails: {
    DietaryBoost: HealthClaimContent[],
    productKeyFeatures: ProductKeyFeatures[],
    shortHealthClaimByGoal: ShortHealthClaimByGoal[],
    SuggestedUse: string,
    productImages : ProductImages[],
    stampIcon: number,
    productTags: ProductTagResponse[],
    newDetailImages: string
  },
  orderItem?: OrderItem,
  type?: string,
  toldUs?: string,
  availableForSubscription: boolean,
  availableForOneOff: boolean,
  isProcessedInternally: boolean,
}
