import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import unreduxed from 'unreduxed';
import { createLocalStorageStateHook } from 'use-local-storage-state';
import { useAuth } from './AuthContainer';
import { OrderConfirmation } from '../types/Order';

interface Confirmation {
  email: string;
  userAlreadyPassword: boolean;
}

export const useLocalStorageClickedBtnShare = createLocalStorageStateHook<boolean>('clickedBtnShare');
export const useLocalStorageConfirmation = createLocalStorageStateHook<Confirmation>('confirmation', {
  email: '',
  userAlreadyPassword: false,
});

const container = () => {
  const [clickedBtnShare, setClickedBtnShare] = useLocalStorageClickedBtnShare();
  const [confirmation, setConfirmation] = useLocalStorageConfirmation();
  const [orderConfirmation, setOrderConfirmation] = useState<OrderConfirmation>({
    orderNumber: '',
    orderDate: '',
    shipping: 0,
    subtotal: 0,
    total: 0,
    estimatedDelivery: '',
    products: [],
    shippingMethodId: '1',
    phone: '',
    addressDetail: '',
  });

  const { tokenSetPassword, setTokenSetPassword } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!router.asPath.includes('checkout/confirmation') && tokenSetPassword && confirmation) {
      setTokenSetPassword.reset();
      setConfirmation.reset();
      setClickedBtnShare.reset();
    }
  }, []);

  return {
    confirmation,
    setConfirmation,
    clickedBtnShare,
    setClickedBtnShare,
    orderConfirmation,
    setOrderConfirmation,
  };
};

export const [ConfirmationProvider, useConfirmation] = unreduxed(container);
