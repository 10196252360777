import assign from 'lodash/assign';
import { useRouter } from 'next/router';
import unreduxed from 'unreduxed';
import useLocalStorageState from 'use-local-storage-state';
import { useEffect, useState } from 'react';
import type { CartItem } from '../types/CartItem';
import { Country } from '../types/Country';
import { checkoutGroupValue, type Product } from '../types/Product';
import { useCountry } from './CountryContainer';
import { discountType, productStatus, promoCodeTypes } from '../common/const';
import { usePromoCode } from './PromoCodeContainer';
import {
  getOrderDataOnApplyingPromoCode as getOrderDataOnApplyingPromoCodeV2,
  getShippingFee as getShippingFeeV2,
  getTotal as getTotalV2,
  getSubTotal as getSubTotalV2,
  getTotalAfterDiscount as getTotalAfterDiscountV2,
  getTotalAddOnsProducts,
  hasAddOnsProducts,
} from '../common/new-utils';
import { formatter, getOrderDataOnApplyingPromoCode, getShippingFee, getSubTotal, getTotal, getTotalAfterDiscount, isValidPromoCode } from '../common/util';
import { ItemsDiscount, OrderInformation } from '../types/Order';
import { sendEcommerceEvent, sendGa4Event } from '../common/GTM';
import { SubscriptionFrequency } from '../types/SubscriptionFrequency';
import { ExtraDiscount } from '../types/PromoCode';

export type BillingModel = {
  doubleSubscription: boolean;
  nameOnPack: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  suburb: string;
  country: string;
  state: string;
  postcode: string;
  authorityToLeave: boolean;
  email: string;
  phone: string;
  promocode?: string;
  solidDispenser: boolean;
  solidDispenserColor: string;
  monthlyPacks: number;
  subscriptionFrequencyId?: number;
  paymentGateway: string;
};
export const samplePlans = [
  {
    id: 0,
    name: 'Monthly',
    slug: 'monthly',
    description: '',
    refillableDispenser: true,
    saveOnShipping: false,
    accessToApp: true,
    exclusiveDiscountsAndOffers: false,
    dietAndLifestyle: false,
    supportFromNutritionists: false,
    extraDiscountCode: '',
    subscriptionFrequency: 30,
  },
  {
    id: 0,
    name: 'Bimonthly',
    slug: 'bimonthly',
    description: ' | Most Popular | Save 5%',
    refillableDispenser: true,
    saveOnShipping: true,
    accessToApp: true,
    exclusiveDiscountsAndOffers: false,
    dietAndLifestyle: false,
    supportFromNutritionists: false,
    extraDiscountCode: 'EXTRA5',
    subscriptionFrequency: 60,
  },
  {
    id: 0,
    name: 'Quarterly',
    slug: 'quarterly',
    description: ' | Best Value | Save 10%',
    refillableDispenser: true,
    saveOnShipping: true,
    accessToApp: true,
    exclusiveDiscountsAndOffers: true,
    dietAndLifestyle: true,
    supportFromNutritionists: true,
    extraDiscountCode: 'EXTRA10',
    subscriptionFrequency: 90,
  },
];
type Props = {
  countries: Country[];
  products: Product[];
};
const container = (props: Props) => {
  const { countries } = props;
  const router = useRouter();
  const { asPath } = router;
  const country = useCountry((con) => con.country);
  const localPromoCode = usePromoCode((con) => con.localPromoCode);
  const localGiftCard = usePromoCode((con) => con.localGiftCard);
  const [cart, setCart] = useLocalStorageState<CartItem[]>('cart', []);
  const [extraDiscount, setExtraDiscount] = useState(0);
  const [extraDiscountCode, setExtraDiscountCode] = useState('');
  const [extraDiscountList, setExtraDiscountList] = useState<ExtraDiscount[]>([]);
  const [itemHaveNewTag, setItemHaveNewTag] = useLocalStorageState<CartItem[]>('itemHaveNewTag', []);
  const [newSubscriptionPlanVersion, setNewSubscriptionPlanVersion] = useState({
    control: true,
    variation1: false,
    variation2: false,
  });
  const [checkoutFlowRevamp, setCheckoutFlowRevamp] = useState({
    control: true,
    variation1: false,
    variation2: false,
  });
  const [billing, setBilling] = useState<BillingModel>({
    doubleSubscription: false,
    authorityToLeave: true,
    nameOnPack: '',
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    suburb: '',
    country: '',
    state: '',
    postcode: '',
    email: '',
    phone: '',
    solidDispenser: false,
    solidDispenserColor: '',
    monthlyPacks: 1,
    subscriptionFrequencyId: 0,
    paymentGateway: '',
  });
  let initialOrderInfo: OrderInformation = {
    totalBeforeDiscount: 0,
    subtotal: 0,
    totalOrder: 0,
    promotionalText: '',
    discountAmount: '',
    totalDoubleOrder: 0,
    freeProducts: '',
    freeGift: '',
    isFreeGiftOrFreeProduct: false,
    firstMonthDiscountDollar: '',
    firstMonthDiscountPercent: 0,
    secondMonthDiscountDollar: '',
    secondMonthDiscountPercent: 0,
    firstMonthDiscountText: '',
    secondMonthDiscountText: '',
    numberOfMonthsApplied: '',
    onlyOnePromotionalText: true,
    giftCardDiscountText: '',
    giftCardDiscountDollar: '',
    giftCardDiscountRemaining: '',
    discountAvailableFor: '',
    itemsDiscount: [] as ItemsDiscount[],
    shipping: 0,
    discountDollar: 0,
    discountPercent: 0,
    discountEachMonths: [],
  };
  const [orderInfo, setOrderInfo] = useState(initialOrderInfo);
  const [promocodeImpactProduct, setPromocodeImpactProduct] = useState<CartItem | null>(null);
  const [showConfirmUpdateCart, setShowConfirmUpdateCart] = useState(false);
  const [subscriptionFrequencies, setSubscriptionFrequencies] = useState<SubscriptionFrequency[]>(samplePlans);
  const [enableAddOnsFlag, setEnableAddOnsFlag] = useState(false);

  if (props.products) {
    cart.forEach((cI: CartItem) => {
      const product = props.products.find((p) => p.id === cI.productId);
      if (!product || product.status === productStatus.PENDING || product.outOfStock) {
        setCart.reset();
        if (asPath.includes('checkout/review') || asPath.includes('checkout/place-order')) {
          router.push('/cart-empty');
        }
      }
      assign(cI, { product });
    });
  }

  const addToCart = (product: Product, quantity?: number) => {
    // if (!quantity) { quantity = product.quantity; }
    const q = quantity || product.quantity;
    setCart((c) => [...c, { productId: product.id, quantity: q }]);
    sendEcommerceEvent('add_to_cart', [product]);
  };

  const inCart = (product: Product): boolean => {
    const found: CartItem | undefined = cart.find((item: CartItem) => item.productId === product.id);
    return found !== undefined;
  };
  const canAdd = (product: Product): boolean => !inCart(product);
  const emptyCart = () => {
    setCart([]);
  };

  const updateOrderInfo = (cartItems?: CartItem[]): OrderInformation => {
    const newCart = cartItems || cart;
    let selectedCountry = countries.find((item: Country) => {
      const countryCode = billing.country !== '' ? billing.country : country?.country;
      return item.country === countryCode;
    });
    if (selectedCountry && country && selectedCountry?.country === country?.country && country?.enableShippingThresholdTesting) {
      selectedCountry = { ...selectedCountry, ...{ freeShipping: country.freeShipping } };
    }
    // console.log('selectedCountry', selectedCountry);
    const extraDiscountPercent = billing.doubleSubscription ? extraDiscount : 0;
    if (selectedCountry) {
      const shipping = getShippingFee(newCart, selectedCountry);
      if (localPromoCode.applied && localPromoCode.item) {
        let { total, discount, promotionalInformation, itemsDiscount } = getOrderDataOnApplyingPromoCode(newCart, localPromoCode.item, extraDiscount, selectedCountry);
        initialOrderInfo.totalOrder = total.totalAfterDiscount;
        // initialOrderInfo.itemsDiscount = itemsDiscount;
        initialOrderInfo.discountAmount = `-$${formatter.format(discount.discountDollar)}`;
        if (billing.doubleSubscription) {
          ({ total, discount, promotionalInformation, itemsDiscount } = getOrderDataOnApplyingPromoCode(newCart, localPromoCode.item, extraDiscount, selectedCountry, true));
          initialOrderInfo.totalDoubleOrder = total.totalAfterDiscount;
          initialOrderInfo.discountAmount = `-$${formatter.format(discount.discountDollar)}`;
        }
        const firstMonthDiscountDollar = `-$${formatter.format(discount.firstMonthDiscountDollar || 0)}`;
        const secondMonthDiscountDollar = `-$${formatter.format(discount.secondMonthDiscountDollar || 0)}`;
        initialOrderInfo = {
          ...initialOrderInfo,
          ...promotionalInformation,
          ...{ firstMonthDiscountDollar, secondMonthDiscountDollar },
          ...total,
          itemsDiscount,
          shipping,
        };
      } else {
        const totalOrder = getTotal(newCart, 0, selectedCountry);
        const subtotal = getSubTotal(newCart, billing.doubleSubscription);
        initialOrderInfo.shipping = shipping;
        initialOrderInfo.totalOrder = totalOrder;
        initialOrderInfo.totalBeforeDiscount = totalOrder;
        initialOrderInfo.subtotal = subtotal;
        if (billing.doubleSubscription) {
          const totalDoubleOrder = getTotal(newCart, extraDiscountPercent, selectedCountry, true);
          initialOrderInfo.totalDoubleOrder = totalDoubleOrder;
          initialOrderInfo.totalBeforeDiscount = totalDoubleOrder;
        }
      }
      if (billing.doubleSubscription) initialOrderInfo.onlyOnePromotionalText = false;
      if (initialOrderInfo.freeProducts !== '' && initialOrderInfo.freeGift !== '') initialOrderInfo.onlyOnePromotionalText = false;
      if (localPromoCode.item?.discountAmount || localPromoCode.item?.percent !== '') {
        initialOrderInfo.onlyOnePromotionalText = false;
      }
      if (localPromoCode.item?.type !== promoCodeTypes.DEGRESSIVE && !localPromoCode.item?.discountAmount && localPromoCode.item?.percent === '')
        initialOrderInfo.isFreeGiftOrFreeProduct = true;
      if (localGiftCard) {
        const total = billing.doubleSubscription ? initialOrderInfo.totalDoubleOrder : initialOrderInfo.totalOrder;
        const totalAfterDiscount = getTotalAfterDiscount(total, discountType.DOLLAR, localGiftCard.discountAmount);
        let giftCardDiscountRemaining = '';
        let giftCardDiscountDollar = 0;
        let giftCardDiscountText = 'Gift card applied.';
        if (Number(localGiftCard.discountAmount) > total) giftCardDiscountDollar = total;
        if (total >= Number(localGiftCard.discountAmount)) giftCardDiscountDollar = Number(localGiftCard.discountAmount);
        const giftCardRemaining = Number(localGiftCard.discountAmount) - total;
        if (Number(localGiftCard.discountAmount) - total > 0) {
          giftCardDiscountRemaining = `*A remaining of $${formatter.format(giftCardRemaining)} will be applied to your account as credits.`;
          giftCardDiscountText += '*';
        }
        initialOrderInfo = {
          ...initialOrderInfo,
          totalDoubleOrder: totalAfterDiscount,
          totalOrder: totalAfterDiscount,
          giftCardDiscountText,
          giftCardDiscountDollar: `-$${formatter.format(giftCardDiscountDollar)}`,
          giftCardDiscountRemaining,
        };
      }
      setOrderInfo(initialOrderInfo);
    }
    return initialOrderInfo;
  };

  const updateOrderInfoV2 = (cartItems?: CartItem[]): OrderInformation => {
    const factor = billing.monthlyPacks || 1;

    const newCart = (cartItems || cart).filter(f => f.product?.checkoutGroup !== checkoutGroupValue.ADD_ONS);
    let selectedCountry = countries.find((item: Country) => {
      const countryCode = billing.country !== '' ? billing.country : country?.country;
      return item.country === countryCode;
    });
    if (selectedCountry && country && selectedCountry?.country === country?.country && country?.enableShippingThresholdTesting) {
      selectedCountry = { ...selectedCountry, ...{ freeShipping: country.freeShipping } };
    }
    const extraDiscountPercent = factor > 1 ? extraDiscount : 0;
    if (selectedCountry) {
      const shipping = getShippingFeeV2(newCart, selectedCountry);
      const subtotal = getSubTotalV2(newCart);
      if (localPromoCode.applied && localPromoCode.item) {
        const { total, discount, promotionalInformation, itemsDiscount } = getOrderDataOnApplyingPromoCodeV2(newCart, localPromoCode.item, extraDiscount, selectedCountry, factor);
        const discountAmount = `-$${formatter.format(discount.discountDollar)}`;
        const firstMonthDiscountDollar = `-$${formatter.format(discount.firstMonthDiscountDollar || 0)}`;
        const secondMonthDiscountDollar = `-$${formatter.format(discount.secondMonthDiscountDollar || 0)}`;
        initialOrderInfo = {
          ...initialOrderInfo,
          ...promotionalInformation,
          ...{ firstMonthDiscountDollar, secondMonthDiscountDollar },
          ...total,
          subtotal,
          itemsDiscount,
          shipping,
          discountAmount,
          totalOrder: total.totalAfterDiscount,
        };
      } else {
        const totalOrder = getTotalV2(newCart, extraDiscountPercent, selectedCountry, factor);
        initialOrderInfo = { ...initialOrderInfo, totalOrder, subtotal, shipping, totalBeforeDiscount: totalOrder };
      }
      if (factor > 1) initialOrderInfo.onlyOnePromotionalText = false;
      if (initialOrderInfo.freeProducts !== '' && initialOrderInfo.freeGift !== '') initialOrderInfo.onlyOnePromotionalText = false;
      if (localPromoCode.item?.discountAmount || localPromoCode.item?.percent !== '') {
        initialOrderInfo.onlyOnePromotionalText = false;
      }
      if (localPromoCode.item?.type !== promoCodeTypes.DEGRESSIVE && !localPromoCode.item?.discountAmount && localPromoCode.item?.percent === '')
        initialOrderInfo.isFreeGiftOrFreeProduct = true;
      if (localGiftCard) {
        const total = initialOrderInfo.totalOrder;
        const totalAfterDiscount = getTotalAfterDiscountV2(total, discountType.DOLLAR, localGiftCard.discountAmount);
        let giftCardDiscountRemaining = '';
        let giftCardDiscountDollar = 0;
        let giftCardDiscountText = 'Gift card applied.';
        if (Number(localGiftCard.discountAmount) > total) giftCardDiscountDollar = total;
        if (total >= Number(localGiftCard.discountAmount)) giftCardDiscountDollar = Number(localGiftCard.discountAmount);
        const giftCardRemaining = Number(localGiftCard.discountAmount) - total;
        if (Number(localGiftCard.discountAmount) - total > 0) {
          giftCardDiscountRemaining = `*A remaining of $${formatter.format(giftCardRemaining)} will be applied to your account as credits.`;
          giftCardDiscountText += '*';
        }
        initialOrderInfo = {
          ...initialOrderInfo,
          totalOrder: totalAfterDiscount,
          giftCardDiscountText,
          giftCardDiscountDollar: `-$${formatter.format(giftCardDiscountDollar)}`,
          giftCardDiscountRemaining,
        };
      }

      if (hasAddOnsProducts(cart)) {
        initialOrderInfo = {
          ...initialOrderInfo,
          subtotal: initialOrderInfo.totalOrder,
          totalOrder: initialOrderInfo.totalOrder + getTotalAddOnsProducts(cart),
        }
      }

      setOrderInfo(initialOrderInfo);
    }
    return initialOrderInfo;
  };

  const toCart = (items: CartItem[]): CartItem[] => {
    const newCart = items.map((item: CartItem) => ({ productId: item.productId, quantity: item.quantity, product: item.product }));
    return newCart;
  };

  const removeItem = (item: CartItem, confirmRemove = false) => {
    let removeProduct = true;
    let newCart = toCart(cart);
    newCart = newCart.filter((i: CartItem) => i.productId !== item.productId);
    if (localPromoCode && localPromoCode.item && localPromoCode.applied && country && !confirmRemove) {
      const { valid } = isValidPromoCode(newCart, localPromoCode.item, country, billing.doubleSubscription);
      if (!valid) {
        removeProduct = false;
        setPromocodeImpactProduct({ ...item, type: 'remove' });
        setShowConfirmUpdateCart(true);
      }
    }
    if (removeProduct) {
      setCart(newCart);
      const items = cart.map((CartItem) => CartItem.product);
      sendGa4Event('removeProduct', 'click', item.product?.name);
      sendEcommerceEvent('remove_from_cart', items as Product[]);
    }
  };

  const changeQuantityItem = (item: CartItem, quantity: number, confirmChange = false) => {
    let updateQuantity = true;
    const newCart = toCart(cart);
    const targetIndex = cart.findIndex((i) => i.productId === item.productId);
    newCart[targetIndex].quantity += quantity;
    if (localPromoCode && localPromoCode.item && localPromoCode.applied && country && !confirmChange) {
      const { valid } = isValidPromoCode(newCart, localPromoCode.item, country, billing.doubleSubscription);
      if (!valid) {
        updateQuantity = false;
        setPromocodeImpactProduct({ ...item, quantity, type: 'update' });
        setShowConfirmUpdateCart(true);
      }
    }
    if (updateQuantity) {
      setCart(newCart);
      const cartItems: CartItem[] = newCart.map((cI: CartItem) => assign(cI, { product: props.products.find((p) => p.id === cI.productId) }));
      if (!billing.subscriptionFrequencyId) {
        updateOrderInfo(cartItems);
      } else {
        updateOrderInfoV2(cartItems);
      }
      sendGa4Event('adjustQty', 'click', `${item.product?.name}-${newCart[targetIndex].quantity}`);
    }
  };

  useEffect(() => {
    if (!billing.subscriptionFrequencyId) {
      updateOrderInfo();
    } else {
      updateOrderInfoV2();
    }
  }, [
    localPromoCode.applied,
    localPromoCode.item?.id,
    localPromoCode.item?.items,
    billing.doubleSubscription,
    cart.length,
    billing.country,
    country,
    localGiftCard,
    billing.monthlyPacks,
    billing.subscriptionFrequencyId,
    extraDiscount,
  ]);
  return {
    cart,
    addToCart,
    canAdd,
    inCart,
    removeItem,
    emptyCart,
    setCart,
    changeQuantityItem,
    setBilling,
    billing,
    orderInfo,
    updateOrderInfo,
    updateOrderInfoV2,
    itemHaveNewTag,
    setItemHaveNewTag,
    checkoutFlowRevamp,
    setCheckoutFlowRevamp,
    extraDiscount,
    setExtraDiscount,
    extraDiscountCode,
    setExtraDiscountCode,
    newSubscriptionPlanVersion,
    setNewSubscriptionPlanVersion,
    promocodeImpactProduct,
    setPromocodeImpactProduct,
    showConfirmUpdateCart,
    setShowConfirmUpdateCart,
    extraDiscountList,
    setExtraDiscountList,
    subscriptionFrequencies,
    setSubscriptionFrequencies,
    enableAddOnsFlag,
    setEnableAddOnsFlag,
  };
};

export const [CartProvider, useCart] = unreduxed(container);
