/* eslint-disable import/no-cycle */
import { filter, find, map, unionBy, maxBy, isEmpty, assign, findIndex, last, head, isNil } from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import unreduxed from 'unreduxed';
import useFetch from 'use-http';
import useLocalStorageState from 'use-local-storage-state';
import { quizType, TOKEN } from '../common/const';
import { sendAdditionalData, sendAnswerGA, sendEvent, sendGa4Event } from '../common/GTM';
import { toQuizAnswer } from '../common/transformers';
import { Question, Option, Payload, QUESTION_TYPE } from '../types/Question';
import { QuestionResponse } from '../types/QuestionResponse';
import { QuizAnswer, SubmitAnswersData } from '../types/QuizAnswer';
import { QuizAnswerResponse } from '../types/ServerResponses';
import { useLaunchDarkly } from './LaunchDarklyContainer';
import { insiderTrackEvent } from '../common/insider';
import { makeResponseId } from '../common/util';
import { QuizResponse } from '../types/QuizResponse';
import appRoutes from '../config/AppRoutes';
import { ENABLE_NEW_QUIZ_UI_FLAG } from '../common/feature-flags';
import { StatusProgress, SurveyVersion } from '../types/Survey';

const container = () => {
  const testMode = process.env.NEXT_PUBLIC_ENV === 'test';
  const [localCurrentQuestion, setLocalCurrentQuestion] = useLocalStorageState<QuestionResponse>('currentQuestion');
  const [localQuestions, setLocalQuestions] = useLocalStorageState<QuestionResponse[]>('questions');
  const [responses, setResponses] = useLocalStorageState<QuestionResponse[]>('responses');
  const [quizEmail, setQuizEmail] = useLocalStorageState<string>('quizEmail');
  const [showModalUnfinishedQuiz, setShowModalUnfinishedQuiz] = useState(false);
  const [showModalRedoQuiz, setShowModalRedoQuiz] = useState(false);
  const [showValidateEmailModal, setShowValidateEmailModal] = useState<boolean>(false);
  const [showWhyWeAsk, setShowWhyWeAsk] = useState<boolean>(false);
  const [quizStartQuizEnd, setQuizStartQuizEnd] = useState(false);
  const [clickLoginFromSurvey, setClickLoginFromSurvey] = useState(false);
  const [quizResponsesProfile, setQuizResponsesProfile] = useState<QuizAnswer[] | []>([]);
  const [isRedoText, setIsRedoText] = useState<boolean>(false);
  const [showButtonTakeQuiz, setShowButtonTakeQuiz] = useState<boolean | null>(true);
  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState<string>('');
  const [payload, setPayload] = useState<Payload>({
    currentQuestion: undefined,
    firstQuestion: undefined,
    questions: [],
    questionsTypeSection: [],
  });
  const [showScreenLoader, setShowScreenLoader] = useState<boolean>(true);
  const [showSubmitLoader, setShowSubmitLoader] = useState<boolean>(false);
  const [fadeDirection, setFadeDirection] = useState<string>('fade-center'); // right, left, center
  const [animation, setAnimation] = useState<boolean>(false);
  const [isIntro, setIsIntro] = useState(false);
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [statusProgress, setStatusProgress] = useState<StatusProgress>({
    youAreHere: 'hidden',
    halfway: 'hidden',
    lastOne: 'hidden',
  });
  const [halfwayDone, setHalfwayDone] = useState<boolean>(false);
  const [isMultiSelected, setIsMultiSelected] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false); // show loading gif or question
  const [surveyVersion, setSurveyVersion] = useState<SurveyVersion>(SurveyVersion.DEFAULT);
  const [responseId, setResponseId] = useLocalStorageState('responseId', '');
  const [firstName, setFirstName] = useLocalStorageState('firstName', '');

  const { get } = useFetch();
  const { post: submitQuiz } = useFetch(appRoutes.QUIZ_RESPONSES);

  const trackEvent = useLaunchDarkly((launchDarklyContainer) => launchDarklyContainer.trackEvent);
  const getVariation = useLaunchDarkly((launchDarklyContainer) => launchDarklyContainer.getVariation);

  const router = useRouter();
  const { asPath } = router;

  const getActionProductPage = () => {
    const { body } = document;
    const html = document.documentElement;
    const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    if (window.pageYOffset < (height / 2)) {
      return 'topPage';
    } 
    return 'bottomPage';
  }
  const getLabelProductPage = () => {
    const { sku } = router.query;
    if (typeof sku === 'string') {
      return sku;
    }
    return '';
  }

  const getTakeQuizEventParams = () => {
    if (asPath === '/') {
      return {action: 'click', label: 'heroBanner'};
    }
    if (asPath.includes('/products/')) {
      return {action: getActionProductPage(), label: getLabelProductPage()};
    }

    return {action: 'click', label: ''};
  }
		
  const trackGa4 = () => {
    const {action, label} = getTakeQuizEventParams();
    sendGa4Event('takeQuiz', action, label);
  };

  const surveyPageRouter = async (event?: string) => {
    if (event && event !== '') {
      await trackEvent(event);
    }
    if (!localCurrentQuestion) {
      const token = window.localStorage.getItem(TOKEN);
      trackGa4();
      if (quizEmail) {
        setShowModalRedoQuiz(true);
      } else if (token) {
        const quizResponses = await get(`${appRoutes.QUIZ_RESPONSES}?quiz=${quizType.MAIN_QUIZ}&limit=1`);
        if (quizResponses && quizResponses.data && quizResponses.data.length) {
          setShowModalRedoQuiz(true);
        } else if (asPath.includes('/reactivation')) {
          router.push({
            pathname: '/survey',
            query: { backToReactivationScreen: true },
          });
        } else {
          window.location.href = '/survey';
        }
      } else {
        window.location.href = '/survey';
      }
    } else {
      setShowModalUnfinishedQuiz(true);
    }
  };

  const checkIsRedo = async () => {
    if (!localCurrentQuestion) {
      if (quizEmail) return true;
      if (quizResponsesProfile.length) return true;
      const token = window.localStorage.getItem(TOKEN);
      if (token) {
        const res = await get(appRoutes.QUIZ_RESPONSES_PROFILE);
        if (res.data && res.data.answerquizzes) {
          const data = res.data.answerquizzes.map((item: QuizAnswerResponse) => {
            const quizAnswer: QuizAnswer = toQuizAnswer(item);
            return quizAnswer;
          });
          setQuizResponsesProfile(data);
        }
        const result = !!(res && res.data);
        setIsRedoText(result);
        return result;
      }
    }
    return false;
  };

  const setRank = (question: Question, answer: Option, value: boolean) => {
    if (value) {
      const highestRank = maxBy(question.options, (option: Option) => option.rank);
      const rank = highestRank && highestRank.rank ? highestRank.rank + 1 : 1;
      assign(answer, { rank });
    } else {
      const higherRank = question.options.filter(
        (option: Option) => option.rank && option.rank !== 0 && option.rank > answer.rank,
      );
      if (!isEmpty(higherRank)) {
        higherRank.map((item: Option) => assign(item, { rank: item.rank - 1 }));
      }
      assign(answer, { rank: 0 });
    }
    return question;
  };

  const storeLocalQuestions = () => {
    let newQuestions = map(payload.questions, (question: Question) => {
      const item = { short: question.short, response: question.response ? question.response : '' };
      return item;
    });
    newQuestions = unionBy(responses, newQuestions, 'short');
    setLocalQuestions(newQuestions as QuestionResponse[]);
  };

  const saveAnswers = (newListQuest: Question[], question: Question, response: string) => {
    const newAnswers = [];
    let newResponse: QuestionResponse[] = responses || [];
    const isExistAnswer = find(responses, (e: QuestionResponse) => e.short === question.short);
    if (isExistAnswer) {
      newResponse = map(responses, (e: QuestionResponse) => {
        if (e.short === question.short) e.response = response;
        return e;
      });
    } else {
      const answer = { short: question.short, response };
      newAnswers.push(answer);
    }
    newResponse = [...newResponse, ...newAnswers];
    newResponse = filter(newResponse, (res: QuestionResponse) => res.response !== '');
    const responseRemove = filter(responses, (res: QuestionResponse) => !find(newListQuest, ['short', res.short]));
    newResponse = filter(newResponse, (res: QuestionResponse) => responseRemove.indexOf(res) < 0);
    setResponses(newResponse);
    storeLocalQuestions();
  };

  const save = async (data: SubmitAnswersData) => {
    const response: QuizResponse = {
      answers: data.answers,
      response_id: data.responseId,
    };
    const res = await submitQuiz(response);
    if (res && res.error_code && res.error_code === 401) {
      setTimeout(() => {
        setLocalCurrentQuestion.reset();
        setLocalQuestions.reset();
        setResponses.reset();
        router.reload();
      }, 3000);
    }
    if (res.data && res.data.success === true) {
      setLocalQuestions.reset();
      setLocalCurrentQuestion.reset();
      setPayload({
        currentQuestion: undefined,
        firstQuestion: undefined,
        questions: [],
        questionsTypeSection: [],
      });

      setResponseId(data.responseId);
      const token = window.localStorage.getItem(TOKEN);
      if (!token) {
        const questionFirstName = find(data.answers, ['short', 'first_name']);
        if (questionFirstName) setFirstName(questionFirstName.response);
      }
      const backToReactivationScreen = router.asPath.includes('backToReactivationScreen') || null;
      if (backToReactivationScreen) {
        setShowButtonTakeQuiz(false);
        router.push({
          pathname: '/recommendation',
          query: {
            backToReactivationScreen,
          },
        });
      } else {
        router.push('/recommendation', '/recommendation', { scroll: false });
      }
    }
  };

  const onSubmit = async () => {
    setShowSubmitLoader(true);
    const data: SubmitAnswersData = {
      answers: responses || [],
      responseId: makeResponseId(22),
    };

    sendEvent('quiz', 'quiz_complete', 'success');
    trackEvent('quiz-completion');
    const emailQuestion = find(responses, (q) => q.short === 'email');
    sendAdditionalData(emailQuestion?.response || '');
    // segment track
    insiderTrackEvent('quizComplete', { quiz: 'main' });
    save(data);
  };

  const resetDivider = () => {
    if (!isEmpty(payload.questionsTypeSection)) {
      const tmpQuestionsTypeSection = [...payload.questionsTypeSection];
      map(tmpQuestionsTypeSection, (item) => {
        const newItem = item;
        newItem.class = '';
        return newItem;
      });
      setPayload({
        ...payload,
        questionsTypeSection: tmpQuestionsTypeSection,
      });
    }
  };

  const setDividerClass = () => {
    resetDivider(); // clear divider class
    // set class animation
    const questionTypeSection = find(payload.questionsTypeSection, [
      'short',
      payload.currentQuestion?.short,
    ]) as Question;
    const index = findIndex(payload.questionsTypeSection, ['short', payload.currentQuestion?.short]);
    // TODO: sendEvent
    if (payload.currentQuestion) {
      sendEvent('quiz', 'section', payload.currentQuestion.title);
      // segment track
      insiderTrackEvent('quizSection', {
        section: payload.currentQuestion.title,
      });
    }
    if (index > 0) {
      if (payload.currentQuestion?.short === questionTypeSection?.short) {
        const tmpQuestionsTypeSection = [...payload.questionsTypeSection];
        tmpQuestionsTypeSection[index - 1].class = 'in';
        setPayload({
          ...payload,
          questionsTypeSection: tmpQuestionsTypeSection,
        });
        setTimeout(
          () => {
            tmpQuestionsTypeSection[index - 1].class = 'done';
            setPayload({
              ...payload,
              questionsTypeSection: tmpQuestionsTypeSection,
            });
            setTimeout(
              () => {
                questionTypeSection.class = 'in';
              },
              testMode ? 10 : 500,
            );
          },
          testMode ? 10 : 500,
        );
      }
    } else if (index === 0) {
      questionTypeSection.class = 'in';
    }
  };

  const setProgressBarClasses = () => {
    setStatusProgress({
      youAreHere: 'hidden',
      halfway: 'hidden',
      lastOne: 'hidden',
    });

    if (payload.currentQuestion?.order === payload.firstQuestion?.order) {
      setStatusProgress({ ...statusProgress, youAreHere: 'youAreHere' });
      setTimeout(() => {
        setStatusProgress({ ...statusProgress, youAreHere: 'hidden' });
      }, 3000);
    } else if (currentProgress > 0.5 && currentProgress <= 0.6 && !halfwayDone) {
      setStatusProgress({ ...statusProgress, halfway: 'halfway' });
      setHalfwayDone(true);
      setTimeout(() => {
        setStatusProgress({ ...statusProgress, halfway: 'hidden' });
      }, 3000);
    } else if (payload.currentQuestion?.short === 'email') {
      setStatusProgress({ ...statusProgress, lastOne: 'lastOne' });
      setTimeout(() => {
        setStatusProgress({ ...statusProgress, lastOne: 'hidden' });
      }, 3000);
    }
  };

  const calculateProgressId = () => {
    const filterQuestions = filter(
      payload.questions,
      (question) => question.type !== QUESTION_TYPE.TEXT && question.type !== QUESTION_TYPE.SECTION,
    );
    const totalNumberOfQuestions = filterQuestions.length;
    const progressId = findIndex(filterQuestions, ['order', payload.currentQuestion?.order]) + 1;
    if (progressId !== -1) {
      const tmpCurrentProgress = progressId / totalNumberOfQuestions;
      setCurrentProgress(tmpCurrentProgress);
      if (tmpCurrentProgress < 0.5) {
        setHalfwayDone(false);
      }
      setProgressBarClasses();
    }
  };

  const checkMultiSelected = (options: Option[]) => {
    if (!isEmpty(options)) {
      const item = find(options, ['selected', true]);
      if (item) {
        setIsMultiSelected(true);
      } else {
        setIsMultiSelected(false);
      }
    }
  };

  const changeCurrentQuestion = (): void => {
    const nextQuestion = filter(
      payload.questions,
      (ques) => ques.order > (payload.currentQuestion as Question).order,
    ) as Question[];
    setTimeout(
      async () => {
        payload.currentQuestion = head(nextQuestion);
        setLocalCurrentQuestion({ short: payload.currentQuestion ? payload.currentQuestion.short : '', response: '' });
        sendEvent('quiz', 'view_question', payload.currentQuestion?.short, true);
        // segment track
        insiderTrackEvent('viewQuestion', {
          question: payload.currentQuestion?.short || '',
        });
        setPayload({ ...payload });
        if (
          payload.currentQuestion?.type === QUESTION_TYPE.TEXT ||
          (surveyVersion === SurveyVersion.DEFAULT && payload.currentQuestion?.type === QUESTION_TYPE.SECTION)
        ) {
          setShowScreenLoader(false);
          if (surveyVersion === SurveyVersion.DEFAULT && payload.currentQuestion?.type === QUESTION_TYPE.SECTION) {
            setDividerClass();
          }
          setTimeout(() => {
            setShowScreenLoader(true);
            setTimeout(() => setAnimation(animation), testMode ? 300 : 400);
            setTimeout(() => {
              changeCurrentQuestion();
            }, 700);
          }, 1500);
        } else {
          calculateProgressId();
        }
        setShowScreenLoader(false);
        checkMultiSelected(payload.currentQuestion?.options as Option[]);
      },
      testMode ? 10 : 300,
    );
  };

  const start = async () => {
    if (isNil(localCurrentQuestion)) {
      setResponses.reset();
    }
    trackEvent('click-start-quiz');
    sendEvent('quiz', 'click_start');
    // segment track
    insiderTrackEvent('clickStartQuiz', {});
    setIsIntro(false);
    calculateProgressId();
    setTimeout(
      () => {
        sendEvent('quiz', 'view_question', payload.currentQuestion?.short, true);
        // segment track
        insiderTrackEvent('viewQuestion', {
          question: payload.currentQuestion?.short || '',
        });
        setFadeDirection('fade-center');
        setTimeout(() => setAnimation(!animation), 0);
        setShowScreenLoader(false);
        setTimeout(() => setIsStarted(true), 0);
      },
      testMode ? 10 : 1500,
    );
  };

  const next = () => {
    setShowScreenLoader(true);
    setFadeDirection(fadeDirection);
    setTimeout(() => setAnimation(!animation), 0);
    setTimeout(
      () => {
        changeCurrentQuestion();
      },
      testMode ? 10 : 300,
    );
  };

  const onContinue = () => {
    if (payload.currentQuestion) sendAnswerGA(payload.currentQuestion, payload.currentQuestion.response as string);
    next();
  };

  const back = () => {
    setShowScreenLoader(true);
    setFadeDirection('fade-left');
    setTimeout(() => setAnimation(!animation), 0);
    setTimeout(
      () => {
        setTimeout(
          () => {
            setShowScreenLoader(true);
            const previousQuestionList = filter(
              payload.questions,
              (ques) =>
                ques.order < (payload.currentQuestion as Question).order &&
                ques.type !== 'text' &&
                ques.type !== 'section',
            );
            payload.currentQuestion = last(previousQuestionList as Question[]);
            const currentResponse = find(responses, ['short', payload.currentQuestion?.short]);
            setLocalCurrentQuestion(currentResponse);
            setPayload({
              ...payload,
              currentQuestion: payload.currentQuestion,
            });
            sendEvent('quiz', 'view_question', payload.currentQuestion?.short, true);
            // segment track
            insiderTrackEvent('viewQuestion', {
              question: payload.currentQuestion?.short || '',
            });
            checkMultiSelected(payload.currentQuestion?.options as Option[]);
            setShowScreenLoader(false);
            calculateProgressId();
          },
          testMode ? 10 : 300,
        );
      },
      testMode ? 10 : 300,
    );
  };

  useAsyncEffect(async () => {
    checkIsRedo();

    const getPrivacyPolicyCheckbox = await getVariation('privacy-policy-checkbox', '');
    setPrivacyPolicyCheckbox(getPrivacyPolicyCheckbox);

    const getQuizStartQuizEnd = await getVariation('quiz-start-quiz-end', true);
    setQuizStartQuizEnd(getQuizStartQuizEnd);

    const enableNewQuizUi = await getVariation(ENABLE_NEW_QUIZ_UI_FLAG, false);
    if (enableNewQuizUi) {
      setSurveyVersion(SurveyVersion.ADD_ONS);
    }
  }, []);

  return {
    responses,
    setResponses,
    localCurrentQuestion,
    setLocalCurrentQuestion,
    localQuestions,
    setLocalQuestions,
    quizEmail,
    setQuizEmail,
    setRank,
    showModalUnfinishedQuiz,
    setShowModalUnfinishedQuiz,
    surveyPageRouter,
    showModalRedoQuiz,
    setShowModalRedoQuiz,
    clickLoginFromSurvey,
    setClickLoginFromSurvey,
    quizResponsesProfile,
    isRedoText,
    showButtonTakeQuiz,
    setShowButtonTakeQuiz,
    privacyPolicyCheckbox,
    showWhyWeAsk,
    setShowWhyWeAsk,
    quizStartQuizEnd,
    payload,
    setPayload,
    saveAnswers,
    showValidateEmailModal,
    setShowValidateEmailModal,
    onSubmit,
    showSubmitLoader,
    responseId,
    setResponseId,
    firstName,
    setFirstName,
    showScreenLoader,
    setShowScreenLoader,
    animation,
    setAnimation,
    fadeDirection,
    setFadeDirection,
    isIntro,
    setIsIntro,
    currentProgress,
    statusProgress,
    isStarted,
    start,
    next,
    back,
    isMultiSelected,
    checkMultiSelected,
    onContinue,
    changeCurrentQuestion,
    surveyVersion,
  };
};

export const [SurveyProvider, useSurvey] = unreduxed(container);
