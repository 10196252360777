export const CONTROL = 'control';
export const VARIATION_1 = 'variation-1';
export const VARIATION_2 = 'variation-2';

export const ADD_ONS_FLAG = 'enable-add-ons';

export const ENABLE_NEW_QUIZ_UI_FLAG = 'enable-new-quiz-ui';

export const SOLID_DISPENSER_ENABLED_FLAG = 'solid-dispenser-enabled';

export const SOLID_DISPENSER_COLORS_FLAG = 'solid-dispenser-colors';
