import { useEffect, useState } from 'react';
import unreduxed from 'unreduxed';
import useLocalStorageState from 'use-local-storage-state';
import useFetch from 'use-http';
import { useIntercom } from 'react-use-intercom';
import { useRouter } from 'next/router';
import { useAsyncEffect } from 'use-async-effect';
import jws from 'jws';
import sha256 from 'sha256';
import { fromUnixTime, format, add } from 'date-fns';
import { Customer } from '../types/Customer';
import { User } from '../types/User';
import { Subscription } from '../types/Subscription';
import { ProfileResponse } from '../types/ServerResponses';
import { toCustomer, toUser } from '../common/transformers';
import { SubscriptionItem } from '../types/SubscriptionItem';
import { useLaunchDarkly } from './LaunchDarklyContainer';
import { OrderUser } from '../types/Order';
import { OrderLatest } from '../types/OrderLatest';
import { getSubscription } from '../common/next-util';
import { useCart } from './CartContainer';
import { FLAG_NEW_SUBCRIPTION_PLANS, FLAG_WEB_NEW_SUBCRIPTION_PLANS_CURRENT_CUSTOMER, LIMITED_TIME, TOKEN } from '../common/const';
import { usePromoCode } from './PromoCodeContainer';
import { clearLocalStorage, convertQueryParams, getAuthQueryToken } from '../common/util';
import { useCountry } from './CountryContainer';
import { useSurvey } from './SurveyContainer';
import { insiderTrackEvent } from '../common/insider';

type Props = {
  authToken: string;
};
const container = (props: Props) => {
  const country = useCountry((c) => c.country);
  const setCountry = useCountry((c) => c.setCountry);
  const setCart = useCart((c) => c.setCart);
  const setExtraDiscount = useCart((c) => c.setExtraDiscount);
  const setEmail = useLaunchDarkly((c) => c.setEmail);
  const applyPromoCodeFromParams = usePromoCode((c) => c.applyPromoCodeFromParams);
  const removePromoCode = usePromoCode((c) => c.removePromoCode);
  const [user, setUser] = useState<User | null>(null);
  const { update: updateIntercom } = useIntercom();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [subscription, setSubscription] = useLocalStorageState<Subscription | null>('subscription', null);
  const [token, setToken] = useLocalStorageState(TOKEN, '');
  const [tokenSetPassword, setTokenSetPassword] = useLocalStorageState('tokenSetPassword', '');
  const [remember, setRemember] = useLocalStorageState('remember', true);
  const setItemHaveNewTag = useCart((cartContainer) => cartContainer.setItemHaveNewTag);
  const [subscriptionItems, setSubscriptionItems] = useState<SubscriptionItem[] | []>([]);
  const { get: getProfile } = useFetch('/api/v1/me/profile?includes=roles');
  const { get: refreshToken } = useFetch('/api/v1/auth/refresh');
  const { get: getExtraDiscount } = useFetch('api/v1/users/extra-discount');
  const router = useRouter();
  const { asPath } = router;
  const [order, setOrder] = useState<OrderUser[]>([]);
  const [orderLatest, setOrderLatest] = useState<OrderLatest | null>();
  const [closeReactivation, setCloseReactivation] = useLocalStorageState<boolean>('closeReactivation', false);
  const [hiddenScrollY, setHiddenScrollY] = useLocalStorageState<boolean>('hiddenScroll', false);
  const setShowButtonTakeQuiz = useSurvey((surveyContainer) => surveyContainer.setShowButtonTakeQuiz);
  const getFlagByKey = useLaunchDarkly((launchDarklyContainer) => launchDarklyContainer.getFlagByKey);
  const getVariation = useLaunchDarkly((launchDarklyContainer) => launchDarklyContainer.getVariation);
  const [flagNewSubscriptionPlan, setFlagNewSubscriptionPlan] = useState<string>('');
  const isNewCustomer = token === '';
  const logout = (redirectToHome = true) => {
    setUser(null);
    setRemember(false);
    setCustomer(null);
    removePromoCode();
    setToken.reset();
    setCart.reset();
    setExtraDiscount(0);
    clearLocalStorage();
    setEmail('');
    setItemHaveNewTag([]);
    setCloseReactivation(false);
    setShowButtonTakeQuiz(true);
    setFlagNewSubscriptionPlan('');

    if (redirectToHome) {
      router.replace('/');
    }
  };

  if (process.browser) {
    window.onbeforeunload = () => {
      if (token && !remember) {
        setToken('');
      }
    };
  }

  const handleExtraDiscount = async () => {
    const extraDiscount = await getExtraDiscount();
    if (extraDiscount && extraDiscount.data) {
      setExtraDiscount(extraDiscount.data.discount_percent);
    }
  };

  const fetchProfile = async (): Promise<User & Customer | null> => {
    const res: ProfileResponse = (await getProfile()) as ProfileResponse;
    let profile: User & Customer | null = null;

    if (res.data && res.data.customer) {
      const customerData: Customer = toCustomer(res.data.customer.data);
      setCustomer(customerData);

      const userData: User = toUser(res.data);
      setUser(userData);
      setEmail(userData.email);

      profile = { ...customerData, ...userData };

      await handleExtraDiscount();
    }

    return profile;
  };

  useEffect(() => {
    if (props.authToken) {
      setToken(props.authToken);
    }
  }, [props.authToken]);

  useEffect(() => {
    if (customer && country && customer.freeShippingThreshold) {
      setCountry({ ...country, ...{ freeShipping: customer.freeShippingThreshold, freeShippingThreshold: customer.freeShippingThreshold } });
    }
  }, [customer]);

  useAsyncEffect(async () => {
    if (token && !router.asPath.includes('/auth/reset-password')) {
      const profile = await fetchProfile();
      if (profile) {
        updateIntercom({
          email: profile.email,
          name: profile.firstName,
          userHash: sha256(profile.email),
          userId: profile.userId.toString(),
        });
      }

      const sub = await getSubscription(token);
      if (sub) {
        setSubscription(sub);
      }
    }
  }, [token, router.query]);

  useAsyncEffect(async () => {
    if (token && !router.asPath.includes('/auth/reset-password')) {
      const decode = jws.decode(token);

      const expired = format(fromUnixTime(decode.payload.exp), 'yyyy-MM-dd');

      const estimatedExpirationDate = format(add(new Date(), { seconds: LIMITED_TIME }), 'yyyy-MM-dd');

      if (estimatedExpirationDate !== expired) {
        const res = await refreshToken();

        if (res && res.access_token) {
          setToken(res.access_token);
        }
      }
    }
  }, []);

  useAsyncEffect(async () => {
    if (token) {
      const flag = await getFlagByKey('new-subscription-plans');
      if (flag) {
        setFlagNewSubscriptionPlan(flag);
      }
      if (!flag || flag === FLAG_NEW_SUBCRIPTION_PLANS.CONTROL) {
        const forceFlag = await getVariation(FLAG_WEB_NEW_SUBCRIPTION_PLANS_CURRENT_CUSTOMER.NAME, FLAG_WEB_NEW_SUBCRIPTION_PLANS_CURRENT_CUSTOMER.CONTROL);
        if (forceFlag) {
          setFlagNewSubscriptionPlan(FLAG_NEW_SUBCRIPTION_PLANS.FORCE_NEW);
        }
        insiderTrackEvent('launchdarkly', {
          feature_flag: FLAG_WEB_NEW_SUBCRIPTION_PLANS_CURRENT_CUSTOMER.NAME,
          variation_value: forceFlag,
        });
      }
    } else {
      setFlagNewSubscriptionPlan('');
    }
  }, [token]);

  useAsyncEffect(async () => {
    if (subscription && (asPath.includes('promo=') || asPath.includes('ref='))) {
      let promoCode;
      const query = convertQueryParams();
      if (query && (query.ref || query.promo)) {
        promoCode = query.ref ? query.ref : query.promo;
        if (query.ref) delete query.ref;
        if (query.promo) delete query.promo;
      }
      const auth = getAuthQueryToken();
      if (promoCode && auth) {
        router.push(
          {
            pathname: router.pathname,
            query,
          },
          undefined,
          { shallow: true },
        );
        await applyPromoCodeFromParams(promoCode, token, subscription, auth);
      }
    }
    if (subscription?.status !== 'active' && token) {
      setCloseReactivation(false);
    }
  }, [subscription]);

  return {
    user,
    customer,
    token,
    setToken,
    setUser,
    setCustomer,
    logout,
    remember,
    setRemember,
    subscription,
    setSubscription,
    fetchProfile,
    setSubscriptionItems,
    subscriptionItems,
    tokenSetPassword,
    setTokenSetPassword,
    order,
    setOrder,
    orderLatest,
    setOrderLatest,
    closeReactivation,
    setCloseReactivation,
    hiddenScrollY,
    setHiddenScrollY,
    flagNewSubscriptionPlan,
    handleExtraDiscount,
    isNewCustomer,
  };
};

export const [AuthProvider, useAuth] = unreduxed(container);
