/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CartItem } from '../types/CartItem';
import { InsiderProduct } from '../types/InsiderProduct';
import { InsiderUser } from '../types/InsiderUser';
import { OrderLatest } from '../types/OrderLatest';
import { Product } from '../types/Product';
import { AddressResponse } from '../types/ServerResponses';
import { SubscriptionItem } from '../types/SubscriptionItem';

export type ProductObject = Product;
const insiderScriptSrc = `//${process.env.NEXT_PUBLIC_INSIDER_PARTNER_NAME}.api.useinsider.com/ins.js?id=${process.env.NEXT_PUBLIC_INSIDER_ID}`;

export const createInsiderTag = () => {
  const script = document.createElement('script');
  script.src = insiderScriptSrc;
  script.async = true;
  script.type = 'text/javascript';

  return script;
};

function pad(n: number) {
  return n < 10 ? `0${  n}` : n;
}

function ISODateString(d: Date) {
  return `${d.getUTCFullYear()  }-${ 
    pad(d.getUTCMonth() + 1)  }-${ 
    pad(d.getUTCDate())  }T${ 
    pad(d.getUTCHours())  }:${ 
    pad(d.getUTCMinutes())  }:${ 
    pad(d.getUTCSeconds())  }Z`
}

function isScriptLoaded(scriptSrc: string) {
  const scripts = document.getElementsByTagName('script');

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.includes(scriptSrc)) {
      return true;
    }
  }

  return false;
}

export const convertToInsiderProducts = (cartItems: CartItem[] | SubscriptionItem[]) => {
  const items = cartItems.map((item) => ({
    product: {
      id: item.product?.id.toString(),
      name: item.product?.name,
      taxonomy: item.product?.categories.map((a) => a.name),
      currency: 'AUD',
      unit_price: parseFloat((item.product?.price || 0).toFixed(2)),
      unit_sale_price: parseFloat((item.product?.price || 0).toFixed(2)),
      url: window.location.href,
      color: '',
      product_image_url: item.product?.listLarge,
    },
    quantity: item.quantity,
    subtotal: parseFloat(((item.product?.price || 0) * item.quantity).toFixed(2)),
  }));

  return items;
};

export const insiderTrackEvent = async (
  eventName: string,
  eventParams: { [key: string]: string | number | boolean }
): Promise<void> => {
  const { Insider } = window as any;
  const isLoaded = isScriptLoaded(insiderScriptSrc);

  if (!isLoaded) {
    const insiderTag = createInsiderTag();
    document.head.appendChild(insiderTag);
  }

  if (Insider) {
    Insider.eventManager.dispatch('init-manager:re-initialize');
    const insiderEventTimestamp = ISODateString(new Date());
    Insider.track('events', [{
      event_name: eventName,
      timestamp: insiderEventTimestamp,
      event_params: {
        custom: eventParams,
      },
    }]);
  }
};

export const insiderTrackPageView = async (): Promise<void> => {
  const { Insider } = window as any;

  if (Insider) {
    const userLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
    Insider.eventManager.dispatch('init-manager:re-initialize');
    Insider.track('events', [{
      event_name: 'page_view',
      timestamp: ISODateString(new Date()),
      event_params: {
        custom: {
          context_page_referrer: document.referrer || '',
          url: window.location.href,
          context_locale: userLocale,
          context_page_title: document.title,
          context_user_agent: navigator.userAgent,
          context_ip: sessionStorage.getItem('ip') || ''
        },
      },
    }]);
  }
};

export const insiderObject = (
  data: {
    page?: string,
    user?: InsiderUser,
    insiderProduct?: InsiderProduct,
    list?: InsiderProduct[]
  }
) => {
  const {
    page, user, insiderProduct, list,
  } = data;

  (window as any).insider_object = (window as any).insider_object || {};

  if (page) {
    (window as any).insider_object.page = { type: page };
  }

  if (user) {
    (window as any).insider_object.user = user;
  }

  if (insiderProduct) {
    (window as any).insider_object.product = insiderProduct;
  }

  if (list) {
    (window as any).insider_object.listing = { items: list };
  }

  const insiderTag = createInsiderTag();
  document.head.appendChild(insiderTag);
  insiderTrackPageView();
};

export const insiderSetBasket = (
  data: {
    total: number,
    promoCode: string,
    cart: CartItem[],
    discountAmount: number,
    discountPercent: number,
    shippingFee: number
  }
) => {
  const {
    total, promoCode, cart, discountAmount, discountPercent, shippingFee,
  } = data;

  if ((window as any).insider_object && (window as any).insider_object.page) {
    (window as any).insider_object.basket = {
      currency: 'AUD',
      total,
      vouchers: [promoCode],
      voucher_discount: discountAmount,
      voucher_discount_ratio: discountPercent,
      shipping_cost: shippingFee,
      line_items: convertToInsiderProducts(cart),
    };
  }

  const insiderTag = createInsiderTag();
  document.head.appendChild(insiderTag);
};

export const insiderSetTransaction = (
  data: {
    subscriptionItems: SubscriptionItem[],
    order: OrderLatest,
    address: AddressResponse
  }
) => {
  const {
    subscriptionItems, order, address,
  } = data;

  if ((window as any).insider_object && (window as any).insider_object.page) {
    (window as any).insider_object.transaction = {
      order_id: order.orderId,
      currency: 'AUD',
      total: order.amount,
      payment_type: order.paymentMethod,
      vouchers: [order.coupon],
      voucher_discount: order.discountAmount,
      voucher_discount_ratio: order.discountPercent,
      shipping_cost: order.shipping,
      delivery: {
        country: address.country,
        city: address.state,
        district: address.address_line_1,
      },
      line_items: convertToInsiderProducts(subscriptionItems),
    };
  }

  const insiderTag = createInsiderTag();
  document.head.appendChild(insiderTag);
};

export const inSiderSetUser = (
  userId: string | number,
  email: string,
  name: string,
  surname: string,
  data = { gdpr_optin: true, email_optin: true }
) => {
  if ((window as any).insider_object && (window as any).insider_object.page) {
    (window as any).insider_object.user = {
      uuid: userId.toString(),
      email,
      name,
      surname,
      custom: { user_id: userId, test_multi_1: { recommended: true, current_order: false } },
      username: email,
      ...data,
    };
  }

  const insiderTag = createInsiderTag();
  document.head.appendChild(insiderTag);
};