import maxBy from 'lodash/maxBy';
import unreduxed from 'unreduxed';
import md5 from 'md5';
import { useState } from 'react';
import {
  setBaseUrl, apiKeys, setTenant, setDefaultApiKey, trackedUsers, itemsRecommenders, events,
} from 'cherry.ai';
import { GOAL_QUESTION } from '../common/const';
import { QuestionResponse } from '../types/QuestionResponse';
import { ParameterSetRecommender } from '../types/Four2Response';
import { sendEvent } from '../common/GTM';
import { storeCherryData } from '../common/next-util';

const four2Endpoint = process.env.NEXT_PUBLIC_FOUR2_ENDPOINT || 'https://canary.cherry.ai';
const four2Tenant = process.env.NEXT_PUBLIC_FOUR2_TENANT || 'vitable';
const four2ApiKey = process.env.NEXT_PUBLIC_FOUR2_API_KEY || 'NmJkMWUwOGQtMWM4ZS00NDM5LWFjZTctMjE5N2ZjMWNjZjQz';
const four2CheckoutPromoRecommender = process.env.NEXT_PUBLIC_FOUR2_CHECKOUT_PROMO_RECOMMENDER || 3;

setBaseUrl(four2Endpoint);
setTenant(four2Tenant);
setDefaultApiKey(four2ApiKey);
const container = () => {
  const [four2Token, setFour2Token] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [four2Response, setFour2Response] = useState<any>();
  const apiKey = four2ApiKey;

  async function loginFour2() {
    if (four2Token !== '') return four2Token;
    const res = await apiKeys
      .exchangeApiKeyAsync({ apiKey })
      // eslint-disable-next-line camelcase
      .then((response) => response) // use the access_token for requests.
      .catch((e) => e);
    setFour2Token(res.access_token);
    return res.access_token;
  }

  async function createUserOnFour2(email: string, firstName: string) {
    const token = await loginFour2();
    await trackedUsers.createOrUpdateTrackedUserAsync({
      user: {
        commonUserId: md5(email),
        name: firstName,
      },
      token,
      customer: null,
    });
  }

  async function invokeParameterSetRecommender(answers: QuestionResponse[], email: string, productCount: number, basketGMV: number) {
    const token = await loginFour2();
    const hashedEmail = md5(email);
    const goalAnswer = answers.find((a: QuestionResponse) => a.short === GOAL_QUESTION);
    const parameterSetRecommender: ParameterSetRecommender = {};
    parameterSetRecommender.productCount = productCount;
    parameterSetRecommender.basketGMV = basketGMV;
    if (goalAnswer) {
      const rank = goalAnswer.response.split(',');
      parameterSetRecommender.number_goals = rank.length;
      for (let i = 0; i < rank.length; i++) {
        parameterSetRecommender[`${rank[i]}_goal`] = i + 1;
      }
    }
    answers.map((i) => {
      parameterSetRecommender[`q_${i.short}`] = i.response;
      return i;
    });

    try {
      const response = await itemsRecommenders
        .invokeItemsRecommenderAsync({
          id: four2CheckoutPromoRecommender,
          token,
          input: {
            commonUserId: hashedEmail,
            arguments: parameterSetRecommender,
          },
          useInternalId: undefined,
        })
        .then((res) => res);
      setFour2Response(response);
      if (response) {
        const scoredItem = maxBy(response.scoredItems, 'score');
        const code = scoredItem?.item.properties.code;
        const cherryResponse = { correlatorId: response.correlatorId || '', code };
        // console.log('cherryResponse', cherryResponse);
        storeCherryData(parameterSetRecommender, cherryResponse);
      }
      // console.log('signalbox response', four2response);
      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('An error occurred with Four2', e);
      return null;
    }
  }

  async function getFour2PromoCode(answers: QuestionResponse[], email: string, productCount: number, basketGMV: number) {
    const response = await invokeParameterSetRecommender(answers, email, productCount, basketGMV);
    if (!response) return null;
    const scoredItem = maxBy(response.scoredItems, 'score');
    const promoCode = scoredItem?.item.properties.code;
    sendEvent('four2', 'promo', promoCode, true);
    return promoCode;
  }

  async function consumptionEvent(correlatorId: number, email: string) {
    const token = await loginFour2();
    const hashedEmail = md5(email);
    await events
      .createRecommendationConsumedEventAsync({
        token,
        commonUserId: hashedEmail,
        correlatorId,
        properties: null,
        customerId: '',
      })
      .then() // log information about the response.
      .catch();
  }

  return {
    loginFour2, createUserOnFour2, four2Token, four2Response, getFour2PromoCode, consumptionEvent,
  };
};

export const [Four2Provider, useFour2] = unreduxed(container);
