/* eslint-disable no-nested-ternary */
import { CachePolicies, Provider, CustomOptions } from 'use-http';
import React, { ReactNode } from 'react';
import { useNotification } from './NotificationContainer';
import { clearLocalStorage, handlingErrorMessages } from '../common/util';
import { TOKEN } from '../common/const';

type Props = {
  children: ReactNode;
};

const FetchProvider = ({ children }: Props): JSX.Element => {
  const notification = useNotification((container) => container.notification);
  const showNotification = useNotification((container) => container.showNotification);
  const showProgressBar = useNotification((container) => container.showProgressBar);
  const hideProgressBar = useNotification((container) => container.hideProgressBar);
  const fetchOptions: Partial<CustomOptions> = {
    cachePolicy: CachePolicies.NO_CACHE,
    interceptors: {
      request: async ({ options }) => {
        const o = options;
        let tokenJson = localStorage.getItem(TOKEN);
        if (!tokenJson && tokenJson !== '') {
          tokenJson = localStorage.getItem('tokenSetPassword');
        }
        let token = '';
        const headers: Headers = new Headers(o.headers);
        if (tokenJson && tokenJson !== 'undefined' && tokenJson.length > 5) {
          token = JSON.parse(tokenJson) as string;
          headers.set('Authorization', `Bearer ${token}`);
        }
        o.headers = headers;
        return o;
      },
    },
    loading: true,
    data: [],
    onNewData: (currData, newData) => {
      showProgressBar();

      if (newData) {
        hideProgressBar();

        if (newData.error_code) {
          if (newData.message && newData.message === 'jwt expired') {
            clearLocalStorage();
            window.location.href = '/';
          } else {
            const message = handlingErrorMessages(newData.message);
            showNotification(message, 'error');
          }
        }
      }

      return newData;
    },
  };
  // Options.headers['Content-Type'] = `application/json`
  const api = process.env.NEXT_PUBLIC_API;
  return (
    <Provider url={api} options={fetchOptions}>
      <div
        id="nf-notify"
        style={{ height: `${notification.message !== '' ? '40px' : '0'}` }}
        className={
          `${notification.type === 'error' ? 'notify-warning' : 'notify-success'} ${notification.type === 'success' ? 'success' : ''}`
        }
      >
        <div className="notify-container">
          <span
            className={
              notification.type === 'error'
                ? 'warn warning'
                : notification.type === 'success'
                  ? 'success-icon'
                  : ''
            }
          />
          <p className="notify-message">{notification.message}</p>
        </div>
      </div>
      {children}
    </Provider>
  );
};
export default FetchProvider;
