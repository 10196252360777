import cloneDeep from 'lodash/cloneDeep';
import { useRouter } from 'next/router';
import unreduxed from 'unreduxed';
import useLocalStorageState from 'use-local-storage-state';
import { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import useFetch from 'use-http';
import type { CartItem } from '../types/CartItem';
import { Country } from '../types/Country';
import { PromoCode, PromoCodeSession } from '../types/PromoCode';
import { kindOfDiscount, SUBSCRIPTION_STATUS_ACTIVE } from '../common/const';
import { getProducts, getPromoCode } from '../common/next-util';
import {
  getAuthQueryToken, getOrderDataOnApplyingPromoCode, getTotal,
} from '../common/util';
import { useNotification } from './NotificationContainer';
import { Subscription } from '../types/Subscription';
import { OrderInformation } from '../types/Order';

const container = () => {
  const [code, setCode] = useLocalStorageState<string>('code', '');
  const [giftCard, setGiftCard] = useLocalStorageState<string>('giftCard', '');
  const [localPromoCode, setLocalPromoCode] = useState<PromoCodeSession>({ applied: false });
  const [localGiftCard, setLocalGiftCard] = useState<PromoCode | null>(null);
  const [promoCodeWarning, setPromoCodeWarning] = useState<boolean>(false);
  const { showNotification } = useNotification();
  const { response, put } = useFetch();
  const router = useRouter();

  const setPromoCode = async (name: string, promoCode?: PromoCode, orderInfo?: OrderInformation, double?: boolean, items?: CartItem[]) => {
    let coupon;
    let errorMessage = '';
    const errorMessages = [];
    let type = '';
    let valid = true;
    let maxBasketCapValid = true;
    let miniumSpendValid = true;
    let productLimitValid = true;
    if (promoCode !== undefined) {
      coupon = cloneDeep(promoCode);
    } else {
      const res = await getPromoCode(name);
      if (res) {
        coupon = res;
      }
    }
    if (coupon && !coupon.valid) {
      setLocalPromoCode({
        code: name, applied: false, invalid: true, errorMessages: [coupon.errorMessage || ''],
      });
      return { valid: false, type };
    }
    if (coupon && coupon.valid) {
      if (coupon.productLimit !== null && coupon.productLimit !== undefined && coupon.productLimit !== '') {
        const sku = coupon.productLimit.replaceAll(' ', '');
        const products = await getProducts({ sku });
        if (products.length && items?.length) {
          // eslint-disable-next-line no-restricted-syntax
          for (const product of products) {
            const findProduct = items.find((item) => item.productId === product.id);
            if (findProduct) {
              productLimitValid = true;
              break;
            }
            productLimitValid = false;
          }
          if (!productLimitValid) {
            errorMessage = 'Please add eligible product(s) to Cart to use this code.';
            errorMessages.push(errorMessage);
          }
          coupon.items = products;
        }
      }
      if (coupon.maxBasketCap && orderInfo && orderInfo.totalOrder) {
        const maxBasketCap = double && coupon.maxBasketCap ? coupon.maxBasketCap * 2 : coupon.maxBasketCap;
        if (orderInfo.totalOrder > maxBasketCap) {
          maxBasketCapValid = false;
          if (double) {
            errorMessage = coupon.errorMessageForDouble || '';
          } else {
            errorMessage = coupon.errorMessage || '';
          }
          if (errorMessage !== '') errorMessages.push(errorMessage);
        }
      }
      if (coupon.miniumSpend && orderInfo && orderInfo.subtotal) {
        const miniumSpend = double && coupon.miniumSpend ? coupon.miniumSpend * 2 : coupon.miniumSpend;
        if (orderInfo.subtotal < miniumSpend) {
          miniumSpendValid = false;
          errorMessage = `$${miniumSpend} minimum spend excluding shipping. Please add more products to redeem offer.`;
          if (double) {
            errorMessage = coupon.errorMessageForDouble || errorMessage;
          } else {
            errorMessage = coupon.errorMessage || errorMessage;
          }
          if (errorMessage !== '') errorMessages.push(errorMessage);
        }
      }
      valid = maxBasketCapValid && miniumSpendValid && productLimitValid;
      if (coupon.giftCard) {
        setLocalGiftCard({ ...coupon });
        type = kindOfDiscount.GIFT_CARD;
        if (valid) setGiftCard(coupon.code);
      } else {
        setLocalPromoCode({
          item: { ...coupon, items: coupon.items }, code: coupon.code, applied: valid, invalid: !valid, errorMessage, errorMessages
        });
        type = kindOfDiscount.PROMO_CODE;
        if (valid) setCode(coupon.code);
      }
    }
    return { valid, type };
  };

  useAsyncEffect(async () => {
    if (code !== '' && !localPromoCode.applied) {
      await setPromoCode(code);
    }
    if (giftCard !== '' && !localGiftCard) {
      await setPromoCode(giftCard);
    }
  }, [code, giftCard]);

  const getQueryCode = (): string | undefined => {
    if (router.query && (router.query.ref || router.query.promo)) {
      const promoCode = router.query.ref ? router.query.ref : router.query.promo;
      return promoCode?.toString();
    }
    return undefined;
  };

  const applyPromoCodeToSub = async (promoCode: string, subId: number) => {
    await put(`/api/v1/subscriptions/${subId}/apply-promo-code`, { promocode: promoCode?.trim() });
    if (response.ok) {
      showNotification(`Promo code ${promoCode.toUpperCase()} applied!`, 'success');
      return true;
    }
    return false;
  };

  const applyPromoCodeFromParams = async (promoCode: string | undefined, token: string | null, subscriptionData: Subscription | null, authParam: string | null) => {
    if (promoCode && (!token || token === '') && !authParam) {
      if (!localPromoCode.applied && promoCode) {
        setCode((promoCode as string));
      }
    }
    if ((token || authParam) && promoCode && subscriptionData) {
      const codeUpcase = promoCode.toUpperCase();
      if (!localPromoCode.applied && !code && !subscriptionData.coupons) {
        let apply = true;
        if (subscriptionData.status === SUBSCRIPTION_STATUS_ACTIVE) {
          apply = await applyPromoCodeToSub(codeUpcase, subscriptionData.id);
        }
        if (apply) {
          setPromoCode(codeUpcase);
          if (subscriptionData.status !== SUBSCRIPTION_STATUS_ACTIVE) {
            showNotification(`Promo code ${codeUpcase} applied!`, 'success');
          }
        }
      }
      let showWarning = false;
      if (code && code !== codeUpcase) {
        showWarning = true;
      }
      if (!code && subscriptionData.coupons.length > 0 && subscriptionData.coupons !== codeUpcase) {
        showWarning = true;
      }
      if (showWarning) {
        setPromoCodeWarning(true);
      }
    }
  };

  useAsyncEffect(async () => {
    const promoCode = getQueryCode();
    const token = window.localStorage.getItem('token');
    const subscription = window.localStorage.getItem('subscription');
    let subData: Subscription | null = null;
    if (subscription) {
      subData = JSON.parse(subscription);
    }
    const auth = getAuthQueryToken();
    if (!auth && promoCode) {
      await applyPromoCodeFromParams(promoCode, token, subData, null);
    }
  }, [router.query]);

  const calculateTotal = (items: CartItem[], extraDiscount = 0, currentCountry: Country, double = false): number => {
    let total;
    if (!localPromoCode?.applied) {
      total = getTotal(items, extraDiscount, currentCountry, double);
      return total;
    }

    const discount = localPromoCode?.item;
    if (discount) {
      const result = getOrderDataOnApplyingPromoCode(items, discount, extraDiscount, currentCountry, double);
      total = result.total.totalAfterDiscount;
    } else {
      total = getTotal(items, extraDiscount, currentCountry, double);
    }
    return total;
  };

  const removePromoCode = (type?: string) => {
    if (type === kindOfDiscount.PROMO_CODE) {
      setLocalPromoCode({ applied: false });
      setCode.reset();
    }
    if (type === kindOfDiscount.GIFT_CARD) {
      setLocalGiftCard(null);
      setGiftCard.reset();
    }
    if (!type) {
      setLocalPromoCode({ applied: false });
      setCode.reset();
      setLocalGiftCard(null);
      setGiftCard.reset();
    }
  };

  return {
    localPromoCode,
    localGiftCard,
    setLocalPromoCode,
    setLocalGiftCard,
    getTotal,
    calculateTotal,
    setPromoCode,
    removePromoCode,
    setCode,
    setGiftCard,
    getQueryCode,
    code,
    promoCodeWarning,
    setPromoCodeWarning,
    applyPromoCodeToSub,
    applyPromoCodeFromParams,
    giftCard,
  };
};

export const [PromoCodeProvider, usePromoCode] = unreduxed(container);
