import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import maxBy from 'lodash/maxBy';
import { NextRouter, useRouter } from 'next/router';
import { useState } from 'react';
import unreduxed from 'unreduxed';
import useFetch from 'use-http';
import { eventTypes } from '../common/const';
import { sendGa4Event } from '../common/GTM';
import { toProduct, toRecommendationProducts } from '../common/transformers';
import { convertQueryParams } from '../common/util';
import { CartItem } from '../types/CartItem';
import { Category } from '../types/Category';
import { Product } from '../types/Product';
import {
  ProductResponseItem,
  Recommendation,
  RecommendationProduct,
  RecommendationQuestion,
} from '../types/ServerResponses';
import { useAuth } from './AuthContainer';
import { useCart } from './CartContainer';
import { useEvents } from './EventsContainer';
import { useSurvey } from './SurveyContainer';
import appRoutes from '../config/AppRoutes';

const container = () => {
  const [removeProducts, setRemoveProducts] = useState<Product[] | []>([]);
  const [recommendedProducts, setRecommendedProducts] = useState<Product[] | []>([]);
  const [recommendedOneOffProducts, setRecommendedOneOffProducts] = useState<Product[] | []>([]);
  const [enableAddOnsFlag, setEnableAddOnsFlag] = useState<boolean>(false);

  const responseId = useSurvey((con) => con.responseId);
  const firstName = useSurvey((con) => con.firstName);
  const quizEmail = useSurvey((con) => con.quizEmail);
  const setResponseId = useSurvey((con) => con.setResponseId);
  const setFirstName = useSurvey((con) => con.setFirstName);
  const setQuizEmail = useSurvey((con) => con.setQuizEmail);

  const { response, get } = useFetch();
  const token = useAuth((con) => con.token);
  const checkoutFlowRevamp = useCart((con) => con.checkoutFlowRevamp);
  const cart = useCart((con) => con.cart);
  const setCart = useCart((con) => con.setCart);
  const billing = useCart((con) => con.billing);
  const setBilling = useCart((con) => con.setBilling);
  const sendEvent = useEvents((con) => con.sendEvent);

  const recommendationRouter = useRouter();

  if (!token && !responseId && !isEmpty(removeProducts)) {
    setRemoveProducts([]);
  }

  async function getRecommendation(router: NextRouter) {
    let recommendationUrl = appRoutes.API_RECOMMENDATIONS_NEW;
    if (!token) {
      const params = router.asPath.split('?');
      let query;
      if (params.length >= 2) {
        query = convertQueryParams();
      }
      if (query && !isNil(query.token) && query.token !== '') {
        recommendationUrl += `?token=${query.token}`;
      } else {
        recommendationUrl += `?form_id=${responseId}`;
      }
    }
    const recommendation = await get(recommendationUrl);
    if (response.ok && recommendation) {
      recommendation.products = recommendation.products.map((item: RecommendationProduct) => {
        const product: Product = toRecommendationProducts(item);
        return product;
      });
      recommendation.removeProducts = recommendation.removeProducts.map((item: RecommendationProduct) => {
        const product: Product = toRecommendationProducts(item);
        return product;
      });
      setRecommendedProducts(recommendation.products);
      setRemoveProducts(recommendation.removeProducts);
      if (recommendation.email && !quizEmail) setQuizEmail(recommendation.email);
      if (!isEmpty(recommendation.products)) {
        if (checkoutFlowRevamp && checkoutFlowRevamp.variation2) {
          if (!token && isEmpty(cart)) {
            const items: CartItem[] = recommendation.products.map((item: Product) => ({
              productId: item.id,
              quantity: item.quantity,
              fromRecommendation: true,
            }));
            setCart(items);
            setBilling({ ...billing, ...{ nameOnPack: firstName, email: quizEmail || '' } });
          }
        } else if (!token && isEmpty(cart)) {
          const items: CartItem[] = recommendation.products.map((item: Product) => ({
            productId: item.id,
            quantity: item.quantity,
          }));
          setCart(items);
          setBilling({ ...billing, ...{ nameOnPack: firstName, email: quizEmail || '' } });
        }
      }
    }
    return recommendation;
  }

  async function getRecommendationOneOff() {
    let recommendationOneOffUrl = appRoutes.API_RECOMMENDATIONS_ONE_OFF;
    if (!token) {
      const params = recommendationRouter.asPath.split('?');
      let query;
      if (params.length >= 2) {
        query = convertQueryParams();
      }
      if (query && !isNil(query.token) && query.token !== '') {
        recommendationOneOffUrl += `?token=${query.token}`;
      } else {
        recommendationOneOffUrl += `?form_id=${responseId}`;
      }
    }
    const recommendationOneOff = await get(recommendationOneOffUrl);
    if (response.ok && recommendationOneOff && recommendationOneOff.data && recommendationOneOff.data.products) {
      const rawProducts = recommendationOneOff.data.products;
      const oneOffProducts = rawProducts.map((m: ProductResponseItem) => toProduct(m));
      setRecommendedOneOffProducts(oneOffProducts);
    }
  }

  const recommendedCategories = (product: Product, healthGoals: string[]): Category[] => {
    let items: Category[] = [];
    if (product && !isEmpty(product.categories)) {
      product.categories.forEach((category) => {
        if (healthGoals.includes(category.slug)) {
          items.push(category);
        }
      });
      if (isEmpty(items)) {
        items = product.categories;
      }
    }
    return items;
  };

  const recommendedNote = (product: Product): RecommendationQuestion => {
    let note;
    if (product && product.recommendation) {
      const topRecommend = maxBy(product.recommendation.recommendations, 'score');
      const questions = (topRecommend as Recommendation)?.questions.filter((q) => q.correct);
      if (!isEmpty(questions)) {
        note = { ...questions[0] };
      } else {
        const { title, description } = product.recommendation;
        note = { title, description };
      }
    }
    return note as RecommendationQuestion;
  };

  const onClickReview = () => {
    sendEvent(eventTypes.CART_PAGE_VIEW);
    sendGa4Event('continue', 'click');

    if (
      enableAddOnsFlag &&
      recommendedOneOffProducts.length > 0 &&
      recommendationRouter.pathname !== appRoutes.RECOMMENDATION_ADD_ONS
    ) {
      recommendationRouter.push(appRoutes.RECOMMENDATION_ADD_ONS);
      return;
    }
    if (checkoutFlowRevamp.variation2 || checkoutFlowRevamp.variation1) {
      recommendationRouter.push({
        pathname: !token ? appRoutes.CHECKOUT_REVIEW : appRoutes.MY_ACCOUNT_NEXT_DELIVERY,
        query: { backToRecommendation: true },
      });
    } else {
      recommendationRouter.push(!token ? appRoutes.CHECKOUT_REVIEW : appRoutes.MY_ACCOUNT_NEXT_DELIVERY);
    }
  };

  return {
    responseId,
    setResponseId,
    removeProducts,
    setRemoveProducts,
    recommendedProducts,
    recommendedOneOffProducts,
    setRecommendedProducts,
    firstName,
    setFirstName,
    getRecommendation,
    getRecommendationOneOff,
    recommendedCategories,
    recommendedNote,
    onClickReview,
    enableAddOnsFlag,
    setEnableAddOnsFlag,
  };
};

export const [RecommendationProvider, useRecommendation] = unreduxed(container);
