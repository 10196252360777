import ceil from 'lodash/ceil';
import cloneDeep from 'lodash/cloneDeep';
import floor from 'lodash/floor';
import isUndefined from 'lodash/isUndefined';
import round from 'lodash/round';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import pluralize from 'pluralize';

import { Country } from '../types/Country';
import { CartItem } from '../types/CartItem';
import { PromoCode } from '../types/PromoCode';
import { DiscountEachMonth, ItemsDiscount } from '../types/Order';
import { discountType, promoCodeTypes } from './const';
import { Product, checkoutGroupValue } from '../types/Product';

type Discount = {
  discountDollar: number;
  discountPercent: number;
  firstMonthDiscountDollar?: number;
  secondMonthDiscountDollar?: number;
  firstMonthDiscountPercent?: number;
  secondMonthDiscountPercent?: number;
  discountEachMonths?: DiscountEachMonth[];
};

type Total = {
  totalBeforeDiscount: number;
  totalAfterDiscount: number;
};

type PromotionalInformation = {
  discountText: string;
  itemsAreDiscounted: string;
  promotionalText: string;
  firstMonthDiscountText?: string;
  secondMonthDiscountText?: string;
  freeProducts?: string;
  freeGift?: string;
  numberOfMonthsApplied?: string;
  discountAvailableFor?: string;
  discountDollar: number;
  discountPercent: number;
  discountEachMonths?: DiscountEachMonth[];
};

type OrderInfo = {
  total: Total;
  discount: Discount;
  promotionalInformation: PromotionalInformation;
  shipping: number;
  itemsDiscount: [] | ItemsDiscount[];
};

export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const getSubtotalForOneOffItems = (items: CartItem[]): number => {
  const oneOffProducts = items.filter((item) =>
    item.product?.availableForOneOff
  );

  let subtotal = 0;

  if (oneOffProducts.length) {
    subtotal = sumBy(oneOffProducts, (item) => 
      item.quantity * Number(item.product?.price)
    );
  }

  return subtotal;
}

export const hasInternallyProcessedProductsInCart = (items: CartItem[]): boolean => {
  const productsProcessedInternally = items.filter((item) =>
    item.product?.isProcessedInternally
  );

  return productsProcessedInternally.length > 0;
}


export const getSubtotalForProductsProcessedInternally = (items: CartItem[]): number => {
  const productsProcessedInternally = items.filter((item) =>
    item.product?.isProcessedInternally
  );

  let subtotal = 0;

  if (productsProcessedInternally.length) {
    subtotal = sumBy(productsProcessedInternally, (item) =>
      item.quantity * Number(item.product?.price)
    );
  }

  return subtotal;
}

export const getSubTotal = (items: CartItem[], packageQuantity = 1): number => {
  const subscriptionItems = items.filter((item) =>
    item.product?.availableForSubscription
  );

  let subtotal = 0;

  if (subscriptionItems.length) {
    subtotal = sum(
      subscriptionItems.map((item) => item.quantity * Number(item.product?.price))
    );
  }

  return subtotal * packageQuantity;
};

export const getSubTotalHaveExtraDiscount = (items?: CartItem[], packageQuantity = 1, extraDiscount?: number): number => {
  if (!items) return 0;
  let subtotal = getSubTotal(items, packageQuantity);

  if (extraDiscount) {
    subtotal *= (100 - extraDiscount) / 100;
  }
  
  return subtotal;
};
export const getTotalForDiscountPercent = (totalBeforeDiscount: number, discountPercent: number): number => {
  const total = totalBeforeDiscount * ((100 - discountPercent) / 100);
  return total;
};

export const getShippingFee = (items: CartItem[], country: Country): number => {
  if (!hasInternallyProcessedProductsInCart(items)) {
    return 0;
  }

  let shippingFee = country.shippingCost;

  const subtotal = getSubtotalForProductsProcessedInternally(items);

  if (subtotal >= country.freeShipping) {
    shippingFee = 0;
  }

  return shippingFee;
};

export const getTotal = (items: CartItem[], extraDiscount: number, country: Country, packageQuantity = 1): number => {
  const subtotalForSubscriptionItems = getSubTotal(items, packageQuantity);
  const subtotalForOneOffItems = getSubtotalForOneOffItems(items);
  const shipping = getShippingFee(items, country);
  let total = subtotalForSubscriptionItems + shipping;

  if (extraDiscount > 0 && packageQuantity > 1) {
    total = getTotalForDiscountPercent(subtotalForSubscriptionItems, extraDiscount) + shipping;
  }

  total += subtotalForOneOffItems;

  // total rounded to 2 decimal places
  total = Math.round((total + Number.EPSILON) * 100) / 100;

  return total;
};

export const categorizeProducts = (items: CartItem[], promoCode: PromoCode): { itemsAreDiscounted: CartItem[]; itemsAreNotDiscounted: CartItem[] } => {
  const itemsAreDiscounted: CartItem[] = [];
  const itemsAreNotDiscounted: CartItem[] = [];
  const productLimit = promoCode.productLimit && promoCode.productLimit !== '' ? promoCode.productLimit.replace(/\s/g, '').split(',') : [];
  items.forEach((item: CartItem) => {
    if (item.product?.noPercentDiscount || item.product?.noDollarDiscount) {
      itemsAreNotDiscounted.push(item);
    } else if (productLimit.length > 0) {
      if (productLimit.includes(item.product?.sku || '')) {
        itemsAreDiscounted.push(item);
      } else {
        itemsAreNotDiscounted.push(item);
      }
    } else {
      itemsAreDiscounted.push(item);
    }
  });
  // productLimit.includes(item.product?.sku || '')
  return { itemsAreDiscounted, itemsAreNotDiscounted };
};

export const getDiscountPercent = (total: number, discountValue: string | number): number => {
  const percent = ceil((Number(discountValue) / total) * 100);
  return percent > 100 ? 100 : percent;
};

export const getDiscountDollar = (total: number, discountValue: string | number): number => {
  const amount = (Number(discountValue) / 100) * total;
  return amount;
};

export const getDiscount = (total: number, kindOfDiscount: string, discountValue: string | number): Discount => {
  let discountDollar;
  let discountPercent;
  if (kindOfDiscount === discountType.PERCENT) {
    discountPercent = Number(discountValue);
    discountDollar = getDiscountDollar(total, discountValue);
  } else {
    discountDollar = Number(discountValue) > total ? total : Number(discountValue);
    discountPercent = getDiscountPercent(total, discountValue);
  }

  return { discountDollar: round(discountDollar, 2), discountPercent };
};

export const getTotalForDiscountAmount = (totalBeforeDiscount: number, discountAmount: number): number => {
  const total = totalBeforeDiscount - discountAmount;
  return total;
};

export const changePromoCode = (promoCode: PromoCode, factor = 2): PromoCode => {
  const clonePromoCode = cloneDeep(promoCode);
  if (factor === 1) {
    return clonePromoCode;
  }
  // let change = false;
  if (clonePromoCode.type === promoCodeTypes.REPEATING) {
    if (clonePromoCode.durationInMonths > 1 && !clonePromoCode.giftCard && clonePromoCode.discountAmount && clonePromoCode.discountAmount !== '') {
      clonePromoCode.discountAmountForManySubs = Number(clonePromoCode.discountAmount) * factor;
    }
    if (clonePromoCode.durationInMonths === 1) {
      clonePromoCode.onlyApplyForOnePack = true;
    }
  }
  if (clonePromoCode.type === 'once') {
    clonePromoCode.onlyApplyForOnePack = true;
  }
  return clonePromoCode;
};

export const getTotalAfterDiscount = (totalBeforeDiscount: number, kindOfDiscount: string, discountValue: string | number): number => {
  let total;
  if (kindOfDiscount === discountType.PERCENT) {
    total = getTotalForDiscountPercent(totalBeforeDiscount, Number(discountValue));
  } else {
    total = getTotalForDiscountAmount(totalBeforeDiscount, Number(discountValue));
  }
  return total > 0 ? total : 0;
};

export const getPromotionalInformation = (promoCode: PromoCode, discount: Discount, totalBeforeDiscount: number, factor = 1): PromotionalInformation => {
  let discountEachMonths: DiscountEachMonth[] = [];
  const itemsAreDiscounted = '';
  const productLimit = (promoCode.productLimit && promoCode.productLimit !== '') || false;
  let promotionalText = '';
  let discountText = '';
  let firstMonthDiscountText = '';
  let secondMonthDiscountText = '';
  let freeProducts = '';
  let freeGift = '';
  let numberOfMonthsApplied = '';
  let discountAvailableFor = '';
  let remainingAvailableMonths = 0;
  const monthsUsed = factor;
  const QUANTITY_TEXT = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];
  if (promoCode.freeGift && promoCode.freeGift !== '') {
    freeGift = promoCode.freeGift;
  }
  if (promoCode.freeProducts && promoCode.freeProducts.length) {
    const freeProductList = cloneDeep(promoCode.freeProducts);
    freeProducts = `Free ${freeProductList
      .map((item) => item.name)
      .toString()
      .replace(',', ', ')} `;
  }
  if (promoCode.resultMessage && promoCode.resultMessage !== '') {
    if (freeProducts !== '') freeProducts = promoCode.resultMessage;
    if (freeGift !== '') freeGift = promoCode.resultMessage;
  }
  if (promoCode.percent && promoCode.percent !== '') {
    discountText = `${promoCode.percent}%`;
  } else if (promoCode.discountAmount && promoCode.discountAmount !== '') {
    if (promoCode.type === promoCodeTypes.REPEATING) {
      if (Number(promoCode.discountAmount) > totalBeforeDiscount) {
        discountText = `$${totalBeforeDiscount}`;
      } else {
        discountText = `$${discount.discountDollar}`;
      }
    } else {
      discountText = `$${discount.discountDollar}`;
    }
  }
  promotionalText = `You've saved ${discountText} today!`;
  firstMonthDiscountText = `You’ve saved ${discountText} on one Monthly pack!`;
  if (productLimit) {
    promotionalText = `${discountText} off eligible products.`;
    firstMonthDiscountText = `${discountText} off eligible products.`;
    numberOfMonthsApplied = 'Applied to one Monthly pack.';
    if (promoCode.type === promoCodeTypes.REPEATING) {
      if (promoCode.durationInMonths > 1) {
        numberOfMonthsApplied = 'Applied to two Monthly packs.';
        remainingAvailableMonths = promoCode.durationInMonths - monthsUsed;
      }
    }
    if (promoCode.type === promoCodeTypes.FOREVER) numberOfMonthsApplied = 'Applied to two Monthly packs.';
  }
  if ((promoCode.type === promoCodeTypes.REPEATING || promoCode.type === promoCodeTypes.FOREVER) && !productLimit) {
    firstMonthDiscountText = `You’ve saved ${discountText} on ${QUANTITY_TEXT[factor - 1]} Monthly packs!`;
    if (promoCode.type === promoCodeTypes.REPEATING) {
      remainingAvailableMonths = promoCode.durationInMonths - monthsUsed;
    }
  }
  if (promoCode.message && promoCode.message !== '') {
    promotionalText = promoCode.message;
  }
  if (promoCode.type === promoCodeTypes.DEGRESSIVE && promoCode.degressiveDiscount?.length) {
    const firstMonthDiscount = promoCode.degressiveDiscount[0].type;
    const secondMonthDiscount = promoCode.degressiveDiscount[1] ? promoCode.degressiveDiscount[1].type : '';
    const discountTextFirstMonth = firstMonthDiscount === discountType.DOLLAR ? `$${discount.firstMonthDiscountDollar}` : `${discount.firstMonthDiscountPercent}%`;
    const discountTextSecondMonth = secondMonthDiscount === discountType.DOLLAR ? `$${discount.secondMonthDiscountDollar}` : `${discount.secondMonthDiscountPercent}%`;
    promotionalText = `You've saved ${discountTextFirstMonth} today`;
    firstMonthDiscountText = `Saved ${discountTextFirstMonth} on 1st Month pack`;
    secondMonthDiscountText = `Saved ${discountTextSecondMonth} on 2nd Month pack`;
    remainingAvailableMonths = promoCode.degressiveDiscount.length - monthsUsed;
    if (discount.discountEachMonths) {
      discountEachMonths = discount.discountEachMonths.map((m: DiscountEachMonth, index: number) => {
        if (promoCode.degressiveDiscount) {
          let text = promoCode.degressiveDiscount[index]?.type === discountType.DOLLAR ? `$${m.dollar}` : `${m.percent}%`;
          text = `Saved ${text} on ${index + 1}st Month pack`;
          return { ...m, text };
        }
        return m;
      });
    }
  }
  if (remainingAvailableMonths > 1) {
    discountAvailableFor = `*Discount available for ${remainingAvailableMonths} more Monthly packs after purchase. Automatically applied to the next order.`;
  } else if (remainingAvailableMonths === 1) {
    discountAvailableFor = `*Discount available for ${remainingAvailableMonths} more Monthly pack after purchase. Automatically applied to the next order.`;
  }
  if (remainingAvailableMonths >= 1) {
    promotionalText += '*';
    if (promoCode.type === promoCodeTypes.REPEATING) firstMonthDiscountText += '*';
  }
  const { discountDollar, discountPercent } = discount;
  return {
    discountText,
    itemsAreDiscounted,
    promotionalText,
    firstMonthDiscountText,
    secondMonthDiscountText,
    freeProducts,
    freeGift,
    numberOfMonthsApplied,
    discountAvailableFor,
    discountDollar,
    discountPercent,
    discountEachMonths,
  };
};

export const getItemsDiscount = (items: CartItem[], type: string, value: number, factor = 1): ItemsDiscount[] => {
  const data = items.map((item) => {
    const subtotal = getSubTotal([item], factor);
    const discount = getDiscount(subtotal, type, value);
    const productAmount = (item.product?.price || 1) * item.quantity;
    if (discount.discountDollar > productAmount) {
      discount.discountDollar /= factor;
    }
    return { sku: item.product?.sku, discountAmount: `$${formatter.format(discount.discountDollar)}` };
  });
  return data as ItemsDiscount[];
};

export const calcNormalDiscount = (items: CartItem[], promoCode: PromoCode, extraDiscountParam = 0, country: Country, factor = 1): OrderInfo => {
  const extraDiscount = factor > 1 ? extraDiscountParam : 0;
  const shipping = getShippingFee(items, country);
  const subtotal = getSubTotal(items, factor);
  let discount = { discountDollar: 0, discountPercent: 0 };
  let promotionalInformation = {
    discountText: '',
    itemsAreDiscounted: '',
    promotionalText: '',
    discountDollar: 0,
    discountPercent: 0,
  };
  const totalBeforeDiscount = getTotalForDiscountPercent(subtotal, extraDiscount) + shipping;
  let totalAfterDiscount = totalBeforeDiscount;
  let findProductsWithoutDiscount;
  let itemsDiscount: [] | ItemsDiscount[] = [];
  if (promoCode.discountAmount && promoCode.discountAmount !== '') {
    findProductsWithoutDiscount = items.find((item) => item.product?.noDollarDiscount);
  }
  if (promoCode.percent && promoCode.percent !== '') {
    findProductsWithoutDiscount = items.find((item) => item.product?.noPercentDiscount);
  }
  // console.log('findProductsWithoutDiscount', findProductsWithoutDiscount);
  const specifiedDiscount = !isUndefined(findProductsWithoutDiscount) || (promoCode.productLimit && promoCode.productLimit !== '');
  // console.log('specifiedDiscount', specifiedDiscount);
  const clonePromoCode = changePromoCode(promoCode, factor);
  const type = clonePromoCode.percent && clonePromoCode.percent !== '' ? discountType.PERCENT : discountType.DOLLAR;
  let value = type === discountType.PERCENT ? Number(clonePromoCode.percent) : Number(clonePromoCode.discountAmount);
  if (specifiedDiscount) {
    const cartItem = categorizeProducts(items, promoCode);
    const totalAmountOfProductsDiscounted = getTotalForDiscountPercent(getSubTotal(cartItem.itemsAreDiscounted, factor), extraDiscount);
    const totalAmountOfProductsNoDiscounted = getTotalForDiscountPercent(getSubTotal(cartItem.itemsAreNotDiscounted, factor), extraDiscount);
    if (clonePromoCode.discountAmountForManySubs) value = clonePromoCode.discountAmountForManySubs;
    if (clonePromoCode.onlyApplyForOnePack && type === discountType.PERCENT) value /= factor;
    if (clonePromoCode.onlyApplyForOnePack && type === discountType.DOLLAR && value > totalAmountOfProductsDiscounted / 2) {
      value = totalAmountOfProductsDiscounted / factor;
    }
    itemsDiscount = getItemsDiscount(cartItem.itemsAreDiscounted, type, value, factor);
    let totalDiscounted = 0;
    if (findProductsWithoutDiscount) {
      discount = getDiscount(totalAmountOfProductsDiscounted + shipping, type, value);
      discount = { ...discount, ...{ firstMonthDiscountDollar: discount.discountDollar, firstMonthDiscountPercent: discount.discountPercent } };
      totalDiscounted = getTotalAfterDiscount(totalAmountOfProductsDiscounted + shipping, type, value);
      totalAfterDiscount = totalDiscounted + totalAmountOfProductsNoDiscounted;
      promotionalInformation = getPromotionalInformation(promoCode, discount, totalAmountOfProductsDiscounted + shipping, factor);
    }
    if (promoCode.productLimit && promoCode.productLimit !== '') {
      discount = getDiscount(totalAmountOfProductsDiscounted, type, value);
      discount = { ...discount, ...{ firstMonthDiscountDollar: discount.discountDollar, firstMonthDiscountPercent: discount.discountPercent } };
      totalDiscounted = getTotalAfterDiscount(totalAmountOfProductsDiscounted, type, value);
      totalAfterDiscount = totalDiscounted + totalAmountOfProductsNoDiscounted + shipping;
      promotionalInformation = getPromotionalInformation(promoCode, discount, totalAmountOfProductsDiscounted, factor);
    }
  } else {
    if (clonePromoCode.discountAmountForManySubs) value = clonePromoCode.discountAmountForManySubs;
    if (factor > 1 && clonePromoCode.onlyApplyForOnePack) {
      const singleSubtotal = getTotalForDiscountPercent(getSubTotal(items, 1), extraDiscount);
      discount = getDiscount(singleSubtotal + shipping, type, value);
      // discount.discountPercent /= 2;
    } else {
      discount = getDiscount(totalBeforeDiscount, type, value);
    }
    discount = { ...discount, ...{ firstMonthDiscountDollar: discount.discountDollar, firstMonthDiscountPercent: discount.discountPercent } };
    totalAfterDiscount = totalBeforeDiscount - discount.discountDollar;
    promotionalInformation = getPromotionalInformation(clonePromoCode, discount, totalBeforeDiscount, factor);
  }

  const subtotalForOneOffItems = getSubtotalForOneOffItems(items);

  const total = {
    totalBeforeDiscount: totalBeforeDiscount + subtotalForOneOffItems,
    totalAfterDiscount: floor(totalAfterDiscount + subtotalForOneOffItems, 2) 
  };

  return {
    total,
    discount,
    promotionalInformation,
    shipping,
    itemsDiscount,
  };
};

export const calcDegressiveDiscount = (items: CartItem[], promoCode: PromoCode, extraDiscountParam = 0, country: Country, factor = 1): OrderInfo => {
  const extraDiscount = factor > 1 ? extraDiscountParam : 0;
  const shipping = getShippingFee(items, country);
  const subtotal = getSubTotal(items, factor);
  const itemsDiscount: [] | ItemsDiscount[] = [];
  let aMonthSubtotal = getSubTotal(items, 1);
  const totalBeforeDiscount = getTotalForDiscountPercent(subtotal, extraDiscount) + shipping;

  let totalNoShip = 0;

  const findProductsWithoutDiscount = items.find((item) => item.product?.noPercentDiscount || item.product?.noDollarDiscount);
  const specifiedDiscount = !isUndefined(findProductsWithoutDiscount) || (promoCode.productLimit && promoCode.productLimit !== '');
  // let clonePromoCode = _.cloneDeep(promoCode);
  const cartItem = categorizeProducts(items, promoCode);
  let totalAmountOfProductsNoDiscounted = 0;
  if (specifiedDiscount) {
    aMonthSubtotal = getSubTotal(cartItem.itemsAreDiscounted, 1);
    totalAmountOfProductsNoDiscounted = getSubTotal(cartItem.itemsAreNotDiscounted, 1);
  }
  if (extraDiscount > 0) {
    aMonthSubtotal = getTotalForDiscountPercent(aMonthSubtotal, extraDiscount);
    totalAmountOfProductsNoDiscounted = getTotalForDiscountPercent(totalAmountOfProductsNoDiscounted, extraDiscount);
  }
  const { degressiveDiscount } = promoCode;
  const discountEachMonths: DiscountEachMonth[] = [];
  for (let i = 0; i < factor; i++) {
    if (degressiveDiscount && degressiveDiscount[i]) {
      const degressiveDiscountType = degressiveDiscount[i].type;
      const discountValue = degressiveDiscount[i].value;
      totalNoShip += getTotalAfterDiscount(aMonthSubtotal, degressiveDiscountType, discountValue) + totalAmountOfProductsNoDiscounted;
      const discount = getDiscount(aMonthSubtotal, degressiveDiscountType, discountValue);
      discountEachMonths.push({
        dollar: discount.discountDollar,
        dollarString: `-$${formatter.format(discount.discountDollar || 0)}`,
        percent: discount.discountPercent,
        text: '',
      });
    } else {
      totalNoShip += aMonthSubtotal + totalAmountOfProductsNoDiscounted;
    }
  }
  const totalAfterDiscount = Number(formatter.format(totalNoShip + shipping));

  const getDiscountAmount = getDiscount(totalBeforeDiscount, discountType.DOLLAR, totalBeforeDiscount - totalAfterDiscount);
  const discount = {
    ...{ discountDollar: getDiscountAmount.discountDollar, discountPercent: getDiscountAmount.discountPercent },
    ...{ firstMonthDiscountDollar: discountEachMonths[0]?.dollar, firstMonthDiscountPercent: discountEachMonths[0]?.percent },
    ...{ secondMonthDiscountDollar: discountEachMonths[1]?.dollar, secondMonthDiscountPercent: discountEachMonths[1]?.percent },
    discountEachMonths,
  };

  const promotionalInformation = getPromotionalInformation(promoCode, discount, totalBeforeDiscount, factor);

  const subtotalForOneOffItems = getSubtotalForOneOffItems(items);

  const total = {
    totalBeforeDiscount: totalBeforeDiscount + subtotalForOneOffItems,
    totalAfterDiscount: floor(totalAfterDiscount + subtotalForOneOffItems, 2)
  };

  return {
    total,
    discount,
    promotionalInformation,
    shipping,
    itemsDiscount,
  };
};

export const getOrderDataOnApplyingPromoCode = (items: CartItem[], promoCode: PromoCode, extraDiscount: number, country: Country, factor = 1): OrderInfo => {
  let data;
  if (promoCode.type === promoCodeTypes.DEGRESSIVE) {
    data = calcDegressiveDiscount(items, promoCode, extraDiscount, country, factor);
  } else {
    data = calcNormalDiscount(items, promoCode, extraDiscount, country, factor);
  }
  return data;
};

export const getCredit = (
  items: CartItem[],
  promoCode: PromoCode | undefined,
  extraDiscount: number,
  country: Country,
  credit: number,
  minimumOrderPrice: number,
  factor = 1,
): number => {
  let amountReduced;
  let total;
  if (promoCode) {
    const getData = getOrderDataOnApplyingPromoCode(items, promoCode, extraDiscount, country, factor);
    total = getData.total.totalAfterDiscount;
  } else {
    total = getTotal(items, extraDiscount, country, factor);
  }
  const surplus = total - credit;
  if (surplus < minimumOrderPrice) {
    amountReduced = total - minimumOrderPrice > 0 ? total - minimumOrderPrice : minimumOrderPrice;
    // total = minimumOrderPrice;
  } else {
    amountReduced = credit;
    // total = surplus;
  }
  return amountReduced;
};

export const getTotalAfterCredit = (
  items: CartItem[],
  promoCode: PromoCode | undefined,
  extraDiscount: number,
  country: Country,
  credit: number,
  minimumOrderPrice: number,
  factor = 1,
): number => {
  let total;
  if (promoCode) {
    const getData = getOrderDataOnApplyingPromoCode(items, promoCode, extraDiscount, country, factor);
    total = getData.total.totalAfterDiscount;
  } else {
    total = getTotal(items, extraDiscount, country, factor);
  }
  if (credit && credit > 0) {
    const surplus = total - credit;
    if (surplus < minimumOrderPrice) {
      total = minimumOrderPrice;
    } else {
      total = surplus;
    }
    return total;
  }
  return total;
};

export const isValidPromoCode = (items: CartItem[], promoCode: PromoCode, country: Country, factor = 1): { valid: boolean; errorMessage: string } => {
  let valid = true;
  let errorMessage = promoCode.errorMessage || '';
  if (promoCode.maxBasketCap) {
    const maxBasketCap = promoCode.maxBasketCap * factor;
    const total = getTotal(items, 0, country, factor);
    if (total > maxBasketCap) {
      valid = false;
      if (factor > 1) {
        errorMessage = promoCode.errorMessageForDouble || '';
      } else {
        errorMessage = promoCode.errorMessage || '';
      }
    }
  }
  if (promoCode.productLimit !== null && promoCode.productLimit !== undefined && promoCode.productLimit !== '') {
    const sku = promoCode.productLimit.replace(/\s/g, '').split(',');
    // eslint-disable-next-line no-restricted-syntax
    for (const product of sku) {
      const findProduct = items.find((item) => item.product?.sku === product);
      if (findProduct) {
        valid = true;
        break;
      }
      valid = false;
    }
    if (!valid) errorMessage = 'Please add eligible product(s) to Cart to use this code.';
  }
  return { valid, errorMessage };
};

export const getBulkUnit = (product: Product): string => {
  const text = product.pdpPriceText?.split('for a');
  let unit = '';
  if (text?.length === 2) {
    /* eslint-disable prefer-destructuring */
    unit = text[1];
  }
  return unit;
};

export const checkParentheses = (item: string, mutipleValue?: boolean): string => {
  let newText = item;
  if (newText.includes('- Rich in Omega')) {
    newText = newText.substring(0, newText.indexOf('- Rich in Omega') - 1);
  }
  const regExp = /\(([^)]+)\)/;
  let replace = false;
  for (let i = 0; i < newText.length; i++) {
    if (newText.indexOf('(') < 0 && newText.indexOf(')') < 0) return newText;
    if (newText[i] === '(') {
      const matches = regExp.exec(newText);
      if (matches != null) {
        replace = true;
        newText = newText.replace(` ${matches[0]}`, '');
      }
    }
  }
  if (mutipleValue) {
    return replace ? `${newText}...` : `${newText},...`;
  }
  return replace ? `${newText}...` : newText;
};

export const checkDailyDose = (product: Product): string => {
  if (!product.quantity || !product.dosage || !product.dosageDescription) return '';
  const desc = product.dosageDescription.split(',');
  if (desc.length === 1) {
    return `${product.quantity * product.dosage}${checkParentheses(product.dosageDescription)}`;
  }
  return `${product.quantity * product.dosage} ${checkParentheses(desc[0], true)}`;
};

export const suggestedText = (product: Product): string => {
  let text = '';
  if (!product?.bulk) text = 'Suggested dose';
  if (product?.suggestedDoseText) text = `${product?.suggestedDoseText}`;
  return text;
};

export const getOptions = (product: Product | (Product & { options: { label: string; value: string }[] })): { label: string; value: string }[] => {
  if ('options' in product && product.options.length > 0) {
    return product.options;
  }
  const listItem = [];
  for (let i = 0; i < product.maxQuantity; i++) {
    listItem.push({
      value: (i + 1).toString(),
      label: `${i + 1} ${pluralize(product.unit, i + 1)}`,
    });
  }
  return listItem;
};

export const hasAddOnsProducts = (items: CartItem[]): boolean => {
  const addOnsItems = items.filter((f) => f.product?.checkoutGroup === checkoutGroupValue.ADD_ONS);
  return addOnsItems.length > 0;
};

export const getTotalAddOnsProducts = (items: CartItem[]): number => {
  const addOnsItems = items.filter((f) => f.product?.checkoutGroup === checkoutGroupValue.ADD_ONS);
  const total = sum(addOnsItems.map((item) => item.quantity * Number(item.product?.price)));
  return total;
};
