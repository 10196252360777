// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://9cf6eec66e97410db827d9ed61d275c2@o516210.ingest.sentry.io/5800596',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate:0.01,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  allowUrls: [
    /https?:\/\/((staging|dev|www|v2-staging|v2-dev)\.)?vitable\.com.au/
  ],
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
    'TypeError: cancelled',
    'performance-relayer',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id' ,
    'Non-Error promise rejection captured with value: routeChange aborted.'
  ]
});
