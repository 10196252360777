import { useRouter } from 'next/router';
import unreduxed from 'unreduxed';
import React, { useEffect } from 'react';
import { useCart } from './CartContainer';
import { useAuth } from './AuthContainer';
import { usePromoCode } from './PromoCodeContainer';
import { useCountry } from './CountryContainer';
import { insiderSetBasket, inSiderSetUser } from '../common/insider';
import { getOrderDataOnApplyingPromoCode, getShippingFee, getTotal } from '../common/util';
import { Country } from '../types/Country';
import { CartItem } from '../types/CartItem';
import { SubscriptionItem } from '../types/SubscriptionItem';

const container = () => {
  const router = useRouter();
  const country = useCountry((con) => con.country);
  const cart = useCart((con) => con.cart);
  const user = useAuth((con) => con.user);
  const customer = useAuth((con) => con.customer);
  const subscriptionItems = useAuth((con) => con.subscriptionItems);
  const localPromoCode = usePromoCode((con) => con.localPromoCode);
  const handleRouteChange = React.useCallback(() => {
    inSiderSetUser(user?.id || '', user?.email || '', customer?.firstName || '', customer?.lastName || '');
  }, []);

  useEffect(() => {
    inSiderSetUser(user?.id || '', user?.email || '', customer?.firstName || '', customer?.lastName || '');
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [user, customer]);

  useEffect(() => {
    if (subscriptionItems.length && user) {
      let cartItems: SubscriptionItem[] | CartItem[] = subscriptionItems;
      if (!router.asPath.includes('/myaccount/next-delivery')) {
        cartItems = cart;
      }
      let basketObject = {
        total: getTotal(cartItems, 0, (country as Country), customer?.doubleSubscription),
        promoCode: '',
        discountAmount: 0,
        discountPercent: 0,
        shippingFee: getShippingFee(cartItems, (country as Country)),
        cart: cartItems,
      };
      if (localPromoCode.item) {
        const { discount, total, shipping } = getOrderDataOnApplyingPromoCode(cartItems, localPromoCode.item, 0, (country as Country), customer?.doubleSubscription);
        basketObject = {
          total: total.totalAfterDiscount,
          promoCode: localPromoCode.item.code,
          discountAmount: discount.discountDollar,
          discountPercent: discount.discountPercent,
          shippingFee: shipping,
          cart: cartItems,
        };
      }
      insiderSetBasket(basketObject);
    }
  }, [subscriptionItems, customer?.doubleSubscription, localPromoCode.applied, cart.length]);
};

export const [InsiderProvider, useInsider] = unreduxed(container);
