import { useAsyncEffect } from 'use-async-effect';
import { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import unreduxed from 'unreduxed';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useAuth } from './AuthContainer';
import { useLaunchDarkly } from './LaunchDarklyContainer';
import { insiderTrackEvent } from '../common/insider';
import { useCart } from './CartContainer';
import { useCountry } from './CountryContainer';

let defaultCardPlaceHolder = '1234 5678 1234 5678';
const container = () => {
  const [stripe, setStripe] = useState<Stripe>();
  const [card, setCard] = useState<StripeCardNumberElement>();
  const [enableAfterPay, setEnableAfterPay] = useState(false);
  const [showAfterPayModal, setShowAfterPayModal] = useState(false);
  const customer = useAuth((con) => con.customer);
  const getVariation = useLaunchDarkly((con) => con.getVariation);
  const checkoutFlowRevamp = useCart((con) => con.checkoutFlowRevamp);
  const newSubscriptionPlanVersion = useCart((con) => con.newSubscriptionPlanVersion);
  const detectedCountry = useCountry((con) => con.detectedCountry);

  useEffect(() => {
    if (!stripe) return;
    const elements = stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
        },
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Inter',
        },
      ],
    });
    const style = {
      base: {
        color: `${checkoutFlowRevamp.variation2 ? '#898989' : '#32325D'}`,
        fontWeight: checkoutFlowRevamp.variation2 ? 400 : 500,
        fontFamily: `${checkoutFlowRevamp.variation2 ? 'Inter' : 'Source Code Pro, Consolas, Menlo, monospace'}`,
        fontSize: `${window.innerWidth < 431 ? '14px' : '16px'}`,
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: `${checkoutFlowRevamp.variation2 ? '#898989' : '#cbc1a9'}`,
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',

        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };

    const elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    const inputs = document.querySelectorAll('.form-group .input');
    Array.prototype.forEach.call(inputs, (input) => {
      input.addEventListener('focus', () => {
        input.classList.add('focused');
      });
      input.addEventListener('blur', () => {
        input.classList.remove('focused');
      });
      input.addEventListener('keyup', () => {
        if (!isNil(input.value)) {
          if (input.value.length === 0) {
            input.classList.add('empty');
          } else {
            input.classList.remove('empty');
          }
        }
      });
    });
    let defaultDatePlaceHolder = 'MM / YY';
    if (checkoutFlowRevamp.variation2) {
      defaultCardPlaceHolder = 'Card number';
      defaultDatePlaceHolder = 'Expiry date';
      if (!newSubscriptionPlanVersion.control) {
        defaultDatePlaceHolder = 'Expiry';
      }
    }
    if (customer && customer.cardLastFour) {
      defaultCardPlaceHolder = ''.concat('XXXX XXXX XXXX ', customer.cardLastFour);
    }
    if (document.getElementById('card-number-element') !== null) {
      const cardNumberElement = elements.create('cardNumber', {
        style,
        placeholder: defaultCardPlaceHolder,
        classes: elementClasses,
      });
      cardNumberElement.mount('#card-number-element');

      const cardExpiryElement = elements.create('cardExpiry', {
        style,
        placeholder: defaultDatePlaceHolder,
        classes: elementClasses,
      });
      cardExpiryElement.mount('#card-expiry-element');

      const cardCvcElement = elements.create('cardCvc', {
        style,
        placeholder: 'CVC',
        classes: elementClasses,
      });
      cardCvcElement.mount('#card-cvc-element');
      setCard(cardNumberElement);
    }
  }, [stripe, customer]);

  useAsyncEffect(async () => {
    if (detectedCountry) {
      if (detectedCountry.country === 'AU') {
        const variation = await getVariation('enable-afterpay', true);
        // segment track
        insiderTrackEvent('launchdarkly2', {
          feature_flag: 'enable-afterpay',
          variation_value: variation ? 'Variation 1' : 'Control',
        });
        setEnableAfterPay(variation);
      }
      if (detectedCountry.country === 'NZ') {
        const variation = await getVariation('enable-afterpay-nz', true);
        // segment track
        insiderTrackEvent('launchdarkly2', {
          feature_flag: 'enable-afterpay-nz',
          variation_value: variation ? 'Variation 1' : 'Control',
        });
        setEnableAfterPay(variation);
      }
    }
  }, [detectedCountry]); 

  const initStripeJsSdk = async (stripePublishableKey: string): Promise<void> => {
    const stripePromise = await loadStripe(stripePublishableKey);
    if (stripePromise) {
      setStripe(stripePromise);
    }
  };

  return {
    stripe,
    card,
    setCard,
    initStripeJsSdk,
    enableAfterPay,
    showAfterPayModal,
    setShowAfterPayModal,
  };
};

export const [StripeProvider, useStripe] = unreduxed(container);
