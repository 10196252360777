import { useFetch } from 'use-http';
import { initialize, LDClient, LDOptions, LDUser } from 'launchdarkly-js-client-sdk';
import jws from 'jws';
import { useState } from 'react';
import unreduxed from 'unreduxed';
import md5 from 'md5';
import { useAsyncEffect } from 'use-async-effect';
import forEach from 'lodash/forEach';
import forIn from 'lodash/forIn';
import cloneDeep from 'lodash/cloneDeep';
import { isJSON } from '../common/util';
import { sendEvent, sendGa4Event } from '../common/GTM';
import { useCountry } from './CountryContainer';
import { insiderTrackEvent } from '../common/insider';

const container = () => {
  const country = useCountry((con) => con.country);
  const setCountry = useCountry((con) => con.setCountry);
  const [ldClient, setLdClient] = useState<LDClient>();
  const [email, setEmail] = useState<string>();
  // const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ldForce, setLdForce] = useState<any>();
  const { post: createLaunchdarklyEvent } = useFetch('/api/v1/launchdarkly-events');
  const { get: getFlagLaunchdarklyByKey } = useFetch('/api/v1/launchdarkly-events/flag');
  const getLDForce = () => {
    // console.log('window.location.search :', window.location.search);
    // console.log('outer.query', router.query);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // console.log('params :', params);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ldf: any = {};
    forEach(params, (value, key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let val: any = value;
      if (key.startsWith('ld-')) {
        if (value === 'true') val = true;
        if (value === 'false') val = false;
        if (parseInt(value, 10).toString() === value) val = parseInt(value, 10);
        ldf[key.substring(3)] = val;
      }
      if (key.startsWith('lds-')) {
        window.localStorage.setItem(key, val);
      }
    });
    forIn(window.localStorage, (value, key) => {
      if (key.startsWith('lds-')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let val: any = value;
        if (value === 'true') val = true;
        if (value === 'false') val = false;
        if (parseInt(value, 10).toString() === value) val = parseInt(value, 10);
        ldf[key.substring(4)] = val;
      }
    });
    // console.log('ldf parsed', ldf);
    setLdForce(ldf);
    return ldf;
  };
  const initLdClient = async (initEmail?: string) => {
    //
    if (ldClient) return ldClient;
    let user: LDUser = { anonymous: true };
    if (initEmail) {
      user = {
        anonymous: false,
        key: md5(initEmail),
        email: initEmail,
      };
    }
    const options: LDOptions = {
      disableSyncEventPost: true,
      sendEventsOnlyForVariation: true,
    };
    const client = initialize(`${process.env.NEXT_PUBLIC_LAUNCH_CLIENT_SIDE_ID}`, user, options);
    await setLdClient(client);
    return client;
  };

  const getVariation = async (flagKey: string, defaultValue: unknown, skipTracking?: boolean) => {
    // console.log('getVariation :', flagKey);
    //
    const client = await initLdClient();
    await client.waitUntilReady();
    let ldf = ldForce;
    // console.log('ldf :', ldf);
    if (!ldf) {
      ldf = getLDForce();
    }
    // console.log('ldf :', ldf);
    // console.log('ldf[flagKey] :', ldf[flagKey]);
    if (ldf && ldf[flagKey] !== undefined) {
      return ldf[flagKey];
    }
    const localVariation = window.localStorage.getItem('integrationTesting-launchDarkly');
    // check if preform integration testing
    if (localVariation && isJSON(localVariation) && process.env.NEXT_PUBLIC_ENV !== 'production') {
      const localVariationJson = JSON.parse(localVariation);
      // If override the flagKey
      if (flagKey in localVariationJson) {
        // If Json convert to Json
        if (isJSON(localVariationJson[flagKey])) return JSON.parse(localVariationJson[flagKey]);
        // check that data type to return is Number
        const testNumber = Number(localVariationJson[flagKey]);
        if (!Number.isNaN(testNumber)) return testNumber;

        // Canbe string or boolean type
        return localVariationJson[flagKey];
      }
    }
    if (skipTracking) {
      const allFlags = client?.allFlags();
      //
      if (allFlags && allFlags[flagKey]) return allFlags[flagKey];
      return defaultValue;
    }
    // get variation from LaunchDarkly
    const variation = client?.variation(flagKey, defaultValue);
    if (!skipTracking) sendGa4Event('launchdarkly', flagKey, variation);
    let value = cloneDeep(variation);
    if (typeof value === 'object') {
      value = JSON.stringify(variation);
    } else if (typeof value === 'boolean') {
      value = value ? 'TRUE' : 'FALSE';
    }
    const data = {
      feature_flag: flagKey,
      value,
      user_id: null,
      email: null,
    };
    let token = window.localStorage.getItem('token');
    if (token) {
      token = token.replaceAll('"', '');
      const decode = jws.decode(token);
      if (decode && decode.payload && decode.payload.data) {
        data.user_id = decode.payload.data.id;
        data.email = decode.payload.data.email;
      }
    }
    createLaunchdarklyEvent(data);
    // console.log('launchdarkly variation', flagKey, variation);
    return variation;
  };

  const getFlagByKey = async (key: string) => {
    const flag = await getFlagLaunchdarklyByKey(`?key=${encodeURIComponent(key)}`);
    return flag?.data;
  };

  const trackEvent = async (event: string) => {
    // console.log('trackEvent :', event);
    const client = await initLdClient();
    await client.waitUntilReady();
    await client.track(event);
    sendEvent('launchdarkly', 'track-event', event, true);
  };

  const flush = async () => {
    const client = await initLdClient();
    await client.waitUntilReady();
    client.flush();
  }

  useAsyncEffect(async () => {
    // console.log('email :', email);
    const client = await initLdClient(email);
    await client.waitUntilReady();
    // console.log('country :', country);
    //
    if (email && email !== '') {
      client.identify({
        key: md5(email),
        email,
        anonymous: false,
        country: country?.country,
      });
    } else {
      client.identify({ anonymous: true, country: country?.country });
    }
  }, [email, country]);

  useAsyncEffect(async () => {
    const token = window.localStorage.getItem('token');
    if (country && country.enableShippingThresholdTesting && !country.freeShippingThreshold && !token) {
      const key = `enable-shipping-threshold-${country.country.toLowerCase()}`;
      const freeShippingThreshold = await getVariation(key, country.freeShipping);
      if (freeShippingThreshold && freeShippingThreshold > 0) {
        setCountry({ ...country, ...{ freeShipping: freeShippingThreshold, freeShippingThreshold } });
      }
      insiderTrackEvent('launchdarkly3', {
        feature_flag: key,
        variation_value: freeShippingThreshold,
      });
    }
  }, [country]);

  return {
    initLdClient,
    ldClient,
    getVariation,
    setEmail,
    trackEvent,
    getFlagByKey,
    flush,
  };
};

export const [LaunchDarklyProvider, useLaunchDarkly] = unreduxed(container);
