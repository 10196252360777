import {
  getProducts as _getProducts,
  getProductsBySku as _getProductsBySku,
  getCategories as _getCategories,
  getProductByGroups as _getProductByGroups,
  getProduct as _getProduct,
  getProductBySlug as _getProductBySlug,
  getQuestions as _getQuestions,
  getSetting as _getSetting,
  getCountries as _getCountries,
  getPromoCode as _getPromoCode,
  getExtraDiscountCode as _getExtraDiscountCode,
  postReviewClick as _postReviewClick,
  postCheckoutClick as _postCheckoutClick,
  getToken as _getToken,
  getPolicy as _getPolicy,
  postEvent as _postEvent,
  getTerms as _getTerms,
  getSubItems as _getSubItems,
  getSubscription as _getSubscription,
  getProductCollection as _getProductCollection,
  getProductCollections as _getProductCollections,
  getLatestOrder as _getLatestOrder,
  changeStatusSubscription as _changeStatusSubscription,
  checkRetryPayment as _checkRetryPayment,
  applyPromoCodeToSubscription as _applyPromoCodeToSubscription,
  retryManualPayment as _retryManualPayment,
  createTokenAfterPay as _createTokenAfterPay,
  storeCherryData as _storeCherryData,
  storeMarryData as _storeMarryData,
  postMarryRecommender as _postMarryRecommender,
} from './old-next-util';

function handlerError<T extends any[], U>(func: (...args: T) => Promise<U>) {
  return async (...args: T): Promise<U> => {
    try {
      return await func(...args);
    } catch (error) {
      if ((error as Error).name === 'AbortError') return undefined as unknown as U;
      console.log('Error ', error);
      throw error;
    }
  };
}

function handlersError<T extends any[], U>(func: (...args: T) => Promise<U>) {
  return async (...args: T): Promise<U> => {
    try {
      return await func(...args);
    } catch (error) {
      if ((error as Error).name === 'AbortError') return [] as unknown as U;
      console.log('Error ', error);
      throw error;
    }
  };
}

export const getProducts = handlersError(_getProducts);

export const getProductsBySku = handlersError(_getProductsBySku);

export const getCategories = handlersError(_getCategories);

export const getProductByGroups = handlersError(_getProductByGroups);

export const getProduct = handlerError(_getProduct);

export const getProductBySlug = handlerError(_getProductBySlug);

export const getQuestions = handlersError(_getQuestions);

export const getSetting = handlerError(_getSetting);

export const getCountries = handlersError(_getCountries);

export const getPromoCode = handlerError(_getPromoCode);

export const getExtraDiscountCode = handlerError(_getExtraDiscountCode);

export const postReviewClick = handlerError(_postReviewClick);

export const postCheckoutClick = handlerError(_postCheckoutClick);

export const getToken = handlerError(_getToken);

export const postEvent = handlerError(_postEvent);

export const getPolicy = handlerError(_getPolicy);

export const getTerms = handlerError(_getTerms);

export const getProductCollection = handlerError(_getProductCollection);

export const getProductCollections = handlersError(_getProductCollections);

export const checkRetryPayment = _checkRetryPayment;

export const changeStatusSubscription = handlerError(_changeStatusSubscription);

export const applyPromoCodeToSubscription = handlerError(_applyPromoCodeToSubscription);

export const getSubscription = handlerError(_getSubscription);

export const getSubItems = handlersError(_getSubItems);

export const getLatestOrder = handlerError(_getLatestOrder);

export const retryManualPayment = handlerError(_retryManualPayment);

export const createTokenAfterPay = handlerError(_createTokenAfterPay);

export const storeCherryData = handlerError(_storeCherryData);

export const storeMarryData = handlerError(_storeMarryData);

export const postMarryRecommender = handlerError(_postMarryRecommender);
