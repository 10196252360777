export type StatusProgress = {
  youAreHere: string;
  halfway: string;
  lastOne: string;
};

export enum SurveyVersion {
  DEFAULT = 'default',
  ADD_ONS = 'add-ons',
}
