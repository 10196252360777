import type { NextRouter } from 'next/router';
import ceil from 'lodash/ceil';
import cloneDeep from 'lodash/cloneDeep';
import floor from 'lodash/floor';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import sum from 'lodash/sum';
import sha256 from 'sha256';
import {
  TEST_SETTING_REF_CREDIT,
  TEST_SETTING_MIN_ORDER_PRICE,
  TEST_SETTING_REF_DISCOUNT,
  TEST_SETTING_MAX_PILLS_PER_SACHET,
  TEST_SETTING_CHECKOUT_POPUP_PROMOCODE,
  TEST_SETTING_CHECKOUT_POPUP_DOLLAR,
  TEST_SETTING_CHECKOUT_POPUP_DELAY,
  TEST_SETTING_CHECKOUT_POPUP_DISCOUNT,
  TEST_SETTING_CART_POPUP_PROMOCODE,
  TEST_SETTING_CART_POPUP_DELAY,
  TEST_SETTING_USE_CART_POPUP_PROMOCODE,
  TEST_SETTING_USE_CHECKOUT_POPUP_PROMOCODE,
  TEST_SETTING_MAINTENANCE_MODE,
  TEST_SETTING_CART_POPUP_DISCOUNT,
  TEST_SETTING_CART_POPUP_DOLLAR,
  TEST_FOUR2_CHECKOUT_PROMO,
  BLACK_FRIDAY_VARIANT_ORIGIN,
} from '../../test/config';
import { Address, ShouldBeComponent } from '../types/Address';
import { Reason } from '../types/Reason';
import { Setting } from '../types/Setting';
import {
  ORDER_SUMMARY_DEFAULT, MOBILE_APP_VIEW_DEFAULT, SHIPPING_VARIANT_DEFAULT, promoCodeTypes, discountType,
} from './const';
import { toSetting } from './transformers';
import { Country } from '../types/Country';
import { CartItem } from '../types/CartItem';
import { PromoCode } from '../types/PromoCode';
import { ItemsDiscount } from '../types/Order';
import { Criteria, Question } from '../types/Question';
import { AfterPayResponse } from '../types/AfterPayResponse';
import { Product } from '../types/Product';
import { QuestionResponse } from '../types/QuestionResponse';

export function redirectLogin(token: string | null, router: NextRouter): void {
  if (!token) {
    const path: string = encodeURIComponent(router.asPath);
    router.replace(`/auth/login?redirect=${path}`);
  }
}

export function getImageCache(
  src: string,
  width?: number,
  height?: number,
  format?: string,
): string {
  let url = `https://images.weserv.nl/?url=${src}`;
  const f = format || 'webp';
  if (width) url += `&w=${width}`;
  if (height) url += `&h=${height}`;
  if (height) url += `&h=${height}`;
  url += `&output=${f}`;
  return url;
}

export const convertQueryParams = (queryParams?: string): {
  token?: string;
  'force-country'?: string;
  auth?: string;
  integrationTesting?: string;
  blackFridayVariant?: string;
  shippingVariation?: string;
  internationalReviewPage?: string;
  recommendationSummary?: string;
  mobilePageView?: string;
  ref?: string;
  promo?: string;
  email?: string;
} => {
  let urlSearchParams;
  if (queryParams && queryParams !== '') {
    urlSearchParams = new URLSearchParams(queryParams);
  } else {
    urlSearchParams = new URLSearchParams(window.location.search);
  }
  const data = Object.fromEntries(urlSearchParams.entries());
  return data;
};
export const getAuthQueryToken = (): string | null => {
  const data = convertQueryParams();
  return data.auth || null;
};

export function validEmail(email: string): boolean {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return false;
  }
  return true;
}

export function randomString(): string {
  const maxLength = 8;
  const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
  let string = '';
  for (let i = 0; i < maxLength; i++) {
    string += chars[Math.floor(Math.random() * chars.length)];
  }
  return string;
}

export function randomEmail(): string {
  const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
  let string = '';
  for (let i = 0; i < 15; i++) {
    string += chars[Math.floor(Math.random() * chars.length)];
  }
  return `${string}@hotmail.com`;
}

export function randomNumber(min: number, max: number): number {
  const newMin = Math.ceil(min);
  const newMax = Math.floor(max);
  return Math.floor(Math.random() * (newMax - newMin + 1)) + newMin;
}

export function randomPhone(): string {
  let string = '';
  for (let i = 0; i < 10; i++) {
    string += randomNumber(0, 9);
  }
  return string;
}

export const randomAddress = (): {
  randomAddressLine1: string;
  randomPostcode: number;
  randomSuburb: string;
  randomState: string;
  randomCountry: string;
} => {
  const randomAddressLine1 = '428 George Street';
  const randomPostcode = randomNumber(1000, 7999);
  const randomSuburb = 'Sydney';
  const randomState = 'NSW';
  const randomCountry = 'AU';
  return {
    randomAddressLine1,
    randomPostcode,
    randomSuburb,
    randomState,
    randomCountry,
  };
};

export function handlingErrorMessages(message: string): string {
  let newMessage = message;
  if (message === 'Password does not match') {
    newMessage = 'Your password is incorrect. If you forgot it, click Forgot password below';
  }
  return newMessage;
}

export function trustPilotScript(): HTMLScriptElement {
  const script = document.createElement('script');
  script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.sync.bootstrap.min.js';
  script.async = true;
  script.type = 'text/javascript';
  return script;
}

export function afterPayScript(): HTMLScriptElement {
  const script = document.createElement('script');
  script.onload = function initAfterPay() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { AfterPay } = window as any;
    if (!AfterPay) {
      console.error('AfterPay is undefined.');
    }
    AfterPay.initializeForPopup({
      countryCode: 'AU',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onCommenceCheckout(actions: any) {
        console.log('actions', actions);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onComplete(data: any) {
        console.log('data', data);
      },
      target: '#afterpay-button',
      shippingOptionRequired: false,
    });
  };
  script.src = 'https://portal.sandbox.afterpay.com/afterpay.js';
  script.async = true;
  script.type = 'text/javascript';
  return script;
}

export function checkIfIntegrationTesting(router: NextRouter): boolean {
  // Only allow non production environment
  if (process.env.NEXT_PUBLIC_ENV === 'production') return false;

  const params = router.asPath.split('?');
  let query;

  if (params.length >= 2) query = convertQueryParams();
  if (query && query.integrationTesting && query.integrationTesting === 'true') return true;
  return false;
}
export const getTestSetting = (): Setting => {
  const testSettingJson = {
    data: {
      referral_credit: TEST_SETTING_REF_CREDIT,
      minimum_order_price: TEST_SETTING_MIN_ORDER_PRICE,
      referral_discount: TEST_SETTING_REF_DISCOUNT,
      max_pills_per_sachet: TEST_SETTING_MAX_PILLS_PER_SACHET,
      checkout_popup_promocode: TEST_SETTING_CHECKOUT_POPUP_PROMOCODE,
      checkout_popup_dollar: TEST_SETTING_CHECKOUT_POPUP_DOLLAR,
      checkout_popup_delay: TEST_SETTING_CHECKOUT_POPUP_DELAY,
      checkout_popup_discount: TEST_SETTING_CHECKOUT_POPUP_DISCOUNT,
      cart_popup_promocode: TEST_SETTING_CART_POPUP_PROMOCODE,
      cart_popup_delay: TEST_SETTING_CART_POPUP_DELAY,
      use_cart_popup_promocode: TEST_SETTING_USE_CART_POPUP_PROMOCODE,
      use_checkout_popup_promocode: TEST_SETTING_USE_CHECKOUT_POPUP_PROMOCODE,
      maintenance_mode: TEST_SETTING_MAINTENANCE_MODE,
      cart_popup_discount: TEST_SETTING_CART_POPUP_DISCOUNT,
      cart_popup_dollar: TEST_SETTING_CART_POPUP_DOLLAR,
      gift_card_enabled: true,
    },
  };
  return toSetting(testSettingJson);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTestLaunchDarkly = (router: NextRouter): any => {
  const params = router.asPath.split('?');
  let query;
  if (params.length >= 2) query = convertQueryParams();
  let blackFridayVariant = BLACK_FRIDAY_VARIANT_ORIGIN;
  let internationalReviewPage = ORDER_SUMMARY_DEFAULT;
  let mobilePageView = MOBILE_APP_VIEW_DEFAULT;
  let shippingVariation = SHIPPING_VARIANT_DEFAULT;
  if (query && query.blackFridayVariant) {
    blackFridayVariant = query.blackFridayVariant;
  }
  if (query && query.shippingVariation) {
    shippingVariation = query.shippingVariation;
  }
  if (query && query.internationalReviewPage) {
    internationalReviewPage = query.internationalReviewPage;
  }
  if (query && query.mobilePageView) {
    mobilePageView = query.mobilePageView;
  }
  return {
    'checkout-popup-delay': TEST_SETTING_CHECKOUT_POPUP_DELAY,
    'checkout-popup-promocode': TEST_SETTING_CHECKOUT_POPUP_PROMOCODE,
    'use-cart-popup-promocode': TEST_SETTING_USE_CART_POPUP_PROMOCODE,
    'cart-popup-delay': TEST_SETTING_CART_POPUP_DELAY,
    'cart-popup-promocode': TEST_SETTING_CART_POPUP_PROMOCODE,
    'use-checkout-popup-promocode': TEST_SETTING_USE_CHECKOUT_POPUP_PROMOCODE,
    'four2-checkout-promo': TEST_FOUR2_CHECKOUT_PROMO,
    'black-friday-variation': blackFridayVariant,
    'highlight-shipping-variation': shippingVariation,
    'combined-international-review-page': internationalReviewPage,
    'mobile-app-views-variant': mobilePageView,
  };
};

export const isJSON = (str: string): boolean => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

type AddressServer = {
  apartment: string;
  home: string;
  postcode: string;
  street: string;
  state: string;
  suburb: string;
  // eslint-disable-next-line camelcase
  address_line_1: string;
};

export const convertAddress = (address: Address): AddressServer => {
  const data : AddressServer = {
    apartment: '',
    home: '',
    postcode: '',
    street: '',
    state: '',
    suburb: '',
    address_line_1: '',
  };
  const shouldBeComponent: ShouldBeComponent = {
    apartment: ['subpremise'],
    home: ['street_number'],
    postcode: ['postal_code'],
    street: ['street_address', 'route'],
    state: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    suburb: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
      'neighborhood',
      'postal_town',
    ],
  };
  address.address_components.forEach((component) => {
    Object.keys(shouldBeComponent).forEach((shouldBe) => {
      if (
        shouldBeComponent[shouldBe as keyof ShouldBeComponent].indexOf(
          component.types[0],
        ) !== -1
      ) {
        if (shouldBe === 'state') {
          data[shouldBe] = component.short_name;
        } else {
          data[shouldBe as keyof ShouldBeComponent] = component.long_name;
        }
      }
    });
  });

  data.home = data.apartment !== '' ? `${data.apartment}/${data.home}` : data.home;
  data.address_line_1 = `${data.home} ${data.street}`;
  data.address_line_1 = data.address_line_1 === ' ' ? '' : data.address_line_1;
  return data;
};

type QuestionsShuffled = {
  [key: string]: Reason[];
};

export const questionsShuffled: QuestionsShuffled = {};

export const resetQuestionsShuffle = (): void => {
  const allProperties = Object.getOwnPropertyNames(questionsShuffled);

  allProperties.forEach((property) => {
    delete questionsShuffled[property];
  });
};

export const customShuffle = (arr: Reason[], questionId: string): Reason[] => {
  let arrayShuffle;
  if (questionsShuffled[questionId]) {
    arrayShuffle = questionsShuffled[questionId];
  } else {
    arrayShuffle = cloneDeep(arr);
    if (arrayShuffle.length < 2) {
      return arrayShuffle;
    }

    for (let i = arrayShuffle.length - 2; i >= 0; --i) {
      const j = 1 + Math.floor(Math.random() * i);
      [arrayShuffle[i], arrayShuffle[j]] = [arrayShuffle[j], arrayShuffle[i]];
    }
    questionsShuffled[questionId] = arrayShuffle;
  }
  return arrayShuffle;
};

export const getPhoneNumber = (country: Country): { phoneNumber: string, callNumber: string } => {
  const phone = {
    phoneNumber: '+61 1300 996 897',
    callNumber: 'tel:+611300996897',
  };
  if (country && country.country === 'AU') {
    phone.phoneNumber = '1300 996 897';
  }
  return phone;
};

export async function launcherScript(email: string, amount: number): Promise<void> {
  // console.log('email', email);
  // console.log('amount', amount);
  const target = document.head || document.body;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://apps.rokt.com/wsdk/integrations/launcher.js';
  // script.importance = 'high';
  script.crossOrigin = 'anonymous';
  script.async = true;
  script.id = 'rokt-launcher';
  target.appendChild(script);
  // eslint-disable-next-line no-return-assign, @typescript-eslint/no-explicit-any
  await new Promise<void>((resolve) => ((window as any).Rokt ? resolve() : ((document.getElementById('rokt-launcher') as any).onload = resolve)));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const launcher = await (window as any).Rokt.createLauncher({
    accountId: process.env.NEXT_PUBLIC_LAUNCHER_ACCOUNT_ID || 5555511658049098746,
  });
  await launcher.selectPlacements({
    attributes: {
      // Recommended
      emailsha256: sha256(email),
      // Transaction
      amount,
      currency: 'AUD',
      quantity: '1',
      paymenttype: '',
      ccbin: '',
      margin: '',
      confirmationref: '',
      price: '',

      // Consumer
      firstname: '',
      lastname: '',
      mobile: '',
      title: '',
      gender: '',
      dob: '',
      age: '',
      language: '',
      mobilesha256: '',
      // Address

      // Miscellaneous
      clientcustomerid: '',
      // Segmentation
    },
  });
}

export const convertAfterpayOrder = (data: AfterPayResponse): {
  nameOnPack: string,
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2: string,
  suburb: string,
  country: string,
  state: string,
  postcode: string,
  email: string,
  phone: string,
  afterpayToken: string,
  paymentGateway: string,
} => {
  const { orderInfo, orderToken } = data.data;
  const result = {
    nameOnPack: orderInfo.consumer.givenNames,
    firstName: orderInfo.consumer.givenNames,
    lastName: orderInfo.consumer.surname,
    addressLine1: orderInfo.shippingAddress.line1,
    addressLine2: orderInfo.shippingAddress.line2 || '',
    suburb: orderInfo.shippingAddress.area1,
    country: orderInfo.shippingAddress.countryCode,
    state: orderInfo.shippingAddress.region,
    postcode: orderInfo.shippingAddress.postcode,
    email: orderInfo.consumer.email,
    phone: orderInfo.shippingAddress.phoneNumber,
    afterpayToken: orderToken,
    paymentGateway: 'AFTERPAY',
  };
  return result;
};

export const convertToDubDiscount = (code: string): PromoCode => {
  const data = {
    id: 0,
    amountUse: 0,
    code,
    credit: '',
    discountAmount: '',
    durationInMonths: 0,
    expiryDate: '',
    giftCard: false,
    maxRedemptions: 0,
    name: '',
    newCustomer: false,
    percent: '',
    productLimit: '',
    referralCode: false,
    status: '',
    type: 'dub',
    userId: 0,
    message: '',
    resultMessage: '',
  }
  return data;
}

export const getPricePerDay = (product: Product): string => {
  let amount = 30;
  if (product.suggestedDoseText && product.suggestedDoseText.includes('15')) {
    amount = 15;
  }
  const result = ((product.price * product.quantity) / amount).toFixed(2);
  return result;
};

type Discount = {
  discountDollar: number,
  discountPercent: number,
  firstMonthDiscountDollar?: number,
  secondMonthDiscountDollar?: number,
  firstMonthDiscountPercent?: number,
  secondMonthDiscountPercent?: number,
};

type Total = {
  totalBeforeDiscount: number,
  totalAfterDiscount: number,
};

type PromotionalInformation = {
  discountText: string,
  itemsAreDiscounted: string,
  promotionalText: string,
  firstMonthDiscountText?: string,
  secondMonthDiscountText?: string,
  freeProducts?: string;
  freeGift?: string;
  numberOfMonthsApplied?: string;
  discountAvailableFor?: string;
  discountDollar: number,
  discountPercent: number,
};

type OrderInfo = {
  total: Total,
  discount: Discount,
  promotionalInformation: PromotionalInformation,
  shipping: number,
  itemsDiscount: [] | ItemsDiscount[],
};

export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const getSubTotal = (items?: CartItem[], double = false): number => {
  if (!items) return 0;
  let subtotal = sum(items.map((item) => item.quantity * Number(item.product?.price)));
  if (double) {
    subtotal *= 2;
  }
  return subtotal;
};

export const getSubTotalHaveExtraDiscount = (items?: CartItem[], double = false, extraDiscount?: number) : number => {
  if (!items) return 0;
  let subtotal = sum(items.map((item) => item.quantity * Number(item.product?.price)));
  if (double) {
    subtotal *= 2;
    
    if (extraDiscount) {
      subtotal *= ((100 - extraDiscount) / 100) ;
    }
  }
  return subtotal;
}

export const getShippingFee = (items: CartItem[], country: Country, double = false): number => {
  const subtotal = getSubTotal(items, double);
  if (subtotal < country.freeShipping) {
    return country.shippingCost;
  }
  return 0;
};

export const getTotalForDiscountPercent = (totalBeforeDiscount: number, discountPercent: number): number => {
  const total = totalBeforeDiscount * ((100 - discountPercent) / 100);
  return total;
};

export const getTotal = (items: CartItem[], extraDiscount: number, country: Country, double = false): number => {
  const subtotal = getSubTotal(items, double);
  const shipping = getShippingFee(items, country);
  let total = subtotal + shipping;
  if (extraDiscount > 0) {
    total = getTotalForDiscountPercent(subtotal, extraDiscount) + shipping;
  }
  return total;
};

export const categorizeProducts = (items: CartItem[], promoCode: PromoCode): { itemsAreDiscounted: CartItem[], itemsAreNotDiscounted: CartItem[] } => {
  const itemsAreDiscounted: CartItem[] = [];
  const itemsAreNotDiscounted: CartItem[] = [];
  const productLimit = promoCode.productLimit && promoCode.productLimit !== '' ? promoCode.productLimit.replace(/\s/g, '').split(',') : [];
  items.forEach((item: CartItem) => {
    if (item.product?.noPercentDiscount || item.product?.noDollarDiscount) {
      itemsAreNotDiscounted.push(item);
    } else if (productLimit.length > 0) {
      if (productLimit.includes(item.product?.sku || '')) {
        itemsAreDiscounted.push(item);
      } else {
        itemsAreNotDiscounted.push(item);
      }
    } else {
      itemsAreDiscounted.push(item);
    }
  });
  // productLimit.includes(item.product?.sku || '')
  return { itemsAreDiscounted, itemsAreNotDiscounted };
};

export const getDiscountPercent = (total: number, discountValue: string | number): number => {
  const percent = ceil((Number(discountValue) / total) * 100);
  return percent > 100 ? 100 : percent;
};

export const getDiscountDollar = (total: number, discountValue: string | number): number => {
  const amount = (Number(discountValue) / 100) * total;
  return amount;
};

export const getDiscount = (total: number, kindOfDiscount: string, discountValue: string | number): Discount => {
  let discountDollar;
  let discountPercent;
  if (kindOfDiscount === discountType.PERCENT) {
    discountPercent = Number(discountValue);
    discountDollar = getDiscountDollar(total, discountValue);
  } else {
    discountDollar = Number(discountValue) > total ? total : Number(discountValue);
    discountPercent = getDiscountPercent(total, discountValue);
  }

  return { discountDollar: round(discountDollar, 2), discountPercent };
};

export const getTotalForDiscountAmount = (totalBeforeDiscount: number, discountAmount: number): number => {
  const total = totalBeforeDiscount - discountAmount;
  return total;
};

export const changePromoCode = (promoCode: PromoCode): PromoCode => {
  const clonePromoCode = cloneDeep(promoCode);
  // let change = false;
  if (clonePromoCode.type === promoCodeTypes.REPEATING) {
    if (clonePromoCode.durationInMonths > 1 && !clonePromoCode.giftCard && clonePromoCode.discountAmount && clonePromoCode.discountAmount !== '') {
      clonePromoCode.discountAmountForDouble = Number(clonePromoCode.discountAmount) * 2;
    }
    if (clonePromoCode.durationInMonths === 1) {
      clonePromoCode.onlyApplyForOnePack = true;
    }
  }
  if (clonePromoCode.type === 'once') {
    clonePromoCode.onlyApplyForOnePack = true;
  }
  return clonePromoCode;
};

export const getTotalAfterDiscount = (totalBeforeDiscount: number, kindOfDiscount: string, discountValue: string | number): number => {
  let total;
  if (kindOfDiscount === discountType.PERCENT) {
    total = getTotalForDiscountPercent(totalBeforeDiscount, Number(discountValue));
  } else {
    total = getTotalForDiscountAmount(totalBeforeDiscount, Number(discountValue));
  }
  return total > 0 ? total : 0;
};

export const getPromotionalInformation = (promoCode: PromoCode, discount: Discount, totalBeforeDiscount: number, double?: boolean): PromotionalInformation => {
  const itemsAreDiscounted = '';
  const productLimit = (promoCode.productLimit && promoCode.productLimit !== '') || false;
  let promotionalText = '';
  let discountText = '';
  let firstMonthDiscountText = '';
  let secondMonthDiscountText = '';
  let freeProducts = '';
  let freeGift = '';
  let numberOfMonthsApplied = '';
  let discountAvailableFor = '';
  let remainingAvailableMonths = 0;
  const monthsUsed = double ? 2 : 1;
  if (promoCode.freeGift && promoCode.freeGift !== '') {
    freeGift = promoCode.freeGift;
  }
  if (promoCode.freeProducts && promoCode.freeProducts.length) {
    const freeProductList = cloneDeep(promoCode.freeProducts);
    freeProducts = `Free ${freeProductList.map((item) => item.name)
      .toString()
      .replace(',', ', ')} `;
  }
  if (promoCode.resultMessage && promoCode.resultMessage !== '') {
    if (freeProducts !== '') freeProducts = promoCode.resultMessage;
    if (freeGift !== '') freeGift = promoCode.resultMessage;
  }
  if (promoCode.percent && promoCode.percent !== '') {
    discountText = `${promoCode.percent}%`;
  } else if (promoCode.discountAmount && promoCode.discountAmount !== '') {
    if (promoCode.type === promoCodeTypes.REPEATING) {
      if ((Number(promoCode.discountAmount) > totalBeforeDiscount)) {
        discountText = `$${totalBeforeDiscount}`;
      } else {
        discountText = `$${discount.discountDollar}`;
      }
    } else {
      discountText = `$${discount.discountDollar}`;
    }
  }
  promotionalText = `You've saved ${discountText} today!`;
  firstMonthDiscountText = `You’ve saved ${discountText} on one Monthly pack!`;
  if (productLimit) {
    promotionalText = `${discountText} off eligible products.`;
    firstMonthDiscountText = `${discountText} off eligible products.`;
    numberOfMonthsApplied = 'Applied to one Monthly pack.';
    if (promoCode.type === promoCodeTypes.REPEATING) {
      if (promoCode.durationInMonths > 1) {
        numberOfMonthsApplied = 'Applied to two Monthly packs.';
        remainingAvailableMonths = promoCode.durationInMonths - monthsUsed;
      }
    }
    if (promoCode.type === promoCodeTypes.FOREVER) numberOfMonthsApplied = 'Applied to two Monthly packs.';
  }
  if ((promoCode.type === promoCodeTypes.REPEATING || promoCode.type === promoCodeTypes.FOREVER) && !productLimit) {
    firstMonthDiscountText = `You’ve saved ${discountText} on two Monthly packs!`;
    if (promoCode.type === promoCodeTypes.REPEATING) {
      remainingAvailableMonths = promoCode.durationInMonths - monthsUsed;
    }
  }
  if (promoCode.message && promoCode.message !== '') {
    promotionalText = promoCode.message;
  }
  if (promoCode.type === promoCodeTypes.DEGRESSIVE && promoCode.degressiveDiscount?.length) {
    const firstMonthDiscount = promoCode.degressiveDiscount[0].type;
    const secondMonthDiscount = promoCode.degressiveDiscount[1] ? promoCode.degressiveDiscount[1].type : '';
    const discountTextFirstMonth = firstMonthDiscount === discountType.DOLLAR ? `$${discount.firstMonthDiscountDollar}` : `${discount.firstMonthDiscountPercent}%`;
    const discountTextSecondMonth = secondMonthDiscount === discountType.DOLLAR ? `$${discount.secondMonthDiscountDollar}` : `${discount.secondMonthDiscountPercent}%`;
    promotionalText = `You've saved ${discountTextFirstMonth} today`;
    firstMonthDiscountText = `Saved ${discountTextFirstMonth} on 1st Month pack`;
    secondMonthDiscountText = `Saved ${discountTextSecondMonth} on 2nd Month pack`;
    remainingAvailableMonths = promoCode.degressiveDiscount.length - monthsUsed;
  }
  if (remainingAvailableMonths > 1) {
    discountAvailableFor = `*Discount available for ${remainingAvailableMonths} more Monthly packs after purchase. Automatically applied to the next order.`;
  } else if (remainingAvailableMonths === 1) {
    discountAvailableFor = `*Discount available for ${remainingAvailableMonths} more Monthly pack after purchase. Automatically applied to the next order.`;
  }
  if (remainingAvailableMonths >= 1) {
    promotionalText += '*';
    if (promoCode.type === promoCodeTypes.REPEATING) firstMonthDiscountText += '*';
  }
  const { discountDollar, discountPercent } = discount;
  return {
    discountText,
    itemsAreDiscounted,
    promotionalText,
    firstMonthDiscountText,
    secondMonthDiscountText,
    freeProducts,
    freeGift,
    numberOfMonthsApplied,
    discountAvailableFor,
    discountDollar,
    discountPercent,
  };
};

export const getItemsDiscount = (items: CartItem[], type: string, value: number, double = false): ItemsDiscount[] => {
  const data = items.map((item) => {
    const subtotal = getSubTotal([item], double);
    const discount = getDiscount(subtotal, type, value);
    const productAmount = (item.product?.price || 1) * item.quantity;
    if (double && discount.discountDollar > productAmount) {
      discount.discountDollar /= 2;
    }
    return { sku: item.product?.sku, discountAmount: `$${formatter.format(discount.discountDollar)}` };
  });
  return data as ItemsDiscount[];
};

export const calcNormalDiscount = (items: CartItem[], promoCode: PromoCode, extraDiscount = 0, country: Country, double = false): OrderInfo => {
  const extraDiscountPercent = double ? extraDiscount : 0
  const shipping = getShippingFee(items, country);
  const subtotal = getSubTotal(items, double);
  let discount = { discountDollar: 0, discountPercent: 0 };
  let promotionalInformation = {
    discountText: '', itemsAreDiscounted: '', promotionalText: '', discountDollar: 0, discountPercent: 0,
  };
  const totalBeforeDiscount = getTotalForDiscountPercent(subtotal, extraDiscountPercent) + shipping;
  let totalAfterDiscount = getTotalForDiscountPercent(subtotal, extraDiscountPercent) + shipping;
  let findProductsWithoutDiscount;
  let itemsDiscount: [] | ItemsDiscount[] = [];
  if (promoCode.discountAmount && promoCode.discountAmount !== '') {
    findProductsWithoutDiscount = items.find((item) => item.product?.noDollarDiscount);
  }
  if (promoCode.percent && promoCode.percent !== '') {
    findProductsWithoutDiscount = items.find((item) => item.product?.noPercentDiscount);
  }
  // console.log('findProductsWithoutDiscount', findProductsWithoutDiscount);
  const specifiedDiscount = (!isUndefined(findProductsWithoutDiscount) || (promoCode.productLimit && promoCode.productLimit !== ''));
  // console.log('specifiedDiscount', specifiedDiscount);
  let clonePromoCode = cloneDeep(promoCode);
  if (double) clonePromoCode = changePromoCode(promoCode);
  const type = clonePromoCode.percent && clonePromoCode.percent !== '' ? discountType.PERCENT : discountType.DOLLAR;
  let value = type === discountType.PERCENT ? Number(clonePromoCode.percent) : Number(clonePromoCode.discountAmount);
  if (specifiedDiscount) {
    const cartItem = categorizeProducts(items, promoCode);
    const totalAmountOfProductsDiscounted = getTotalForDiscountPercent(getSubTotal(cartItem.itemsAreDiscounted, double), extraDiscountPercent);
    const totalAmountOfProductsNoDiscounted = getTotalForDiscountPercent(getSubTotal(cartItem.itemsAreNotDiscounted, double), extraDiscountPercent);
    if (clonePromoCode.discountAmountForDouble) value = clonePromoCode.discountAmountForDouble;
    if (double && clonePromoCode.onlyApplyForOnePack && type === discountType.PERCENT) value /= 2;
    if (double && clonePromoCode.onlyApplyForOnePack && type === discountType.DOLLAR && value > totalAmountOfProductsDiscounted / 2) {
      value = totalAmountOfProductsDiscounted / 2;
    }
    itemsDiscount = getItemsDiscount(cartItem.itemsAreDiscounted, type, value, double);
    let totalDiscounted = 0;
    if (findProductsWithoutDiscount) {
      discount = getDiscount(totalAmountOfProductsDiscounted + shipping, type, value);
      discount = { ...discount, ...{ firstMonthDiscountDollar: discount.discountDollar, firstMonthDiscountPercent: discount.discountPercent } };
      totalDiscounted = getTotalAfterDiscount(totalAmountOfProductsDiscounted + shipping, type, value);
      totalAfterDiscount = totalDiscounted + totalAmountOfProductsNoDiscounted;
      promotionalInformation = getPromotionalInformation(promoCode, discount, totalAmountOfProductsDiscounted + shipping, double);
    }
    if (promoCode.productLimit && promoCode.productLimit !== '') {
      discount = getDiscount(totalAmountOfProductsDiscounted, type, value);
      discount = { ...discount, ...{ firstMonthDiscountDollar: discount.discountDollar, firstMonthDiscountPercent: discount.discountPercent } };
      totalDiscounted = getTotalAfterDiscount(totalAmountOfProductsDiscounted, type, value);
      totalAfterDiscount = totalDiscounted + totalAmountOfProductsNoDiscounted + shipping;
      promotionalInformation = getPromotionalInformation(promoCode, discount, totalAmountOfProductsDiscounted, double);
    }
  } else {
    if (clonePromoCode.discountAmountForDouble) value = clonePromoCode.discountAmountForDouble;
    if (double && clonePromoCode.onlyApplyForOnePack) {
      const singleSubtotal = getTotalForDiscountPercent(getSubTotal(items, false), extraDiscountPercent);
      discount = getDiscount(singleSubtotal + shipping, type, value);
      // discount.discountPercent /= 2;
    } else {
      discount = getDiscount(totalBeforeDiscount, type, value);
    }
    discount = { ...discount, ...{ firstMonthDiscountDollar: discount.discountDollar, firstMonthDiscountPercent: discount.discountPercent } };
    totalAfterDiscount = totalBeforeDiscount - discount.discountDollar;
    promotionalInformation = getPromotionalInformation(clonePromoCode, discount, totalBeforeDiscount, double);
  }

  const total = { totalBeforeDiscount, totalAfterDiscount: floor(totalAfterDiscount, 2) };
  return {
    total, discount, promotionalInformation, shipping, itemsDiscount,
  };
};

export const calcDegressiveDiscount = (items: CartItem[], promoCode: PromoCode, extraDiscount = 0, country: Country, double = false): OrderInfo => {
  const extraDiscountPercent = double ? extraDiscount : 0;
  const shipping = getShippingFee(items, country);
  const subtotal = getSubTotal(items, double);
  const itemsDiscount: [] | ItemsDiscount[] = [];
  let firstMonthSubtotal = getSubTotal(items, false);
  let secondMonthSubtotal = double ? firstMonthSubtotal : 0;
  const totalBeforeDiscount = getTotalForDiscountPercent(subtotal, extraDiscountPercent) + shipping;
  if (extraDiscount > 0) {
    firstMonthSubtotal = getTotalForDiscountPercent(firstMonthSubtotal, extraDiscountPercent);
    secondMonthSubtotal = getTotalForDiscountPercent(secondMonthSubtotal, extraDiscountPercent);
  }
  let firstMonthTotal;
  let secondMonthTotal;
  const { degressiveDiscount } = promoCode;
  const firstMonthDiscountType = degressiveDiscount && degressiveDiscount[0] ? degressiveDiscount[0].type : '';
  const secondMonthDiscountType = degressiveDiscount && degressiveDiscount[1] ? degressiveDiscount[1].type : '';
  const firstMonthDiscountValue = degressiveDiscount && degressiveDiscount[0] ? degressiveDiscount[0].value : 0;
  const secondMonthDiscountValue = degressiveDiscount && degressiveDiscount[1] ? degressiveDiscount[1].value : 0;
  const findProductsWithoutDiscount = items.find((item) => item.product?.noPercentDiscount || item.product?.noDollarDiscount);
  const specifiedDiscount = (!isUndefined(findProductsWithoutDiscount) || (promoCode.productLimit && promoCode.productLimit !== ''));
  // let clonePromoCode = _.cloneDeep(promoCode);
  if (specifiedDiscount) {
    const cartItem = categorizeProducts(items, promoCode);
    const totalAmountOfProductsDiscounted = getSubTotal(cartItem.itemsAreDiscounted, false);
    const totalAmountOfProductsNoDiscounted = getSubTotal(cartItem.itemsAreNotDiscounted, false);
    firstMonthSubtotal = totalAmountOfProductsDiscounted;
    secondMonthSubtotal = double ? firstMonthSubtotal : 0;
    firstMonthTotal = getTotalAfterDiscount(firstMonthSubtotal, firstMonthDiscountType, firstMonthDiscountValue) + totalAmountOfProductsNoDiscounted;
    secondMonthTotal = getTotalAfterDiscount(secondMonthSubtotal, secondMonthDiscountType, secondMonthDiscountValue) + (double ? totalAmountOfProductsNoDiscounted : 0);
  } else {
    firstMonthTotal = getTotalAfterDiscount(firstMonthSubtotal, firstMonthDiscountType, firstMonthDiscountValue);
    secondMonthTotal = getTotalAfterDiscount(secondMonthSubtotal, secondMonthDiscountType, secondMonthDiscountValue);
  }
  const totalAfterDiscount = Number(formatter.format(firstMonthTotal + secondMonthTotal + shipping));
  const firstMonthDiscount = getDiscount(firstMonthSubtotal, firstMonthDiscountType, firstMonthDiscountValue);
  const secondMonthDiscount = getDiscount(secondMonthSubtotal, secondMonthDiscountType, secondMonthDiscountValue);
  const getDiscountAmount = getDiscount(totalBeforeDiscount, discountType.DOLLAR, totalBeforeDiscount - totalAfterDiscount);
  const discount = {
    ...{ discountDollar: getDiscountAmount.discountDollar, discountPercent: getDiscountAmount.discountPercent },
    ...{ firstMonthDiscountDollar: firstMonthDiscount.discountDollar, firstMonthDiscountPercent: firstMonthDiscount.discountPercent },
    ...{ secondMonthDiscountDollar: secondMonthDiscount.discountDollar, secondMonthDiscountPercent: secondMonthDiscount.discountPercent },
  };
  const promotionalInformation = getPromotionalInformation(promoCode, discount, totalBeforeDiscount, double);
  const total = { totalBeforeDiscount, totalAfterDiscount: floor(totalAfterDiscount, 2) };
  return {
    total, discount, promotionalInformation, shipping, itemsDiscount,
  };
};

export const getOrderDataOnApplyingPromoCode = (items: CartItem[], promoCode: PromoCode, extraDiscount: number, country: Country, double = false): OrderInfo => {
  let data;
  if (promoCode.type === promoCodeTypes.DEGRESSIVE) {
    data = calcDegressiveDiscount(items, promoCode, extraDiscount, country, double);
  } else {
    data = calcNormalDiscount(items, promoCode, extraDiscount, country, double);
  }
  return data;
};

export const getCredit = (items: CartItem[], promoCode: PromoCode | undefined, extraDiscount: number, country: Country, credit: number, minimumOrderPrice: number, double = false): number => {
  let amountReduced;
  let total;
  if (promoCode) {
    const getData = getOrderDataOnApplyingPromoCode(items, promoCode, extraDiscount, country, double);
    total = getData.total.totalAfterDiscount;
  } else {
    total = getTotal(items, extraDiscount, country, double);
  }
  const surplus = total - credit;
  if (surplus < minimumOrderPrice) {
    amountReduced = total - minimumOrderPrice > 0 ? total - minimumOrderPrice : minimumOrderPrice;
    // total = minimumOrderPrice;
  } else {
    amountReduced = credit;
    // total = surplus;
  }
  return amountReduced;
};

export const getTotalAfterCredit = (items: CartItem[], promoCode: PromoCode | undefined, extraDiscount: number, country: Country, credit: number, minimumOrderPrice: number, double = false): number => {
  let total;
  if (promoCode) {
    const getData = getOrderDataOnApplyingPromoCode(items, promoCode, extraDiscount, country, double);
    total = getData.total.totalAfterDiscount;
  } else {
    total = getTotal(items, extraDiscount, country, double);
  }
  if (credit && credit > 0) {
    const surplus = total - credit;
    if (surplus < minimumOrderPrice) {
      total = minimumOrderPrice;
    } else {
      total = surplus;
    }
    return total;
  }
  return total;
};

export const isValidPromoCode = (items: CartItem[], promoCode: PromoCode, country: Country, double = false): { valid: boolean, errorMessages: string[] } => {
  let maxBasketCapValid = true;
  let miniumSpendValid = true;
  let productLimitValid = true;
  let errorMessage = promoCode.errorMessage || '';
  const errorMessages = [];
  if (promoCode.maxBasketCap) {
    const maxBasketCap = double ? promoCode.maxBasketCap * 2 : promoCode.maxBasketCap;
    const total = getTotal(items, 0, country, double);
    if (total > maxBasketCap) {
      maxBasketCapValid = false;
      if (double) {
        errorMessage = promoCode.errorMessageForDouble || '';
      } else {
        errorMessage = promoCode.errorMessage || '';
      }
      if (errorMessage !== '') errorMessages.push(errorMessage);
    }
  }
  if (promoCode.miniumSpend) {
    const miniumSpend = double ? promoCode.miniumSpend * 2 : promoCode.miniumSpend;
    const total = getSubTotal(items, double);
    if (total < miniumSpend) {
      miniumSpendValid = false;
      errorMessage = `$${miniumSpend} minimum spend excluding shipping. Please add more products to redeem offer.`;
      if (double) {
        errorMessage = promoCode.errorMessageForDouble || errorMessage;
      } else {
        errorMessage = promoCode.errorMessage || errorMessage;
      }
      if (errorMessage !== '') errorMessages.push(errorMessage);
    }
  }
  if (promoCode.productLimit !== null && promoCode.productLimit !== undefined && promoCode.productLimit !== '') {
    const sku = promoCode.productLimit.replace(/\s/g, '').split(',');
    // eslint-disable-next-line no-restricted-syntax
    for (const product of sku) {
      const findProduct = items.find((item) => item.product?.sku === product);
      if (findProduct) {
        productLimitValid = true;
        break;
      }
      productLimitValid = false;
    }
    if (!productLimitValid) {
      errorMessage = 'Please add eligible product(s) to Cart to use this code.';
      errorMessages.push(errorMessage);
    }
  }
  return { valid: maxBasketCapValid && miniumSpendValid && productLimitValid, errorMessages };
};

export const makeResponseId = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghjklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    if (result.length === length) {
      break;
    }
  }
  return result;
};

export const removeCriteriaQuestions = (newQuestions: Question[], previousCriteriaQuestions: Question[]): Question[] => {
  const previousCriteriaQuestionsIds = previousCriteriaQuestions.map((item) => item.id);
  return newQuestions.filter(
    (item: Question) => !previousCriteriaQuestionsIds.includes(item.id),
  );
};

export const getPreviousCriteriaQuestions = (questions: Question[], question: Question, answer: string | number): Question[] => {
  let criteriaQuestions: Question[] = [];
  criteriaQuestions = questions.filter(
    (item: Question) => !isNil(item.criteria?.length),
  );
  const previousCriteriaQuestions: Question[] = [];
  criteriaQuestions.map((item: Question) => {
    const checkCriteria = item.criteria.find(
      (c: Criteria) =>
        c.question === question.short && c.options.find((o) => o === answer),
    );
    if (checkCriteria) previousCriteriaQuestions.push(item);
    return item;
  });
  return previousCriteriaQuestions;
};

export const getCriteriaQuestions = (questions: Question[], question: Question, answer: string | number, responses: QuestionResponse[] | [] = []): Question[] => {
  let criteriaQuestions: Question[] = [];
  criteriaQuestions = questions.filter(
    (item: Question) => !isNil(item.criteria?.length),
  );
  const newQuestions: Question[] = [];
  criteriaQuestions.map((item: Question) => {
    let checkCriteria = false;
    if (item.criteriaIsAnd) {
      checkCriteria = item.criteria.every((c: Criteria) => {
        const findResponse = responses.find((r) => r.short === c.question);
        if (findResponse && c.options.find((o) => o === findResponse.response)) {
          return true;
        } 
        return false;
      });
    } else {
      const findQuestion = item.criteria.find(
        (c: Criteria) =>
          c.question === question.short && c.options.find((o) => o === answer),
      );
      if (findQuestion) checkCriteria = true;
    }
    const questionIncludedInResponse = responses.find((r) => r.short === item.short);
    if (checkCriteria && !questionIncludedInResponse) newQuestions.push(item);
    return item;
  });
  return newQuestions;
};

export const showOrHideQuestions = (questionsList: Question[], newCriteriaQuestions: Question[], question: Question): Question[] => {
  let newQuestions: Question[] = questionsList;
  if (newCriteriaQuestions && !isEmpty(newCriteriaQuestions)) {
    newQuestions = [...newQuestions, ...newCriteriaQuestions];
  }
  newQuestions = newQuestions.filter(
    (item: Question) => item.id !== question.id,
  );
  newQuestions.push(question);
  newQuestions = orderBy(newQuestions, 'order');
  return newQuestions;
};

export function clearLocalStorage(): void {
  const insiderStorageKeys = ['total-cart-amount', 'paid-products'];
  const storageKeys = Object.keys(localStorage);
  const insiderStorageCharacters = 'ins-';

  storageKeys.forEach((key) => {
    if (!insiderStorageKeys.includes(key) && !key.includes(insiderStorageCharacters)) {
      localStorage.removeItem(key);
    }
  });
}

export default { redirectLogin };
