import { isNil } from 'lodash';
import { CartItem } from '../types/CartItem';
import { Category } from '../types/Category';
import { Policy } from '../types/Policy';
import { Country, UserCountry } from '../types/Country';
import { GroupProduct } from '../types/GroupProduct';
import { OrderConfirmation, OrderItem, OrderUser, ProductOrder } from '../types/Order';
import { OrderLatest } from '../types/OrderLatest';
import { Product } from '../types/Product';
import { Question } from '../types/Question';
import { Setting } from '../types/Setting';
import { PromoCode } from '../types/PromoCode';
import { Terms } from '../types/TermCondition';
import {
  CategoryResponseItem,
  PolicyItem,
  GroupProductResponseItem,
  OrderItemResponseItem,
  OrderLatestResponseItem,
  OrderProductResponseItem,
  OrderResponseItem,
  ProductResponseItem,
  PromoCodeResponse,
  QuestionResponseItem,
  RecommendationProduct,
  SettingResponse,
  SubscriptionItemsResponse,
  SubscriptionResponseItem,
  TermsItem,
  CustomerResponseItem,
  ProductCollectionResponseItem,
  CountryResponse,
  UserCountryResponse,
  PromoCodeInvalidResponse,
  QuizAnswerResponse,
  SubscriptionFrequencyResponse,
  UserResponse,
  OneOffOrderResponseItem,
} from '../types/ServerResponses';
import { SubscriptionItem } from '../types/SubscriptionItem';
import { Customer } from '../types/Customer';
import { Collection } from '../types/Collection';
import { DEFAULT_UPCOMING_INVOICE_FREQUENCY } from './const';
import { QuizAnswer } from '../types/QuizAnswer';
import { SubscriptionFrequency } from '../types/SubscriptionFrequency';
import { User } from '../types/User';
import { AddressData, AddressDataResponse } from '../types/Address';

export const toCategory = (item: CategoryResponseItem): Category => ({
  id: item.id,
  image: item.image,
  name: item.name,
  order: item.order ? item.order : 0,
  parentId: item.parent_id,
  slug: item.slug,
});

export const toOrderItem = (item: OrderItemResponseItem): OrderItem => ({
  freeProduct: item.data.free_product,
  id: item.data.id,
  note: item.data.note,
  productId: item.data.product_id,
  quantity: item.data.quantity,
});

export const toProduct = (item: ProductResponseItem): Product => ({
  id: item.id,
  name: item.name,
  status: item.status,
  description: item.description,
  metaTitle: item.meta_title ? item.meta_title : '',
  metaDescription: item.meta_description,
  metaShortDescription: item.meta_short_description,
  bannerLarge: item.bannerLarge,
  sku: item.sku,
  slug: item.slug,
  nickName: item.nickname,
  listSmall: item.listSmall,
  listLarge: item.listLarge,
  shortDescription: item.short_description,
  price: item.price,
  quantity: item.quantity,
  label: item.label,
  hidden: item.hidden,
  hideFromLibrary: item.hide_from_library,
  recommendation: item.recommendation,
  productReccomendationBanner: item.productReccomendationBanner,
  productReccomendationBannerMobile: item.productReccomendationBannerMobile,
  productFeatureBannerMobile: item.productFeatureBannerMobile,
  productTinyMobile: item.productTinyMobile,
  scientific: item.scientific,
  traditionally: item.traditionally,
  benefit: item.benefit,
  productFeatureBanner: item.productFeatureBanner,
  ourHeading1: item.our_heading_1 ? item.our_heading_1 : '',
  ourBody1: item.our_body_1 ? item.our_body_1 : '',
  ourHeading2: item.our_heading_2 ? item.our_heading_2 : '',
  ourBody2: item.our_body_2 ? item.our_body_2 : '',
  qualityIconNgmo: item.quality_icon_ngmo,
  qualityIconVegetarian: item.quality_icon_vegetarian,
  qualityIconVegan: item.quality_icon_vegan,
  qualityIconNgluten: item.quality_icon_ngluten,
  qualityIconNlactose: item.quality_icon_nlactose,
  qualityIconHalal: item.quality_icon_halal,
  longName: item.long_name,
  unit: item.unit,
  dosage: item.dosage,
  dosageDescription: item.dosage_description,
  ingredients: item.ingredients,
  disclaimer: item.disclaimer,
  brand: item.brand,
  bulk: !isNil(item.bulk) ? item.bulk : false,
  allergens: item.allergens,
  maxQuantity: item.max_quantity,
  suggestedDose: item.quantity,
  suggestedDoseText: item.suggested_dose,
  checkoutGroup: !isNil(item.checkoutGroup) ? item.checkoutGroup : 'PILL',
  noPercentDiscount: !isNil(item.no_percent_discount) ? item.no_percent_discount : false,
  noDollarDiscount: !isNil(item.no_dollar_discount) ? item.no_percent_discount : false,
  categories: item.categories && item.categories.data ? item.categories.data.map(toCategory) : [],
  outOfStock: item.out_of_stock,
  warningReason: item.warning_reason ? item.warning_reason : [],
  blockedCountries: item.blocked_countries,
  bookletReason: item.booklet_reason ? item.booklet_reason : '',
  mediumDescription: item.medium_description ? item.medium_description : '',
  goalBones: item.goal_bone,
  goalBrain: item.goal_brain,
  goalDigestion: item.goal_digestion,
  goalEnergy: item.goal_energy,
  goalEyes: item.goal_eyes,
  goalFitness: item.goal_fitness,
  goalHair: item.goal_hair,
  goalHeart: item.goal_heart,
  goalImmunity: item.goal_immunity,
  goalJoints: item.goal_joint,
  goalNails: item.goal_nails,
  goalSkin: item.goal_skin,
  goalSleep: item.goal_sleep,
  goalStress: item.goal_stress,
  goalPrenatal: item.goal_prenatal,
  goalWomensHealth: item.goal_women_health,
  goalMensHealth: item.goal_men_health,
  hideGoal: !isNil(item.hide_goals) ? item.hide_goals : false,
  lifeStyle: item.lifestyle,
  diet: item.diet,
  customDetails: item.custom_details ? item.custom_details : '',
  useCustomDetails: !isNil(item.use_custom_details) ? item.use_custom_details : false,
  detailLarge: item.detailLarge,
  detailSmall: item.detailSmall,
  hideBreakdown: !isNil(item.hide_breakdown) ? item.hide_breakdown : false,
  howItWorks: item.how_it_works,
  pdpPriceText: item.pdp_price_text ? item.pdp_price_text : '',
  useLargePdpImage: !isNil(item.use_large_pdp_image) ? item.use_large_pdp_image : false,
  largePdpImage: item.large_pdp_image ? item.large_pdp_image : '',
  hideCategory: !isNil(item.hide_category) ? item.hide_category : false,
  researchLink: item.research_link ? item.research_link : '',
  replacementSku: item.replacement_sku ? item.replacement_sku : '',
  healthClaims: item.health_claims ? item.health_claims : [],
  canonicalTag: item.canonical_tag ? item.canonical_tag : '',
  extraDetails: item.extra_details,
  orderItem: item.order_item ? toOrderItem(item.order_item) : undefined,
  type: item.type,
  toldUs: item.told_us,
  availableForSubscription: !isNil(item.available_for_subscription) ? item.available_for_subscription : true,
  availableForOneOff: item.available_for_one_off || false,
  isProcessedInternally: !isNil(item.is_processed_internally) ? item.is_processed_internally : true,
});
export const toCartItem = (item: SubscriptionResponseItem): CartItem => ({
  productId: item.product_id,
  quantity: item.quantity,
});

export const toGroupProduct = (item: GroupProductResponseItem): GroupProduct => ({
  id: item.id,
  name: item.name,
  order: item.order,
  description: item.description,
  products: item.products.data.map(toProduct),
});
export const toCartItems = (response: SubscriptionItemsResponse): CartItem[] => response.data.map(toCartItem);

export const toQuestion = (item: QuestionResponseItem): Question => ({
  criteria: item.criteria,
  enable: item.enable,
  error: item.error,
  firstQuiz: item.first_quiz,
  id: item.id,
  order: item.order,
  max: item.max,
  min: item.min,
  profile: item.profile,
  questionGroupId: item.question_group_id,
  options: item.options,
  quiz: item.quiz,
  short: item.short,
  skipUser: item.skip_user,
  subtitle: item.subtitle,
  text: item.text,
  title: item.title,
  type: item.type,
  whyWeAsk: item.why_we_ask || false,
  whyWeAskContent: item.why_we_ask_content || '',
  criteriaIsAnd: item.criteria_is_and || false,
  updatedAt: item.updated_at,
});

export const toRecommendationProducts = (item: RecommendationProduct): Product => ({
  id: item.id,
  name: item.name,
  status: item.status,
  description: item.description,
  metaTitle: item.meta_title,
  metaDescription: item.meta_description,
  bannerLarge: item.bannerLarge,
  sku: item.sku,
  slug: item.slug,
  nickName: item.nickname,
  listSmall: item.listSmall,
  listLarge: item.listLarge,
  shortDescription: item.short_description,
  price: item.price,
  quantity: item.quantity,
  label: item.label,
  hidden: item.hidden,
  hideFromLibrary: item.hide_from_library,
  recommendation: item.recommendation,
  productReccomendationBanner: item.productReccomendationBanner,
  productReccomendationBannerMobile: item.productReccomendationBannerMobile,
  productFeatureBannerMobile: item.productFeatureBannerMobile,
  productTinyMobile: item.productTinyMobile,
  scientific: item.scientific,
  traditionally: item.traditionally,
  benefit: item.benefit,
  productFeatureBanner: item.productFeatureBanner,
  ourHeading1: item.our_heading_1,
  ourBody1: item.our_body_1,
  ourHeading2: item.our_heading_2,
  ourBody2: item.our_body_2,
  qualityIconNgmo: item.quality_icon_ngmo,
  qualityIconVegetarian: item.quality_icon_vegetarian,
  qualityIconVegan: item.quality_icon_vegan,
  qualityIconNgluten: item.quality_icon_ngluten,
  qualityIconNlactose: item.quality_icon_nlactose,
  qualityIconHalal: item.quality_icon_halal,
  longName: item.long_name,
  unit: item.unit,
  dosage: item.dosage,
  dosageDescription: item.dosage_description,
  ingredients: item.ingredients,
  disclaimer: item.disclaimer,
  brand: item.brand,
  bulk: !isNil(item.bulk) ? item.bulk : false,
  allergens: item.allergens,
  maxQuantity: item.max_quantity,
  suggestedDose: item.quantity,
  suggestedDoseText: item.suggested_dose,
  checkoutGroup: !isNil(item.checkoutGroup) ? item.checkoutGroup : 'PILL',
  noPercentDiscount: !isNil(item.no_percent_discount) ? item.no_percent_discount : false,
  noDollarDiscount: !isNil(item.no_dollar_discount) ? item.no_percent_discount : false,
  categories: item.categories.map(toCategory),
  outOfStock: item.out_of_stock,
  warningReason: item.warning_reason ? item.warning_reason : [],
  blockedCountries: item.blocked_countries,
  bookletReason: item.booklet_reason ? item.booklet_reason : '',
  mediumDescription: item.medium_description ? item.medium_description : '',
  goalBones: item.goal_bone,
  goalBrain: item.goal_brain,
  goalDigestion: item.goal_digestion,
  goalEnergy: item.goal_energy,
  goalEyes: item.goal_eyes,
  goalFitness: item.goal_fitness,
  goalHair: item.goal_hair,
  goalHeart: item.goal_heart,
  goalImmunity: item.goal_immunity,
  goalJoints: item.goal_joint,
  goalNails: item.goal_nails,
  goalSkin: item.goal_skin,
  goalSleep: item.goal_sleep,
  goalStress: item.goal_stress,
  goalPrenatal: item.goal_prenatal,
  goalWomensHealth: item.goal_women_health,
  goalMensHealth: item.goal_men_health,
  hideGoal: !isNil(item.hide_goals) ? item.hide_goals : false,
  lifeStyle: item.lifestyle,
  diet: item.diet,
  customDetails: item.custom_details ? item.custom_details : '',
  useCustomDetails: !isNil(item.use_custom_details) ? item.use_custom_details : false,
  detailLarge: item.detailLarge,
  detailSmall: item.detailSmall,
  hideBreakdown: !isNil(item.hide_breakdown) ? item.hide_breakdown : false,
  howItWorks: item.how_it_works,
  pdpPriceText: item.pdp_price_text ? item.pdp_price_text : '',
  useLargePdpImage: !isNil(item.use_large_pdp_image) ? item.use_large_pdp_image : false,
  largePdpImage: item.large_pdp_image ? item.large_pdp_image : '',
  hideCategory: !isNil(item.hide_category) ? item.hide_category : false,
  researchLink: item.research_link ? item.research_link : '',
  replacementSku: item.replacement_sku ? item.replacement_sku : '',
  healthClaims: item.health_claims ? item.health_claims : [],
  canonicalTag: item.canonical_tag ? item.canonical_tag : '',
  extraDetails: item.extra_details,
  availableForSubscription: !isNil(item.available_for_subscription) ? item.available_for_subscription : true,
  availableForOneOff: item.available_for_one_off || false,
  isProcessedInternally: !isNil(item.is_processed_internally) ? item.is_processed_internally : true,
});

export const toSetting = (item: SettingResponse): Setting => ({
  cartPopupDelay: item.data.cart_popup_delay,
  cartPopupDiscount: item.data.cart_popup_discount,
  cartPopupDollar: item.data.cart_popup_dollar,
  cartPopupPromocode: item.data.cart_popup_promocode,
  checkoutPopupDelay: item.data.checkout_popup_delay,
  checkoutPopupDiscount: item.data.checkout_popup_discount,
  checkoutPopupDollar: item.data.checkout_popup_dollar,
  checkoutPopupPromocode: item.data.checkout_popup_promocode,
  maintenanceMode: item.data.maintenance_mode,
  maxPillsPerSachet: item.data.max_pills_per_sachet,
  minimumOrderPrice: item.data.minimum_order_price,
  referralCredit: item.data.referral_credit,
  referralDiscount: item.data.referral_discount,
  useCartPopupPromocode: item.data.use_cart_popup_promocode,
  useCheckoutPopupPromocode: item.data.use_checkout_popup_promocode,
  giftCardEnabled: item.data.gift_card_enabled,
});
export const toCountryItems = (item: CountryResponse): Country => ({
  country: item.country,
  countryLabel: item.countryLabel,
  currency: item.currency,
  currencyLabel: item.currencyLabel,
  currencyLabelShort: item.currencyLabelShort,
  currencyPrefix: item.currencyPrefix,
  doubleSubscription: item.doubleSubscription,
  estShippingDays: item.estShippingDays,
  exchangeRate: item.exchangeRate,
  flag: item.flag,
  freeShipping: item.freeShipping,
  ignoreState: item.ignoreState,
  postcode: item.postcode,
  region: item.region,
  shippingCost: item.shippingCost,
  shippingFeeConversion: item.shippingFeeConversion,
  state: item.state || [],
  suburb: item.suburb,
  textState: item.textState,
  shippingRange: item.shippingRange,
  enableShippingThresholdTesting: item.enableShippingThresholdTesting,
  enableGst: item.enable_gst || false,
  gstPercent: item.gst_percent || 0,
});
export const toUserCountry = (item: UserCountryResponse): UserCountry => ({
  country: item.country,
});
export const toPromoCode = (response: PromoCodeResponse): PromoCode => ({
  id: response.id,
  amountUse: response.amount_use,
  code: response.code,
  credit: response.credit,
  discountAmount: response.discount_amount,
  durationInMonths: response.duration_in_months,
  expiryDate: response.expiry_date,
  giftCard: response.gift_card,
  maxRedemptions: response.max_redemptions,
  name: response.name,
  newCustomer: response.newCustomer,
  percent: response.percent,
  productLimit: response.product_limit,
  referralCode: response.referral_code,
  status: response.status,
  type: response.type,
  userId: response.user_id,
  message: response.message,
  resultMessage: response.result_message,
  degressiveDiscount: response.degressiveDiscount || undefined,
  freeProducts: response.freeProducts || undefined,
  freeGift: response.freeGift || undefined,
  maxBasketCap: response.maxBasketCap || undefined,
  miniumSpend: response.minium_spend || undefined,
  errorMessage: response.error_message || undefined,
  errorMessageForDouble: response.errorMessageForDouble || undefined,
});

export const toCountries = (response: CountryResponse[]): Country[] => response.map(toCountryItems);

export const toOrderProduct = (item: OrderProductResponseItem): ProductOrder => ({
  sku: item.sku,
  id: item.id,
  recommendation: item.recommendation,
  outOfStock: item.out_of_stock,
  name: item.name,
  images: item.images,
  hideFromLibrary: item.hide_from_library,
  orderItem: item.order_item ? toOrderItem(item.order_item) : [],
});
export const toOrder = (item: OrderResponseItem): OrderUser => ({
  id: item.id,
  amount: item.amount,
  bookletAlert: item.booklet_alert,
  bookletLink: item.booklet_link,
  country: item.country,
  coupon: item.coupon,
  createdAt: item.created_at,
  creditUsed: item.credit_used,
  discountAmount: item.discount_amount,
  discountPercent: item.discount_percent,
  double: item.double,
  estimatedDelivery: item.estimated_delivery,
  estimatedNextPayment: item.estimated_next_payment,
  failedPaymentReason: item.failed_payment_reason,
  gmv: item.gmv,
  holdReason: item.hold_reason,
  isFirstOrder: item.is_first_order,
  lastExport: item.last_export,
  lastPaymentFailed: item.last_payment_failed,
  latest: item.latest,
  nextPaymentAttempt: item.next_payment_attempt,
  note: item.note,
  orderComesFromTrial: item.order_comes_from_trial,
  orderId: item.order_id,
  paid: item.paid,
  paidDate: item.paid_date,
  paymentFailedCount: item.payment_failed_count,
  paymentGateway: item.payment_gateway,
  paymentMethod: item.payment_method,
  percentOff: item.percent_off,
  processedDate: item.processed_date,
  receivedDate: item.received_date,
  refunded: item.refunded,
  refundedAmount: item.refunded_amount,
  retryDate: item.retry_date,
  retryPayment: item.retry_payment,
  shippedDate: item.shipped_date,
  shipping: item.shipping,
  shippingMethodId: item.shipping_method_id,
  userId: item.user_id,
  updatedAt: item.updated_at,
  type: item.type,
  trackingUrl: item.tracking_url,
  trackingNumber: item.tracking_number,
  tax: item.tax,
  stripeSubscriptionId: item.stripe_subscription_id,
  stripeOrderId: item.stripe_order_id,
  status: item.status,
  products: item.products.data.map(toOrderProduct),
});
export const toOrderLatest = (item: OrderLatestResponseItem): OrderLatest => ({
  id: item.id,
  amount: item.amount,
  bookletAlert: item.booklet_alert,
  bookletLink: item.booklet_link,
  country: item.country,
  coupon: item.coupon,
  createdAt: item.created_at,
  creditUsed: item.credit_used,
  discountAmount: item.discount_amount,
  discountPercent: item.discount_percent,
  double: item.double,
  estimatedDelivery: item.estimated_delivery,
  estimatedNextPayment: item.estimated_next_payment,
  failedPaymentReason: item.failed_payment_reason,
  gmv: item.gmv,
  holdReason: item.hold_reason,
  isFirstOrder: item.is_first_order,
  lastExport: item.last_export,
  lastPaymentFailed: item.last_payment_failed,
  latest: item.latest,
  nextPaymentAttempt: item.next_payment_attempt,
  note: item.note,
  orderComesFromTrial: item.order_comes_from_trial,
  orderId: item.order_id,
  paid: item.paid,
  paidDate: item.paid_date,
  paymentFailedCount: item.payment_failed_count,
  paymentGateway: item.payment_gateway,
  paymentMethod: item.payment_method,
  percentOff: item.percent_off,
  processedDate: item.processed_date,
  receivedDate: item.received_date,
  refunded: item.refunded,
  refundedAmount: item.refunded_amount,
  retryDate: item.retry_date,
  retryPayment: item.retry_payment,
  shippedDate: item.shipped_date,
  shipping: item.shipping,
  shippingMethodId: item.shipping_method_id,
  userId: item.user_id,
  updatedAt: item.updated_at,
  type: item.type,
  trackingUrl: item.tracking_url,
  trackingNumber: item.tracking_number,
  tax: item.tax,
  stripeSubscriptionId: item.stripe_subscription_id,
  stripeOrderId: item.stripe_order_id,
  status: item.status,
  products: item.products.data.map(toProduct),
  subtotal: item.subtotal ? Number(item.subtotal) : 0,
});
export const toSubscriptionItem = (item: SubscriptionResponseItem): SubscriptionItem => ({
  amount: item.amount,
  id: item.id,
  isRecommendation: item.is_recommendation,
  product: toProduct(item.product.data),
  productId: item.product_id,
  quantity: item.quantity,
  userId: item.user_id,
  createdAt: item.created_at,
});

export const toPolicy = (item: PolicyItem): Policy => ({
  id: item.id,
  content: item.content,
  type: item.type,
});

export const toTerms = (item: TermsItem): Terms => ({
  id: item.id,
  content: item.content,
  type: item.type,
});
export const toProductCollects = (item: ProductCollectionResponseItem): Collection => ({
  id: item.id,
  title: item.title,
  slug: item.slug,
  active: item.active,
  contentTitle: item.content_title,
  contentText: item.content_text,
  contentImage: item.content_image,
});
export const toProductCollect = (item: ProductCollectionResponseItem): Collection => ({
  id: item.id,
  title: item.title,
  slug: item.slug,
  active: item.active,
  contentTitle: item.content_title,
  contentText: item.content_text,
  contentImage: item.content_image,
  productsCollection: item.products.data.map(toProduct),
});

export const toCustomer = (item: CustomerResponseItem): Customer => ({
  email: item.email,
  firstName: item.first_name,
  nameOnPack: item.name_on_pack,
  id: item.id,
  lastName: item.last_name,
  authorityToLeave: item.authority_to_leave,
  doubleSubscription: item.double_subscription,
  phone: item.phone,
  paymentGateway: item.payment_gateway,
  cardLastFour: item.card_last_four,
  cardExpire: item.card_expire,
  cardBrand: item.card_brand,
  creditAvailable: item.credit_available,
  referralCode: item.referral_code,
  subscriptionFrequency: item.subscription_frequency || 30,
  upcomingInvoiceEnabled: item.upcoming_invoice_enabled,
  upcomingInvoiceFrequency: item.upcoming_invoice_frequency || DEFAULT_UPCOMING_INVOICE_FREQUENCY,
  freeShippingThreshold: item.free_shipping_threshold || undefined,
  enableGst: item.enable_gst || false,
  subscriptionFrequencyId: item.subscription_frequency_id,
  userId: item.user_id,
});

export const toPromoCodeInvalid = (item: PromoCodeInvalidResponse): PromoCode => ({
  id: 0,
  amountUse: 0,
  code: '',
  credit: '',
  discountAmount: '',
  durationInMonths: 0,
  expiryDate: '',
  giftCard: false,
  maxRedemptions: 0,
  name: '',
  newCustomer: false,
  percent: '',
  productLimit: '',
  referralCode: false,
  status: '',
  type: '',
  userId: 0,
  message: '',
  resultMessage: '',
  errorMessage: item.message,
  valid: false,
});

export const toQuizAnswer = (item: QuizAnswerResponse): QuizAnswer => ({
  questionShort: item.question_short,
  response: item.response,
});

export const toSubscriptionFrequency = (item: SubscriptionFrequencyResponse): SubscriptionFrequency => ({
  id: item.id,
  name: item.name,
  slug: item.slug,
  description: item.description,
  refillableDispenser: item.refillable_dispenser,
  saveOnShipping: item.save_on_shipping,
  accessToApp: item.access_to_app,
  exclusiveDiscountsAndOffers: item.exclusive_discounts_and_offers,
  dietAndLifestyle: item.diet_and_lifestyle,
  supportFromNutritionists: item.support_from_nutritionists,
  extraDiscountCode: item.extra_discount_code,
  subscriptionFrequency: item.subscription_frequency,
});

export const toSubscriptionFrequencies = (items: SubscriptionFrequencyResponse[]): SubscriptionFrequency[] =>
  items.map((m) => toSubscriptionFrequency(m));

export const toUser = (user: UserResponse): User => ({
  id: user.id,
  email: user.email,
  createdAt: user.created_at,
});

export const toAddress = (item: AddressDataResponse): AddressData => ({
  id: item.id,
  company: item.company,
  phone: item.phone,
  country: item.country,
  addressLine1: item.address_line_1,
  addressLine2: item.address_line_2,
  suburb: item.suburb,
  state: item.state,
  postcode: item.postcode,
  isDefault: item.is_default,
  isOneOff: item.is_one_off,
});

export const toAddresses = (items: AddressDataResponse[]): AddressData[] => items.map((m) => toAddress(m));

export const toOrderConfirmation = (item: OneOffOrderResponseItem): OrderConfirmation => ({
  orderNumber: item.order_id,
  orderDate: item.created_at,
  shipping: item.shipping,
  subtotal: item.amount,
  total: item.amount,
  estimatedDelivery: item.estimated_delivery,
  shippingMethodId: item.shipping_method_id,
  products: item.order_items.map((m) => toProduct(m.product)),
});
